import { useEffect, useState } from "react";
import SimpleModalFooter from "app/components/Modals/SimpleModalFooter";
import SimpleModal from "app/components/Modals/SimpleModal";
import { useAppDispatch } from "utils/redux/hooks";
import { sendPreviewEmail } from "../slice";
import PreviewInviteToOrgChecklistOptions from "./PreviewInviteToOrgChecklistOptions";
import PreviewTeamInviteChecklistOptions from "./PreviewTeamInviteChecklistOptions";

interface PreviewEmailModalProps {
  emailType?: string;
  onClose: () => void;
  isOpen: boolean;
  title: string;
  titleDescriptionText?: string;
  isLoading?: boolean;
}
function PreviewEmailModal({
  onClose,
  emailType,
  isOpen,
  title,
  titleDescriptionText = "Send a test email to yourself to preview the organization invite email.",
  isLoading,
}: Readonly<PreviewEmailModalProps>) {
  const dispatch = useAppDispatch();
  const [alreadyJoined, setAlreadyJoined] = useState(true);
  const [inviteToOrganizationOptions, setInviteToOrganizationOptions] =
    useState<Record<string, boolean>>({});

  useEffect(() => {
    if (isOpen) {
      return;
    }
    setInviteToOrganizationOptions({
      teamLeaders: false,
      teamMembers: false,
      departmentLeaders: false,
      managers: false,
      admin: false,
    });
    setAlreadyJoined(true);
  }, [isOpen]);

  const getAllRolesChecklist = () => {
    switch (emailType) {
      case "inviteToOrganization":
        return (
          <PreviewInviteToOrgChecklistOptions
            setter={(key, value) => {
              setInviteToOrganizationOptions({
                ...inviteToOrganizationOptions,
                [key]: value,
              });
            }}
            valueMap={inviteToOrganizationOptions}
          />
        );
      case "teamInvite":
        return (
          <PreviewTeamInviteChecklistOptions
            setAlreadyJoined={setAlreadyJoined}
            alreadyJoined={alreadyJoined}
          />
        );
      default:
        return null;
    }
  };

  const checkIfActionIsDisabled = () => {
    if (isLoading) {
      return true;
    }
    if (emailType === "inviteToOrganization") {
      return (
        !inviteToOrganizationOptions.teamLeaders &&
        !inviteToOrganizationOptions.teamMembers &&
        !inviteToOrganizationOptions.departmentLeaders &&
        !inviteToOrganizationOptions.managers &&
        !inviteToOrganizationOptions.admin
      );
    }

    return false;
  };

  const getOnSaveOptions = () => {
    switch (emailType) {
      case "inviteToOrganization":
        return inviteToOrganizationOptions;
      case "teamInvite":
        return { alreadyJoined };
      default:
        return {};
    }
  };

  const onSave = async () => {
    if (isActionButtonDisabled || !emailType) {
      return;
    }

    await dispatch(
      sendPreviewEmail({
        emailType,
        options: getOnSaveOptions(),
      })
    );
    onClose();
  };

  const isActionButtonDisabled = checkIfActionIsDisabled();
  const checklist = getAllRolesChecklist();

  return (
    <SimpleModal show={isOpen} onHide={onClose} title={title}>
      {titleDescriptionText ? (
        <p style={{ marginTop: "-12px" }}>{titleDescriptionText}</p>
      ) : null}
      {checklist}
      <SimpleModalFooter
        onPrimaryButtonClick={onSave}
        isPrimaryButtonDisabled={isActionButtonDisabled}
        primaryButtonText="Send Test Email"
      />
    </SimpleModal>
  );
}

export default PreviewEmailModal;
