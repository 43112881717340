import SimpleModal from "app/components/Modals/SimpleModal";
import Button from "app/storybookComponents/Button";
import TextInput from "app/storybookComponents/Forms/TextInput";
import Select from "react-select";
import { useEffect, useMemo, useState } from "react";
import { Card, Collapse, Dropdown, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getS, getSelectProps } from "utils/helperFunctions";
import { selectAllCompanyUsersById } from "app/containers/Global/slice";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  getMemberOption,
  getUserOption,
} from "app/components/GlobalNavbar/helpers";
import {
  checkNewEmail,
  checkUserEmail,
  getMatches,
  selectGetTestTakerMatchesStatus,
  selectNewCheckedEmails,
  selectTestTakerMatches,
  selectCheckedEditedEmails,
} from "app/containers/AdminConsole/slice";
import { TestTakerMatch } from "app/containers/AdminConsole/types";
import { SearchOption } from "app/components/GlobalNavbar/types";
import Loading from "app/storybookComponents/Loading";
import {
  getLinkedTestTaker,
  getManagerAndDirectReports,
  selectGettingTestTakerForUserAccountIdStatus,
  selectLinkedTestTakerByUserAccountId,
  selectManagerAndDirectReportsByUserAccountId,
} from "../slice";
import Toggle from "app/components/Toggle";
import { PLATFORM_NAME } from "utils/constants";
import {
  getEmailManagerSettings,
  selectEmailManagerSettings,
} from "app/components/EmailManager/slice";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSaveUserDataRow: (data: {
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    gender?: string;
    jobTitle?: string;
    tmgRoleId?: 1 | 2;
    linkedTestTakerId?: number;
    managerUserAccountId?: number;
    sendInvitations?: boolean;
  }) => Promise<any> | void;
  showAccessRoleSection?: boolean;
  showConnectTestTaker?: boolean;
  saveButtonText?: string;
  userAccountId?: number;
  title?: string;
  isLoading?: boolean;
  hideSendInvitationToggle?: boolean;
}

export default function AddSingleUserModal({
  isOpen,
  onClose,
  onSaveUserDataRow,
  showConnectTestTaker,
  showAccessRoleSection = true,
  saveButtonText = "Add User",
  userAccountId,
  title = "Create User Manually",
  isLoading,
  hideSendInvitationToggle,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const queriedMatches = useAppSelector(selectTestTakerMatches);
  const getTestMatchesQueryStatus = useAppSelector(
    selectGetTestTakerMatchesStatus
  );
  const checkedEmails = useAppSelector(selectNewCheckedEmails);
  const linkedTestTakersByUserAccountId = useAppSelector(
    selectLinkedTestTakerByUserAccountId
  );
  const managersAndDirectReportsByUserAccountId = useAppSelector(
    selectManagerAndDirectReportsByUserAccountId
  );
  const getLinkedTestTakerStatus = useAppSelector(
    selectGettingTestTakerForUserAccountIdStatus
  );
  const emailManagementSettings = useAppSelector(selectEmailManagerSettings);
  const checkedEditedEmails = useAppSelector(selectCheckedEditedEmails);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [gender, setGender] = useState("");
  const [lastQuerySearched, setLastQuerySearched] = useState("");
  const [sectionCollapseMap, setSectionCollapseMap] = useState<{
    [key: string]: boolean;
  }>({});
  const [managerLookUpInput, setManagerLookUpInput] = useState("");
  const [linkResultsInput, setLinkResultsInput] = useState("");
  const [managerUserAccountId, setManagerUserAccountId] = useState<
    number | null
  >(null);
  const [testTakerSelected, setTestTakerSelected] =
    useState<null | TestTakerMatch>(null);
  const [accessRole, setAccessRole] = useState<1 | 2>(2); // 1 for Admin, 2 for User
  const [sendInvitations, setSendInvitations] = useState(
    !!emailManagementSettings?.inviteToOrganizationIsEnabled
  );
  const [isEmailFieldInFocus, setIsEmailFieldInFocus] = useState(false);

  const checkedEmail = useMemo(() => {
    if (!emailAddress) return null;
    return userAccountId
      ? checkedEditedEmails[emailAddress]
      : checkedEmails[emailAddress];
  }, [checkedEmails, emailAddress, userAccountId, checkedEditedEmails]);

  useEffect(() => {
    setSendInvitations(
      !!emailManagementSettings?.inviteToOrganizationIsEnabled
    );
  }, [emailManagementSettings]);

  useEffect(() => {
    dispatch(getEmailManagerSettings());
  }, [dispatch]);

  useEffect(() => {
    // when the user opens the modal we set the collapse open for the first section
    if (!isOpen) {
      return;
    }

    setSectionCollapseMap({
      basicInformation: true,
    });
  }, [isOpen]);

  useEffect(() => {
    const user = userAccountId && usersById[userAccountId];
    if (!user) return;
    setFirstName(user.firstName ?? "");
    setLastName(user.lastName ?? "");
    setEmailAddress(user.emailAddress ?? "");
    setJobTitle(user.jobTitle ?? "");
    setGender(user.gender ?? "");
    setAccessRole(user.tmgRoleId);
  }, [userAccountId, usersById]);

  useEffect(() => {
    if (!userAccountId) return;
    dispatch(getManagerAndDirectReports({ userAccountId }));
    dispatch(getLinkedTestTaker(userAccountId));
  }, [userAccountId, dispatch]);

  useEffect(() => {
    if (!userAccountId) {
      return setManagerUserAccountId(null);
    }
    const managerAndDirectReports =
      managersAndDirectReportsByUserAccountId[userAccountId];
    setManagerUserAccountId(
      managerAndDirectReports?.managerRelationship?.managerUserAccountId ?? null
    );
  }, [managersAndDirectReportsByUserAccountId, userAccountId]);

  useEffect(() => {
    if (!userAccountId) {
      return setManagerUserAccountId(null);
    }
    const linkedTestTaker = linkedTestTakersByUserAccountId[userAccountId];
    setTestTakerSelected(linkedTestTaker ?? null);
  }, [linkedTestTakersByUserAccountId, userAccountId]);

  const onCloseWrapper = () => {
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setJobTitle("");
    setGender("");
    setSectionCollapseMap({});
    setManagerLookUpInput("");
    setLinkResultsInput("");
    setAccessRole(2);
    setLastQuerySearched("");
    onClose();
  };

  const getSectionHeader = (
    title: string,
    infoText: string,
    collapseKey: string
  ) => (
    <button
      className="no-style-button"
      onClick={() => {
        setSectionCollapseMap({
          ...sectionCollapseMap,
          [collapseKey]: !sectionCollapseMap[collapseKey],
        });
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <p className="fw-bold">{title}</p>
        <div className="row-gap-16px align-items-center">
          <p
            style={{
              color: "#86888b",
              fontWeight: 700,
            }}
          >
            {infoText}
          </p>

          <div
            style={{
              width: "1px",
              minHeight: "20px",
              height: "100%",
              backgroundColor: "#D9DEF4",
            }}
          ></div>
          <Button variant="secondary-blue" className="border-0">
            {!sectionCollapseMap[collapseKey] ? "Expand" : "Collapse"}
            <FontAwesomeIcon
              icon={`caret-${!sectionCollapseMap[collapseKey] ? "down" : "up"}`}
              className="ms-2"
            />
          </Button>
        </div>
      </div>
    </button>
  );

  const getBasicInformationCompletedCount = () => {
    const fields = [firstName, lastName, emailAddress, jobTitle, gender];
    return fields.reduce((count, field) => count + (field ? 1 : 0), 0);
  };

  const onEmailUnFocus = () => {
    setIsEmailFieldInFocus(false);
    if (
      userAccountId &&
      usersById[userAccountId]?.emailAddress === emailAddress
    ) {
      return;
    }

    if (userAccountId) {
      dispatch(checkUserEmail({ emailAddress, userAccountId }));
    } else {
      dispatch(checkNewEmail(emailAddress));
    }
  };

  const getBasicInformation = () => (
    <>
      {getSectionHeader(
        "Basic Information",
        `${getBasicInformationCompletedCount()} / 5 fields completed`,
        "basicInformation"
      )}
      <Collapse in={sectionCollapseMap["basicInformation"]}>
        <div className="column-gap-20px">
          <div>
            <Form.Label className="textarea-label">First Name</Form.Label>
            <TextInput
              controlId="firstName"
              inputText={firstName}
              onTextChange={setFirstName}
              placeholder="First Name"
            />
          </div>
          <div>
            <Form.Label className="textarea-label">Last Name</Form.Label>
            <TextInput
              controlId="lastName"
              inputText={lastName}
              onTextChange={setLastName}
              placeholder="Last Name"
            />
          </div>
          <div>
            <Form.Label className="textarea-label">Email Address</Form.Label>
            <TextInput
              controlId="emailAddress"
              inputText={emailAddress}
              onTextChange={setEmailAddress}
              placeholder="Email Address"
              onUnFocus={onEmailUnFocus}
              onFocus={() => setIsEmailFieldInFocus(true)}
              className={checkedEmail?.isValid === false ? "danger" : ""}
            />
            {checkedEmail?.isValid === false ? (
              <div className="warning-banner red mt-2">
                <p>
                  {checkedEmail?.error ??
                    "This email is invalid please enter a new email."}
                </p>
              </div>
            ) : null}
          </div>
          <div>
            <Form.Label className="textarea-label">Job Title</Form.Label>
            <TextInput
              controlId="jobTitle"
              inputText={jobTitle}
              onTextChange={setJobTitle}
              placeholder="Job Title"
            />
          </div>
          <Form.Group controlId={"gender"}>
            <Form.Label className="textarea-label">Gender</Form.Label>
            <Dropdown
              onSelect={(e) => {
                setGender(e ?? "");
              }}
            >
              <Dropdown.Toggle
                variant="light"
                className="dropdown-menu-240px"
                style={{
                  textTransform: "capitalize",
                  width: "100%",
                }}
              >
                <p className={gender ? "" : "grey-text"}>
                  {gender || "Select a gender"}
                </p>
              </Dropdown.Toggle>

              <Dropdown.Menu className="w-100">
                <Dropdown.Item eventKey="Male">Male</Dropdown.Item>
                <Dropdown.Item eventKey="Female">Female</Dropdown.Item>
                <Dropdown.Item eventKey="Non-binary">Non-binary</Dropdown.Item>
                <Dropdown.Item eventKey="Not disclosed">
                  Not disclosed
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </div>
      </Collapse>
    </>
  );

  const getMatchCard = (tt: TestTakerMatch) => {
    const {
      testTakerFirstName,
      testTakerLastName,
      testTakerEmail,
      dateEventTaken,
      position,
      stageName,
    } = tt;
    return (
      <Card className="test-taker-match-card">
        <div className="test-taker-match-card__main-content">
          <div className="test-taker-match-card__title">
            <h3 className="test-taker-match-card__title__name">
              {testTakerFirstName} {testTakerLastName}
            </h3>
            <p>
              <span className="label-tag personality-tag">
                Personality <FontAwesomeIcon icon="check" />
              </span>
            </p>
          </div>
          <div className="test-taker-match-card__description">
            <p>
              <b>{testTakerEmail}</b>
              <FontAwesomeIcon icon="circle" className="dot-divider" />
              {position}
            </p>
          </div>
          <div className="test-taker-match-card-date">
            <p>
              {dateEventTaken}
              <FontAwesomeIcon
                icon="circle"
                style={{
                  fontSize: "4px",
                }}
                className="dot-divider"
              />
              {stageName} Stage
            </p>
          </div>
        </div>
        <div>
          <Button
            variant="secondary-blue"
            onClick={() => {
              setLastQuerySearched("");
              setLinkResultsInput("");
              setTestTakerSelected(tt);
            }}
          >
            Link
          </Button>
        </div>
      </Card>
    );
  };

  const getManagerSection = () => {
    const { selectStyles, components } = getSelectProps("search");
    const options: SearchOption[] = [];

    Object.keys(usersById).forEach((uId) => {
      const userId = Number(uId);
      if (userAccountId && userId === userAccountId) return;
      const userOption = getUserOption(usersById, userId);
      if (userOption) {
        options.push(userOption);
      }
    });

    const managerUser = managerUserAccountId
      ? usersById[managerUserAccountId]
      : null;
    const managerName = managerUser
      ? `${managerUser.firstName ?? ""} ${managerUser.lastName ?? ""}`
      : "No manager selected";

    return (
      <>
        {getSectionHeader("Manager", managerName, "managerSection")}
        <Collapse in={sectionCollapseMap["managerSection"]}>
          <div className="column-gap-16px">
            <p className="grey-text">
              Add or edit this user's primary manager in this organization.
              Managers will be able to view Workplace Insights, Manager Guide,
              and Collaboration Guides for their direct reports if it has been
              set to visible
            </p>
            {managerUser ? (
              getSelectedUserBanner(
                managerName,
                managerUser.jobTitle ?? "",
                <Button
                  variant="secondary-gray"
                  onClick={() => {
                    setManagerUserAccountId(null);
                  }}
                  className="white-button"
                >
                  Remove
                </Button>
              )
            ) : (
              <Select<SearchOption>
                isClearable
                isSearchable
                placeholder={"Search by name or email..."}
                components={components}
                inputValue={managerLookUpInput}
                styles={selectStyles}
                options={options}
                value={null}
                onInputChange={(e, actionMeta) => {
                  if (actionMeta.action === "input-change") {
                    setManagerLookUpInput(e);
                  }
                }}
                onChange={(e) => {
                  setManagerUserAccountId(Number(e?.value) || null);
                }}
                formatOptionLabel={getMemberOption}
                filterOption={(option, inputValue) => {
                  if (!inputValue) return true;
                  const userAccountId = Number(option.value);
                  const user = usersById[userAccountId];
                  if (!user) return false;
                  const fullName = `${user.firstName ?? ""} ${
                    user.lastName ?? ""
                  } ${user.emailAddress ?? ""}`;
                  return fullName
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                }}
                className="w-100"
              />
            )}
          </div>
        </Collapse>
      </>
    );
  };

  const getAccessRoleCard = (
    title: string,
    description: string,
    isSelected: boolean,
    onSelect: () => void
  ) => (
    <Card
      className={`clickable-card ${isSelected ? "selected" : ""} v1`}
      onClick={() => {
        onSelect();
      }}
    >
      <div className="row-gap-16px">
        <div>
          <input type="radio" checked={isSelected} />
        </div>
        <div className="column-gap-6px">
          <p>
            <b>{title}</b>
          </p>
          <p>{description}</p>
        </div>
      </div>
    </Card>
  );

  const getAccessRoleSection = () => {
    if (!showAccessRoleSection) {
      return null;
    }

    return (
      <>
        <hr className="m-0" />
        {getSectionHeader(
          "Access Role",
          accessRole === 1 ? "Admin" : "User",
          "accessRoleSection"
        )}
        <Collapse in={sectionCollapseMap["accessRoleSection"]}>
          <div className="column-gap-8px">
            {getAccessRoleCard(
              "User (Default)",
              "Users can take the TEAMscan survey and view results for their team. They can also view their own personality insights and any results that others have chosen to share. If enabled in company settings, users can create teams and invite users.",
              accessRole === 2,
              () => setAccessRole(2)
            )}
            {getAccessRoleCard(
              "Admin",
              "Admins can add, view, update, and remove users and teams/departments. Admins can view TEAMscan results for all teams in the organization. Admins can make changes to company settings.",
              accessRole === 1,
              () => setAccessRole(1)
            )}
          </div>
        </Collapse>
      </>
    );
  };

  const getSelectedUserBanner = (
    title: string,
    description: string,
    rightComponent: JSX.Element
  ) => (
    <div
      style={{
        borderRadius: "4px",
        border: "1px solid #D9DEF4",
        backgroundColor: "#F5F7FC",
        display: "flex",
        justifyContent: "space-between",
        padding: "16px",
      }}
    >
      <div>
        <p className="fw-bold">{title}</p>
        <p>{description}</p>
      </div>
      {rightComponent}
    </div>
  );

  const getLinkedCandidateSearchResults = (
    queryLoading: boolean,
    matchCards: JSX.Element[]
  ) => {
    if (queryLoading) {
      return <Loading />;
    }
    if (matchCards.length) {
      return (
        <Card className="test-taker-match-card-container">
          <p style={{ marginTop: "-2px" }}>
            {matchCards.length} candidate profile
            {getS(matchCards.length)} found
          </p>
          {matchCards}
        </Card>
      );
    }

    if (lastQuerySearched) {
      return (
        <div className="warning-banner grey">
          <p>No candidate profiles found. Please try another name or email.</p>
        </div>
      );
    }

    return null;
  };

  const getLinkedCandidateCollapse = () => {
    if (getLinkedTestTakerStatus === "loading") {
      return <Loading />;
    }

    const { selectStyles, components } = getSelectProps("search");

    const matchCards = (queriedMatches[lastQuerySearched] ?? []).map(
      getMatchCard
    );

    const onSearch = () => {
      if (!linkResultsInput) return;
      dispatch(getMatches(linkResultsInput));
      setLastQuerySearched(linkResultsInput);
    };
    const queryLoading = getTestMatchesQueryStatus === "loading";

    return (
      <Collapse in={sectionCollapseMap["linkCandidateSection"]}>
        <div>
          {testTakerSelected ? (
            getSelectedUserBanner(
              `${testTakerSelected.testTakerFirstName ?? ""} ${
                testTakerSelected.testTakerLastName ?? ""
              }`,
              testTakerSelected.position ?? "",
              <Button
                variant="secondary-gray"
                onClick={() => {
                  setTestTakerSelected(null);
                }}
                className="white-button"
              >
                Unlink
              </Button>
            )
          ) : (
            <div className="column-gap-16px">
              <div className="row-gap-16px w-100">
                <Select
                  placeholder={"Search by name or email..."}
                  isClearable={true}
                  isSearchable={true}
                  components={components}
                  inputValue={linkResultsInput}
                  styles={selectStyles}
                  menuIsOpen={false}
                  onInputChange={(e, actionMeta) => {
                    if (actionMeta.action === "input-change") {
                      setLinkResultsInput(e);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onSearch();
                    }
                  }}
                  className="w-100"
                />
                <Button variant="secondary-gray" onClick={onSearch}>
                  Search
                </Button>
              </div>
              {getLinkedCandidateSearchResults(queryLoading, matchCards)}
            </div>
          )}
        </div>
      </Collapse>
    );
  };

  const getLinkCandidateSection = () => {
    if (!showConnectTestTaker) {
      return null;
    }

    let label = "Not linked";
    if (getLinkedTestTakerStatus === "loading") {
      label = "Loading...";
    } else if (testTakerSelected) {
      label = "Personality Results Linked";
    }

    return (
      <>
        <hr className="m-0" />
        {getSectionHeader("Link Candidate", label, "linkCandidateSection")}
        {getLinkedCandidateCollapse()}
      </>
    );
  };

  const getSendEmailSettingBanner = () => {
    if (hideSendInvitationToggle) return null;
    const toggleLabel = "Invite user to Develop via email?";
    let message = "";

    if (!sendInvitations) {
      message =
        "No invitation email will be sent. You can send invitation emails later from the People tab in the Admin Console.";
    } else {
      message = `An invitation will be sent to this email to join ${PLATFORM_NAME}. This can be done later as well.`;
    }

    return (
      <div className="warning-banner lighter-blue column-gap-12px border-0">
        <div className="row-gap-12px align-items-center">
          <Toggle
            handleToggle={() => {
              setSendInvitations(!sendInvitations);
            }}
            isOn={sendInvitations}
          />
          <p
            style={{
              color: "black",
            }}
          >
            {toggleLabel}
          </p>
        </div>
        <p>{message}</p>
      </div>
    );
  };

  const isFormDisabled =
    isEmailFieldInFocus ||
    !firstName ||
    !lastName ||
    !emailAddress ||
    checkedEmail?.isValid === false; // Only when explicitly false do we want to disable the form because we don't check if validated if its the same email as the userAccountId

  return (
    <SimpleModal show={isOpen} onHide={onCloseWrapper} title={title}>
      <>
        {getBasicInformation()}
        <hr className="m-0" />
        {getManagerSection()}
        {getAccessRoleSection()}
        {getLinkCandidateSection()}
        {getSendEmailSettingBanner()}
        <div className="ms-auto">
          <div className="row-gap-16px">
            <Button
              onClick={() => {
                onCloseWrapper();
              }}
              variant="secondary-blue"
            >
              Cancel
            </Button>
            <Button
              disabled={isFormDisabled || isLoading}
              onClick={async () => {
                if (isFormDisabled) return;
                await onSaveUserDataRow({
                  firstName,
                  lastName,
                  emailAddress,
                  gender,
                  jobTitle,
                  linkedTestTakerId: testTakerSelected?.testTakerId,
                  managerUserAccountId: managerUserAccountId ?? undefined,
                  tmgRoleId: accessRole,
                  sendInvitations,
                });
                onCloseWrapper();
              }}
            >
              {isLoading ? (
                <div
                  style={{
                    minWidth: "40px",
                  }}
                >
                  <Loading color="#53565A" size={15} />
                </div>
              ) : (
                saveButtonText
              )}
            </Button>
          </div>
        </div>
      </>
    </SimpleModal>
  );
}
