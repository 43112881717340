import TEAM360Icon from "resources/icons/TEAM-assessment-logo.svg";
import { LaunchAssessmentSurveyOverview } from "./types";

export const LAUNCH_TEAM_360_OVERVIEW_BY_ROLE = {
  teamLeader: {
    supportingText:
      "Launch the TEAMscan for your team today. It only takes a few minutes and is an anonymous survey that measures team performance across 17 critical dimensions. On completion, everyone benefits from a customized report detailing departmental strengths and focal points.",
    listItems: [
      {
        title: "Measure What Matters",
        text: "Gain insights with easy-to-understand reports. Dive deep into your team's strengths and areas to focus on. Enhance collaboration and drive productivity.",
      },
      {
        title: "Benchmark & Track Over Time",
        text: "Compare your team's results with others and monitor your TEAMscan scores over time. Understand the growth and areas of improvement within your team.",
      },
      {
        title: "Engage & Develop",
        text: "Benchmark yourself and your team's performance. Receive personalized skill suggestions and conversation prompts based on areas where you diverge from other teams.",
      },
    ],
  },
  departmentLeader: {
    supportingText:
      "Launch the TEAMscan for your team today. It only takes a few minutes and is an anonymous survey that measures team performance across 17 critical dimensions. On completion, everyone benefits from a customized report detailing departmental strengths and focal points.",
    listItems: [
      {
        title: "Measure What Matters",
        text: "Uncover department-wide insights. Recognize patterns, strengths, and areas demanding attention.",
      },
      {
        title: "Benchmark & Track Over Time",
        text: "Align your department's performance with others and trace your TEAMscan scores over time. This empowers strategic departmental growth.",
      },
      {
        title: "Engage & Develop",
        text: "Enhance yourself as a department leader, uplift your managers, discover best practices, and stimulate conversations in areas of shared or individual need.",
      },
    ],
  },
  admin: {
    supportingText:
      "Initiate the TEAMscan for your entire organization or one or more departments or teams. This comprehensive survey gauges team dynamics across 17 essential areas. Post-survey, each member is equipped with a personalized report appropriate to their organizational level and underscoring their teams organizational strengths and areas of growth.",
    listItems: [
      {
        title: "Measure What Matters",
        text: "Get a high-level organizational view. Decode which managers and departments are excelling in which specific areas and who needs support.",
      },
      {
        title: "Benchmark & Track Over Time",
        text: "Stack your departments and teams and measure the effectiveness of learning programs and interventions. This valid, easy, and fully automatic survey ensures your organization stays on the desired growth trajectory.",
      },
      {
        title: "Engage & Develop",
        text: "Bolster managers and teams, identify and propagate best practices, and ignite constructive conversations.",
      },
    ],
  },
};

export const DEFAULT_CUSTOM_MESSAGE =
  "I’m inviting all of you to participate in the TEAMscan Assessment. This assessment is designed to help us gain valuable insights into our team dynamics, strengths, and areas for improvement.";

export const SURVEY_MAP = {
  1: {
    name: "TEAMscan",
    icon: TEAM360Icon,
    headerDescription:
      "The TEAMscan survey, developed by industrial-organizational psychologists, enables you to efficiently and reliably assess team dynamics, benchmark performance, and track progress over time.",
    overviewBodyItemsByRole: LAUNCH_TEAM_360_OVERVIEW_BY_ROLE,
  },
} as LaunchAssessmentSurveyOverview;
