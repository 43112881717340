import SimpleModalFooter from "app/components/Modals/SimpleModalFooter";
import { getChecklistText } from "../helpers";
import { useAppSelector } from "utils/redux/hooks";
import {
  selectAllCompanyUsersById,
  selectCompanyInfo,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import { selectDepartments } from "app/containers/AdminConsole/slice";
import AvatarCircle from "app/components/AvatarCircle";
import { selectCompanyGuide } from "app/containers/CompanyGuide/slice";
import { getS } from "utils/helperFunctions";
import CircleEditButton from "app/storybookComponents/Button/CircleEditButton";
import { SendToType } from "../types";

interface Props {
  sendToType: SendToType;
  ids: number[];
  onSave: () => void;
  onBack: () => void;
  sendEmailOptions: Record<string, boolean | 0 | 1>;
  isLoading?: boolean;
  getWarningBanner?: (sendToType: string) => JSX.Element | null;
  confirmationDescriptionText?: string;
}

const SendEmailConfirmation = ({
  sendToType,
  ids,
  onSave,
  onBack,
  sendEmailOptions,
  isLoading,
  getWarningBanner,
  confirmationDescriptionText = "You’re sending an organization invite email to",
}: Props) => {
  const users = useAppSelector(selectAllCompanyUsersById);
  const departments = useAppSelector(selectDepartments);
  const teams = useAppSelector(selectTeamsByTeamId);
  const currentCompanyGuide = useAppSelector(selectCompanyGuide);
  const companyInfo = useAppSelector(selectCompanyInfo);

  const getIcons = () => {
    const hasRemaining = ids.length > 3;
    const splicedIds = ids.slice(0, 3);
    const iconMap: React.ReactNode[] = [];
    splicedIds.forEach((id) => {
      iconMap.push(getIcon(id));
    });
    if (hasRemaining) {
      iconMap.pop();
      const remainingCount = ids.length - 2;
      iconMap.push(<AvatarCircle name={`+ ${remainingCount}`} size="small" />);
    }

    return <div className="multi-avatar-container">{iconMap}</div>;
  };

  const generateSentToTextFromEntityNames = (stringArr: string[]) => {
    if (stringArr.length === 1) {
      return stringArr[0];
    }

    const hasRemaining = stringArr.length > 3;
    const splicedIds = stringArr.slice(0, 3);
    let text = "";
    splicedIds.forEach((id, idx) => {
      // if last element, don't add comma. add "and" instead
      // if hasRemaining, on the last element, add "and x more"
      const isLastElement = idx === splicedIds.length - 1;
      if (!isLastElement) {
        text += `${id}, `;
        return;
      }
      text += "and ";
      if (hasRemaining) {
        const remainingCount = stringArr.length - 2;
        text += `${stringArr.length - 2} other ${sendToType.slice(0, -1)}${getS(
          remainingCount
        )}`;
        return;
      }
      text += id;
    });
    return text;
  };

  const getSentToText = () => {
    switch (sendToType) {
      case "organization": {
        return companyInfo?.companyName;
      }
      case "departments":
        return generateSentToTextFromEntityNames(
          ids.map((id) => departments[id]?.name ?? "")
        );
      case "teams":
        return generateSentToTextFromEntityNames(
          ids.map((id) => teams[id]?.teamName ?? "")
        );
      case "users":
        return generateSentToTextFromEntityNames(
          ids.map((id) => {
            const user = users[id];
            return (
              `${user?.firstName ?? ""} ${user?.lastName ?? ""}`.trim() ||
              user.emailAddress
            );
          })
        );
      default:
        return null;
    }
  };
  const getSentToDescriptionText = () => {
    const sendToOptionText = <b>{getChecklistText(sendEmailOptions)}</b>;

    switch (sendToType) {
      case "users": {
        return `${ids.length} user${getS(ids.length)}`;
      }
      case "teams":
      case "departments": {
        const singularType = sendToType.slice(0, -1);
        if (ids.length === 1) {
          return (
            <>
              {sendToOptionText} in this {singularType}.
            </>
          );
        }
        return (
          <>
            {sendToOptionText} in these {ids.length} {singularType}
            {getS(ids.length)}.
          </>
        );
      }
      case "organization": {
        return <>{sendToOptionText} in this organization</>;
      }
      default:
        return null;
    }
  };

  const getIcon = (id: number) => {
    switch (sendToType) {
      case "users": {
        return <AvatarCircle userAccountId={id} size="small" key={id} />;
      }
      case "teams": {
        const teamInfo = teams[id];
        return (
          <AvatarCircle
            name={teamInfo?.teamName}
            size="small"
            avatarColor={teamInfo?.avatarColor}
            profilePicture={teamInfo?.profilePicture}
            key={id}
          />
        );
      }
      case "departments": {
        const departmentInfo = departments[id];
        return (
          <AvatarCircle name={departmentInfo?.name} size="small" key={id} />
        );
      }
      case "organization":
        return (
          <AvatarCircle
            name="Organization"
            size="small"
            profilePicture={currentCompanyGuide?.profilePicture?.picture}
            key={id}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <p>{confirmationDescriptionText}:</p>
      <div>
        <div className="row-gap-12px">
          {getIcons()}
          <div className="column-gap-4px">
            <p>
              <b>{getSentToText()}</b>
            </p>
            <p className="small-body-text grey-text">
              {getSentToDescriptionText()}
            </p>
          </div>
          <CircleEditButton onClick={onBack} />
        </div>
      </div>
      {getWarningBanner?.(sendToType) ?? null}
      <SimpleModalFooter
        onBackButtonClick={onBack}
        onPrimaryButtonClick={onSave}
        primaryButtonText="Send Email"
        isPrimaryButtonDisabled={isLoading}
      />
    </>
  );
};

export default SendEmailConfirmation;
