import { Modal } from "react-bootstrap";
import InviteUserForm from "app/storybookComponents/Forms/InviteUserForm";
import Button from "app/storybookComponents/Button";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  inviteUserByEmail,
  resetInvalidInvitedStrings,
  selectAllCompanyUsersById,
  selectCompanyInfo,
  selectInvalidInvitedStrings,
  selectInviteLinkByTeamId,
  selectInviteUserByEmailStatus,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import {
  selectCompanyInviteLink,
  selectCompanySettings,
} from "app/containers/AdminConsole/slice";
import {
  getEmailManagerSettings,
  selectEmailManagerSettings,
} from "app/components/EmailManager/slice";
import { selectHideInviteViaLinkOption } from "app/containers/Login/slice";
import { getAllowedDomains } from "app/containers/AdminConsole/helpers";
import { useEffect, useMemo, useState } from "react";
import { selectUserIsAbleToInviteUsers } from "app/containers/UserGuide/slice";
import { INVITE_PEOPLE_TEXT } from "./constants";
import { UserInfo } from "app/containers/Global/types";

interface Props {
  hideModal: () => void;
  isLoading?: boolean;
  showing?: boolean;
  teamId?: number;
  modalTitle?: string;
  onInviteSuccess?: () => void;
  defaultTab?: "email" | "csv" | "link" | null;
}

export default function InviteUsersModal({
  hideModal,
  showing,
  teamId,
  modalTitle,
  isLoading,
  onInviteSuccess,
  defaultTab,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const companyInfo = useAppSelector(selectCompanyInfo);
  const companySettings = useAppSelector(selectCompanySettings);
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const invitingUsersByStatus = useAppSelector(selectInviteUserByEmailStatus);
  const invalidInvitedStrings = useAppSelector(selectInvalidInvitedStrings);
  const teamByTeamId = useAppSelector(selectTeamsByTeamId);
  const canUserInviteNewMembers = useAppSelector(selectUserIsAbleToInviteUsers);
  const teamLinksById = useAppSelector(selectInviteLinkByTeamId);
  const companyInviteLink = useAppSelector(selectCompanyInviteLink);
  const hideInviteViaLinkOption = useAppSelector(selectHideInviteViaLinkOption);
  const emailManagementSettings = useAppSelector(selectEmailManagerSettings);
  const [sendEmails, setSendEmails] = useState(
    !!emailManagementSettings?.inviteToOrganizationIsEnabled
  );

  useEffect(() => {
    setSendEmails(
      teamId
        ? !!emailManagementSettings?.teamInviteIsEnabled
        : !!emailManagementSettings?.inviteToOrganizationIsEnabled
    );
  }, [emailManagementSettings, teamId]);

  useEffect(() => {
    dispatch(getEmailManagerSettings());
  }, [dispatch]);

  const currentTeamInfo = teamId ? teamByTeamId[teamId] : null;

  // if the teamId is provided then the team members should be usersById filtered by teamMemberIds
  // if no teamId then no team members should be shown
  const teamMembers = useMemo(() => {
    const suggestedMembers: UserInfo[] = [];
    if (teamId && teamsById[teamId]?.teamMemberIds) {
      Object.values(usersById).forEach((user: UserInfo) => {
        if (teamsById[teamId]?.teamMemberIds?.includes(user.userAccountId)) {
          return;
        }
        suggestedMembers.push(user);
      });
    }

    return suggestedMembers;
  }, [teamId, usersById, teamsById]);

  const allUserEmails = useMemo(() => {
    if (teamMembers?.length) {
      return teamMembers.map((user) => user.emailAddress);
    }
    return Object.values(usersById).map((user) => user.emailAddress);
  }, [usersById, teamMembers]);

  const onHide = () => {
    hideModal();
    dispatch(resetInvalidInvitedStrings());
  };

  const entity = teamId ? "Team" : "Organization";
  const { modalDescription, inviteMemberInfoMessage, addMemberInfoMessage } =
    INVITE_PEOPLE_TEXT[entity];

  const getModalDescription = () => (
    <p>
      <b>Why {sendEmails ? "Invite People" : "Add Users"}?</b>{" "}
      {modalDescription}
    </p>
  );

  const getModalTitle = () => {
    if (modalTitle) {
      return modalTitle;
    }
    const inviteOrAdd = sendEmails ? "Invite" : "Add";

    if (currentTeamInfo) {
      return `${inviteOrAdd} People to ${currentTeamInfo.teamName}`;
    }

    return `${inviteOrAdd} People to ${
      companyInfo?.companyName ?? "Organization"
    }`;
  };

  const getInvitationLink = () => {
    if (teamId) {
      return teamLinksById[teamId];
    }
    return companyInviteLink;
  };

  return (
    <Modal show={showing} onHide={onHide} size="lg" className="simple-modal">
      <div className="modal-title-row">
        <h2>{getModalTitle()}</h2>
        <Button
          onClick={() => onHide()}
          variant={"secondary-blue"}
          style={{ border: "none", width: "auto" }}
          xIcon
        />
      </div>
      <InviteUserForm
        onInviteViaEmail={async (emailAddresses: string[]) => {
          dispatch(resetInvalidInvitedStrings());
          const resp = (await dispatch(
            inviteUserByEmail({
              teamId,
              emailAddresses,
              sendInvitations: sendEmails,
            })
          )) as any;
          if (resp.error) return;
          onInviteSuccess?.();
        }}
        modalDescription={getModalDescription()}
        teamId={teamId ?? currentTeamInfo?.teamId}
        inviteMemberInfoMessage={inviteMemberInfoMessage}
        addMemberInfoMessage={addMemberInfoMessage}
        inviteLink={getInvitationLink()}
        isLoading={isLoading || invitingUsersByStatus === "loading"}
        teamMembers={teamMembers}
        onHide={onHide}
        hiddenUserAccounts={currentTeamInfo?.teamMemberIds ?? []}
        allowedDomains={getAllowedDomains(companySettings)}
        allUsedEmails={allUserEmails}
        invalidInvitedStrings={invalidInvitedStrings}
        isUserAllowedToInviteNewMembers={canUserInviteNewMembers}
        resetInvalidInvitedStrings={() =>
          dispatch(resetInvalidInvitedStrings())
        }
        setSendEmailSettingProps={{
          setter: setSendEmails,
          value: sendEmails,
        }}
        defaultTab={defaultTab}
        hideCsvTab={!!teamId}
        hideInviteViaLinkTab={hideInviteViaLinkOption}
      />
    </Modal>
  );
}
