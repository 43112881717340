interface CheckboxProps {
  id: string;
  value?: boolean;
  label: string;
  onChange?: (value: boolean) => void;
}

const Checkbox = ({ id, value, label, onChange }: CheckboxProps) => (
  <div className="check-box-container">
    <input
      type="checkbox"
      name={id}
      id={id}
      checked={value}
      onChange={() => onChange?.(!value)}
    />
    <label htmlFor={id}>{label}</label>
  </div>
);

export default Checkbox;
