import Button from "app/storybookComponents/Button";
import SurveysTable from "../AdminConsole/Tables/SurveysTable";
import { useAppDispatch } from "utils/redux/hooks";
import { setShowModal } from "app/components/Onboarding/slice";
import { showScheduleAssessmentModalForDepartmentId } from "app/components/LaunchAssessmentModal/slice";

interface Props {
  departmentId: number;
  isLeader?: boolean;
}
export default function DepartmentSurveys({
  departmentId,
  isLeader,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();

  const getEmptyStateActions = () => {
    const seeDetailsButton = (
      <Button
        variant="secondary-blue"
        className="white-button"
        onClick={() => {
          dispatch(
            setShowModal({
              eventType: "visitedTeam360TabWithResults",
            })
          );
        }}
      >
        See details
      </Button>
    );

    if (!isLeader) {
      return <div className="action-buttons">{seeDetailsButton}</div>;
    }

    return (
      <div className="action-buttons">
        <Button
          onClick={() => {
            dispatch(showScheduleAssessmentModalForDepartmentId(departmentId));
          }}
        >
          Launch Survey
        </Button>
        {seeDetailsButton}
      </div>
    );
  };
  return (
    <div>
      <SurveysTable
        departmentId={departmentId}
        emptyStateActions={getEmptyStateActions()}
        elementBetweenSearchAndTable={
          <p
            style={{
              margin: "24px 0",
            }}
          >
            Monitor completion and edit survey settings for TEAMscans here. To
            view results or edit settings, click the menu icon to find more
            actions for each instance.
          </p>
        }
      />
    </div>
  );
}
