import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";
import DashboardOnboardingCard from "app/containers/Dashboard/Cards/DashboardOnboardingCard";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  acceptInvitation,
  declineInvitation,
  selectAllPendingAssessmentsNotifications,
  selectAllAssessmentResultsNotifications,
  selectAllTeamCreationNotifications,
  selectAllTeamInvitations,
  selectDismissedNotificationIds,
  dismissNotification,
  selectAllAddTeamMemberNotifications,
  selectAllSendTeamScanReminderNotifications,
  selectAllLaunchTeamScanNotifications,
  setShowViewResultsReadyModal,
  selectGetNotificationsStatus,
  selectAllNewDirectReportNotifications,
} from "app/components/Notifications/slice";
import {
  selectAllCompanyUsersById,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import { useCallback, useMemo, useState } from "react";
import { setIsNotificationDropdownOpen } from "app/components/GlobalNavbar/slice";
import { getS } from "utils/helperFunctions";
import { useNavigate } from "react-router-dom";
import {
  openCreateTeamModal,
  setIsNewDirectReportModalOpen,
} from "../Modals/slice";
import {
  showScheduleAssessmentModal,
  showScheduleAssessmentModalForTeamId,
} from "../LaunchAssessmentModal/slice";
import {
  selectUserIsAbleToInviteUsers,
  selectUserIsAbleToCreateTeams,
} from "app/containers/UserGuide/slice";
import { getTeamName } from "./helpers";
import InviteUsersModal from "../Modals/InviteUsersModal";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import { sendReminder } from "app/containers/Dashboard/slice";
import { TeamNotification } from "./types";
import Loading from "app/storybookComponents/Loading";

type ButtonParameter = { text: string; onClick: () => void };

type TBanner = {
  text: string | JSX.Element;
  primaryButton?: ButtonParameter;
  secondaryButton?: ButtonParameter;
  showLightbulb?: boolean;
};

export default function ToDoCard() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // ---------------------- Selectors ----------------------
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const allPendingTeamNotifications = useAppSelector(selectAllTeamInvitations);
  const allPendingAssessmentNotifications = useAppSelector(
    selectAllPendingAssessmentsNotifications
  );
  const allAssessmentResultsNotifications = useAppSelector(
    selectAllAssessmentResultsNotifications
  );
  const allTeamCreationNotifications = useAppSelector(
    selectAllTeamCreationNotifications
  );
  const dismissedNotificationIds = useAppSelector(
    selectDismissedNotificationIds
  );
  const allAddTeamMemberNotifications = useAppSelector(
    selectAllAddTeamMemberNotifications
  );
  const allSendTeamScanReminderNotifications = useAppSelector(
    selectAllSendTeamScanReminderNotifications
  );
  const allLaunchTeamScanNotifications = useAppSelector(
    selectAllLaunchTeamScanNotifications
  );
  const allNewDirectReportNotifications = useAppSelector(
    selectAllNewDirectReportNotifications
  );
  const canUserCreateTeams = useAppSelector(selectUserIsAbleToCreateTeams);
  const canUserInviteUsers = useAppSelector(selectUserIsAbleToInviteUsers);
  const getNotificationsStatus = useAppSelector(selectGetNotificationsStatus);
  // ---------------------- State ----------------------
  const [showInviteMemberModal, setShowInviteMemberModal] = useState<
    number | boolean
  >(false);
  const [showRemindWarningModal, setShowRemindWarningModal] =
    useState<null | TeamNotification>(null);

  // ---------------------- Handlers ----------------------
  const onSeeAll = () => {
    dispatch(setIsNotificationDropdownOpen(true));
  };

  // ---------------------- Getters ----------------------
  const getBody = () => {
    if (allBanners.length === 0) {
      return null;
    }
    const banners = allBanners
      .slice(0, 3)
      .map((banner, i) => getNotificationBanner(banner));

    return <div className="column-gap-16px">{banners}</div>;
  };

  const getPendingTeamBannerArray = useCallback((): TBanner[] => {
    const banners: TBanner[] = [];
    allPendingTeamNotifications.forEach((notification) => {
      const { teamId, notificationId } = notification;
      const teamObj = teamsById[teamId];
      if (!teamObj) {
        return;
      }
      const isDismissed = dismissedNotificationIds.includes(notificationId);
      if (isDismissed) {
        return;
      }
      banners.push({
        text: (
          <p>
            You have been invited to join{" "}
            <b>{getTeamName(teamId, teamsById)}</b>. Join the team now?
          </p>
        ),
        primaryButton: {
          text: "Join team",
          onClick: () => {
            dispatch(acceptInvitation({ teamId, notificationId }));
          },
        },
        secondaryButton: {
          text: "Decline",
          onClick: () => {
            dispatch(declineInvitation({ teamId, notificationId }));
          },
        },
      });
    });

    return banners;
  }, [
    allPendingTeamNotifications,
    dismissedNotificationIds,
    teamsById,
    dispatch,
  ]);

  const getInvitationBannerArray = useCallback((): TBanner[] => {
    const banners: TBanner[] = [];
    allPendingAssessmentNotifications.forEach((notification) => {
      const { teamId, notificationId, eventId } = notification;
      const teamObj = teamsById[teamId];
      if (!teamObj) {
        return;
      }
      const isDismissed = dismissedNotificationIds.includes(notificationId);
      if (isDismissed) {
        return;
      }
      banners.push({
        text: (
          <p>
            You have an invite to complete the TEAMscan survey for{" "}
            <b>{getTeamName(teamId, teamsById)}</b>. Take the TEAMscan survey
            now?
          </p>
        ),
        primaryButton: {
          text: "Begin survey",
          onClick: () => {
            navigate(`/survey/team360?eventId=${eventId}&teamId=${teamId}`);
          },
        },
        secondaryButton: {
          text: "Dismiss",
          onClick: () => {
            dispatch(dismissNotification(notificationId));
          },
        },
      });
    });

    return banners;
  }, [
    allPendingAssessmentNotifications,
    dismissedNotificationIds,
    teamsById,
    dispatch,
    navigate,
  ]);

  // should only be one banner
  const getUnreadAssessmentBannerArray = useCallback((): TBanner[] => {
    const notificationLength = allAssessmentResultsNotifications.length;
    if (notificationLength === 0) return [];

    if (notificationLength === 1) {
      const { teamId, notificationId } = allAssessmentResultsNotifications[0];
      return [
        {
          text: (
            <p>
              You have unread TEAMscan results for{" "}
              <b>{getTeamName(teamId, teamsById)}</b>.
            </p>
          ),
          primaryButton: {
            text: "View results",
            onClick: () => {
              navigate(`/TeamGuide/${teamId}?tab=TEAMscan`);
              dispatch(dismissNotification(notificationId));
            },
          },
          secondaryButton: {
            text: "Dismiss",
            onClick: () => {
              dispatch(dismissNotification(notificationId));
            },
          },
        },
      ];
    }
    return [
      {
        text: (
          <p>
            You have unread TEAMscan results for{" "}
            <b>{notificationLength} teams</b>. View all results to see which of
            your teams have results
          </p>
        ),
        primaryButton: {
          text: "View results",
          onClick: () => {
            dispatch(setShowViewResultsReadyModal(true));
          },
        },
      },
    ];
  }, [allAssessmentResultsNotifications, teamsById, dispatch, navigate]);

  // should only be one banner
  const getCreateTeamBanner = useCallback((): TBanner[] => {
    const banner: TBanner[] = [];
    if (!canUserCreateTeams) return banner; // if user can't create teams, don't show this banner
    allTeamCreationNotifications.slice(0, 1).forEach((notification) => {
      banner.push({
        text: <p>Are you a team leader? Create your first team now.</p>,
        primaryButton: {
          text: "Create team",
          onClick: () => {
            dispatch(openCreateTeamModal());
          },
        },
        secondaryButton: {
          text: "Dismiss",
          onClick: () => {
            dispatch(dismissNotification(notification.notificationId));
          },
        },
      });
    });
    return banner;
  }, [allTeamCreationNotifications, dispatch, canUserCreateTeams]);

  const getInviteNewMembersBanner = useCallback((): TBanner[] => {
    let filteredMemberInvitations = [...allAddTeamMemberNotifications];
    // if the user cannot invite new users we should hide any notifications that don't have a teamId
    if (!canUserInviteUsers) {
      filteredMemberInvitations = filteredMemberInvitations.filter(
        (notification) => !!notification.teamId
      );
    }
    return filteredMemberInvitations.map(({ notificationId, teamId }) => ({
      text: (
        <p>
          Invite members to{" "}
          {teamId ? (
            <b>{getTeamName(teamId, teamsById)}</b>
          ) : (
            "your organization"
          )}
        </p>
      ),
      primaryButton: {
        text: "Invite",
        onClick: () => {
          setShowInviteMemberModal(teamId ?? true);
        },
      },
      secondaryButton: {
        text: "Dismiss",
        onClick: () => {
          dispatch(dismissNotification(notificationId));
        },
      },
    }));
  }, [dispatch, allAddTeamMemberNotifications, canUserInviteUsers, teamsById]);

  const getRemindMembersBanner = useCallback(
    (): TBanner[] =>
      allSendTeamScanReminderNotifications.map(
        ({ notificationId, teamId }) => ({
          text: (
            <p>
              Remind team members to take the TEAMscan for{" "}
              <b>{getTeamName(teamId, teamsById)}</b>.
            </p>
          ),
          primaryButton: {
            text: "Remind",
            onClick: () => {
              setShowRemindWarningModal({ teamId, notificationId });
            },
          },
          secondaryButton: {
            text: "Dismiss",
            onClick: () => {
              dispatch(dismissNotification(notificationId));
            },
          },
        })
      ),
    [allSendTeamScanReminderNotifications, dispatch, teamsById]
  );

  const getLaunchTeamScanBanner = useCallback((): TBanner[] => {
    const banner: TBanner[] = [];
    allLaunchTeamScanNotifications.forEach((notification) => {
      const { notificationId, teamId } = notification;
      const text = (
        <p>
          Launch a TEAMscan survey{" "}
          {teamId ? (
            <>
              for <b>{getTeamName(teamId, teamsById)}</b>
            </>
          ) : null}{" "}
          to start building teamwork on this team.
        </p>
      );

      banner.push({
        text,
        primaryButton: {
          text: "Launch survey",
          onClick: () => {
            if (teamId) {
              dispatch(showScheduleAssessmentModalForTeamId(teamId));
            } else {
              dispatch(showScheduleAssessmentModal());
            }
          },
        },
        secondaryButton: {
          text: "Dismiss",
          onClick: () => {
            dispatch(dismissNotification(notificationId));
          },
        },
      });
    });

    return banner;
  }, [allLaunchTeamScanNotifications, dispatch, teamsById]);

  const getNewDirectReportBanner = useCallback((): TBanner[] => {
    const banner: TBanner[] = [];
    allNewDirectReportNotifications.forEach((notification) => {
      const { notificationId, subjectUserAccountId } = notification;
      const userInfo = subjectUserAccountId && usersById[subjectUserAccountId];
      if (!userInfo) {
        return;
      }
      const { firstName, lastName } = userInfo;
      const text = (
        <p>
          You have a new direct report,{" "}
          <b>
            {firstName} {lastName}.
          </b>
        </p>
      );

      banner.push({
        text,
        primaryButton: {
          text: "View Details",
          onClick: () => {
            dispatch(
              setIsNewDirectReportModalOpen({
                userAccountIds: [Number(subjectUserAccountId)],
              })
            );
            dispatch(dismissNotification(notificationId));
          },
        },
        secondaryButton: {
          text: "Dismiss",
          onClick: () => {
            dispatch(dismissNotification(notificationId));
          },
        },
      });
    });

    return banner;
  }, [allNewDirectReportNotifications, dispatch, usersById]);

  const allBanners = useMemo(() => {
    const banners: TBanner[] = [
      ...getCreateTeamBanner(),
      ...getInviteNewMembersBanner(),
      ...getLaunchTeamScanBanner(),
      ...getRemindMembersBanner(),
      ...getPendingTeamBannerArray(),
      ...getUnreadAssessmentBannerArray(),
      ...getInvitationBannerArray(),
      ...getNewDirectReportBanner(),
    ];
    return banners;
  }, [
    getInvitationBannerArray,
    getPendingTeamBannerArray,
    getUnreadAssessmentBannerArray,
    getCreateTeamBanner,
    getInviteNewMembersBanner,
    getRemindMembersBanner,
    getLaunchTeamScanBanner,
    getNewDirectReportBanner,
  ]);

  const getNotificationBanner = ({
    text,
    primaryButton,
    secondaryButton,
    showLightbulb = true,
  }: TBanner) => (
    <div className="snapshot-box align-items-center">
      {showLightbulb ? (
        <FontAwesomeIcon icon={["far", "lightbulb-on"]} />
      ) : null}
      {typeof text === "string" ? <p>{text}</p> : text}
      {getNotificationBannerButtons(primaryButton, secondaryButton)}
    </div>
  );

  const getNotificationBannerButtons = (
    primaryButton?: ButtonParameter,
    secondaryButton?: ButtonParameter
  ) => {
    if (!primaryButton && !secondaryButton) return null;

    return (
      <div className="ms-auto row-gap-8px">
        {primaryButton ? (
          <Button variant="primary" onClick={primaryButton.onClick}>
            {primaryButton.text}
          </Button>
        ) : null}
        {secondaryButton ? (
          <Button
            variant="secondary-blue"
            className="white-button"
            onClick={secondaryButton.onClick}
          >
            {secondaryButton.text}
          </Button>
        ) : null}
      </div>
    );
  };

  const getTotalNotificationCount = () => {
    let bannerLength = allBanners.length;
    // since we are grouping the results available notifications we will need to make sure that the
    if (allAssessmentResultsNotifications.length > 1) {
      bannerLength += allAssessmentResultsNotifications.length - 1;
    }
    return bannerLength;
  };
  const totalNotificationCount = getTotalNotificationCount();

  const getEmptyState = () => {
    if (getNotificationsStatus === "loading") {
      return <Loading />;
    }
    return (
      <div
        className="empty-card column-gap-20px"
        style={{
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <div className="medium-square-icon sapphire-10">
          <FontAwesomeIcon icon={["fas", "stars"]} />
        </div>

        <span>No action needed right now</span>
        <div className="empty-card-text-container">
          <p
            style={{
              marginTop: "-20px",
            }}
          >
            We'll let you know if something comes up
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <InviteUsersModal
        showing={!!showInviteMemberModal}
        hideModal={() => {
          setShowInviteMemberModal(false);
        }}
        teamId={
          typeof showInviteMemberModal === "number"
            ? showInviteMemberModal
            : undefined
        }
        onInviteSuccess={() => setShowInviteMemberModal(false)}
      />
      <WarningModal
        modalTitle="Remind Team Members"
        warningTitle="Remind all team members who have not completed the TEAMscan?"
        warningMessage="Send out an email reminder to an team members who have not completed the TEAMscan survey for this instance."
        isOpen={!!showRemindWarningModal}
        onConfirmClick={async () => {
          if (!showRemindWarningModal) return;
          await dispatch(
            sendReminder({
              reminderType: "remindUserToTakeAssessment",
              teamId: showRemindWarningModal.teamId,
            })
          );
          dispatch(dismissNotification(showRemindWarningModal.notificationId));
          setShowRemindWarningModal(null);
        }}
        hideModal={() => {
          setShowRemindWarningModal(null);
        }}
        customButtonText="Send Reminder"
        isDanger={false}
      />
      <DashboardOnboardingCard
        title="To Do"
        headerButton={{
          text: "See All",
          onClick: onSeeAll,
        }}
        body={getBody()}
        secondaryButton={{
          text: "See all notifications",
          onClick: onSeeAll,
        }}
        footerText={`${totalNotificationCount} unread notification${getS(
          totalNotificationCount
        )}`}
        emptyState={getEmptyState()}
      />
    </>
  );
}
