import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";
import React, { useState, useEffect, useMemo, ReactElement } from "react";
import { Card, Collapse, OverlayTrigger, Popover } from "react-bootstrap";
import notableTraitIcon from "resources/icons/notable-trait.svg";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import EmptyTeamPersonalityIllustration from "resources/images/team-personality-empty.jpg";
import {
  selectPersonalityScoresByTeamId,
  selectGetTeamPersonalityScoresStatusByTeamId,
  getTeamPersonalityScores,
  getEppCompletionRate,
  selectGetEppCompletionRateStatus,
  selectEppCompletionRate,
  selectNewEventId,
} from "./slice";
import TraitCard from "./TraitCard";
import Loading from "app/storybookComponents/Loading";
import {
  selectTeamsByTeamId,
  selectSampleTeamsByTeamId,
  selectIsCurrentUserAdmin,
  selectAllCompanyUsersById,
} from "app/containers/Global/slice";
import TeamBlindSpotReport from "./TeamBlindSpotReport";
import TeamPotentialConflictAreas from "./TeamPotentialConflictAreas";
import TeamSuperPower from "./TeamSuperPower";
import WhatTeamMembersBringToThisTeamReport from "./WhatTeamMembersBringToThisTeamReport";
import RadarChartCard from "./RadarChartCard";
import { Team, UserInfo } from "app/containers/Global/types";
import {
  resetChatbotState,
  setCoachBotPrompt,
  setIsCoachbotOpen,
} from "app/containers/Chatbot/slice";
import { WORKPLACEINSIGHTS_COACHBOT_PROMPT } from "./constants";
import CustomChatbotButton from "app/containers/Chatbot/CustomChatbotButton";
import {
  selectCompanySettings,
  selectTeamAndDepartmentLeadIdsForLoggedInUser,
} from "app/containers/AdminConsole/slice";
import InviteEppModal from "../Modals/InviteEppModal";
import SimplePopover from "../Popovers/SimplePopover";
import { UserVisibilitySettings } from "app/containers/UserSettings/types";
import VisibilityBanner from "app/storybookComponents/WarningBanner/VisibilityBanner";
import { getYourVisibilityInTeamPersonalityBanner } from "app/containers/UserSettings/helpers";

interface Props {
  totalTeamMembers: number;
  teamId: number;
  userBelongsToTeam?: boolean;
  userVisibilitySettings?: UserVisibilitySettings | null;
}

export default function TeamPersonality({
  userVisibilitySettings,
  totalTeamMembers,
  teamId,
  userBelongsToTeam,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const teamsAndDepartmentsUserIsLeaderOf = useAppSelector(
    selectTeamAndDepartmentLeadIdsForLoggedInUser
  );
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const [showingAllTraits, setShowingAllTraits] = useState(false);
  const teamPersonalityScores = useAppSelector(
    selectPersonalityScoresByTeamId(teamId)
  );
  const getTeamPersonalityScoresStatus = useAppSelector(
    selectGetTeamPersonalityScoresStatusByTeamId(teamId)
  );
  const companySettings = useAppSelector(selectCompanySettings);
  const teamByTeamId = useAppSelector(selectTeamsByTeamId);
  const sampleTeamsByTeamId = useAppSelector(selectSampleTeamsByTeamId);
  const teamInfoById = useMemo(
    () => ({ ...teamByTeamId, ...sampleTeamsByTeamId }),
    [teamByTeamId, sampleTeamsByTeamId]
  );
  const team = useMemo(
    () => (teamInfoById[teamId] || null) as Team | null,
    [teamId, teamInfoById]
  );
  const [isLeaderOfTeam, setIsLeaderOfTeam] = useState(false);
  const [showInviteEppModal, setShowInviteEppModal] = useState(false);
  const [showViewInsightsPopover, setShowViewInsightsPopover] = useState(false);
  const getEppCompletionRateStatus = useAppSelector(
    selectGetEppCompletionRateStatus
  );
  const eppCompletionRate = useAppSelector(selectEppCompletionRate);
  const newEventId = useAppSelector(selectNewEventId);
  const userInfoById = useAppSelector(selectAllCompanyUsersById);
  const hasInviteAccess = isAdmin || isLeaderOfTeam;
  const hideInviteMembersToTakeEpp =
    !eppCompletionRate?.teamMemberCount || // if there are no team members
    eppCompletionRate?.teamMemberCount === // or if everyone has completed the EPP
      eppCompletionRate?.teamMembersWithEPPCount;
  const isSampleTeam = team?.isSampleTeam;

  useEffect(() => {
    // Otherwise, we need to fetch the team personality scores
    dispatch(getTeamPersonalityScores({ teamId }));
  }, [dispatch, teamId]);

  useEffect(() => {
    if (
      isAdmin ||
      teamsAndDepartmentsUserIsLeaderOf?.teams?.includes(teamId) ||
      (team?.departmentId &&
        teamsAndDepartmentsUserIsLeaderOf?.departments?.includes(
          team.departmentId
        ))
    ) {
      return setIsLeaderOfTeam(true);
    }

    setIsLeaderOfTeam(false);
  }, [teamId, teamsAndDepartmentsUserIsLeaderOf, isAdmin, team]);

  useEffect(() => {
    if (getEppCompletionRateStatus !== "loading" && teamId) {
      dispatch(getEppCompletionRate(teamId));
    }
  }, [dispatch, teamId, getEppCompletionRateStatus, newEventId]);

  const getPopoverUser = (user: UserInfo) => (
    <div key={user.userAccountId}>
      {user.firstName || user.lastName ? (
        <>
          <Link
            to={`/UserGuide/${user.userAccountId}`}
            style={{ color: "#425CC7" }}
          >
            {user.firstName} {user.lastName}
          </Link>
          <p style={{ color: "#86888B" }}>{user.jobTitle}</p>
        </>
      ) : (
        <p>{user.emailAddress}</p>
      )}
    </div>
  );

  const getEppCompletion = () => {
    const users: ReactElement[] = [];
    const pendingUsers: ReactElement[] = [];
    if (eppCompletionRate === null) {
      return { users, pendingUsers };
    }

    const allTeamMemberIds = team
      ? team.teamMemberIds?.concat(team.pendingTeamMemberIds || [])
      : [];

    const filteredAndSortedMembers = allTeamMemberIds
      ? [...new Set(allTeamMemberIds)]
          .filter((id) => userInfoById[id])
          .map((id) => userInfoById[id])
      : [];

    filteredAndSortedMembers?.forEach((user) => {
      if (eppCompletionRate.teamMembersWithEPPS?.includes(user.userAccountId)) {
        users.push(getPopoverUser(user));
        users.push(<hr className="m-0" />);
        return;
      }

      pendingUsers.push(getPopoverUser(user));
      pendingUsers.push(<hr className="m-0" />);
    });

    // removing last <hr> element
    users.pop();
    pendingUsers.pop();

    return (
      <>
        {users}
        {pendingUsers?.length ? (
          <>
            <hr
              className="m-0"
              style={{
                color: "#202d63",
              }}
            />
            <p>
              <b>Have Not Completed Employee Personality Profile (EPP)</b>
            </p>
            {pendingUsers}
          </>
        ) : null}
      </>
    );
  };

  const getEppUsersByVisibility = (): {
    visibleUserCount: number;
    hiddenUserCount: number;
    popoverElement: ReactElement;
  } => {
    const visibleUsers: ReactElement[] = [];
    const hiddenUsers: ReactElement[] = [];
    let visibleUserCount = 0;
    let hiddenUserCount = 0;
    eppCompletionRate?.teamMembersWithEPPS?.forEach((id) => {
      const isUserVisible = !!teamPersonalityScores?.teamMembers?.find(
        (tm) => tm.userAccountId === id
      );
      const user = userInfoById[id];
      if (!user) {
        return;
      }

      if (isUserVisible) {
        visibleUsers.push(getPopoverUser(user));
        visibleUsers.push(<hr className="m-0" />);
        visibleUserCount++;
        return;
      }
      hiddenUsers.push(getPopoverUser(user));
      hiddenUsers.push(<hr className="m-0" />);
      hiddenUserCount++;
    });

    // removing last <hr> element
    visibleUsers.pop();
    hiddenUsers.pop();

    return {
      visibleUserCount,
      hiddenUserCount,
      popoverElement: (
        <div
          className="column-gap-16px"
          style={{
            marginTop: "-40px",
          }}
        >
          {visibleUsers.length ? (
            <>
              <p className="pe-4">
                <b>Team members whose results are visible to you</b>
              </p>
              {visibleUsers}
            </>
          ) : null}
          {hiddenUsers.length ? (
            <>
              {visibleUsers.length ? (
                <hr
                  className="m-0"
                  style={{
                    color: "#202d63",
                  }}
                />
              ) : null}
              <p>
                <b>Team members whose results are not visible to you</b>
              </p>
              {hiddenUsers}
              <div className="row-gap-8px">
                <FontAwesomeIcon
                  icon={["far", "info-circle"]}
                  className="mt-1"
                />
                <p className="grey-text">
                  {companySettings?.allowVisibilityPermissionOverride
                    ? "Contact the team member you would like to view on this report to change visibility settings."
                    : "Contact an admin to change visibility defaults if you would like to view a team member on this report."}
                </p>
              </div>
            </>
          ) : null}
        </div>
      ),
    };
  };

  const getEppCompletionRateInfo = () => {
    if (eppCompletionRate === null) {
      return null;
    }

    return (
      <div className="completion-rate-div">
        {/* // if the team has no members, we don't want to show the completion rate */}
        {eppCompletionRate?.teamMembersWithEPPCount === 0 ? (
          <span style={{ fontWeight: "bold" }}>No members completed</span>
        ) : (
          <SimplePopover
            popoverTitle="Completed Employee Personality Profile"
            trigger="click"
            popoverContent={<>{getEppCompletion()}</>}
            popoverStyle={{
              maxWidth: "none",
            }}
          >
            <span className="completed-epp">
              {eppCompletionRate.teamMembersWithEPPCount} of {totalTeamMembers}{" "}
              members completed
            </span>
          </SimplePopover>
        )}
        <div>
          <p className="grey-text">the personality assessment (EPP)</p>
        </div>
      </div>
    );
  };
  const getShowingCount = () => {
    const { visibleUserCount, hiddenUserCount, popoverElement } =
      getEppUsersByVisibility();

    return (
      <span className="completed-epp d-inline-flex">
        <SimplePopover
          popoverContent={<>{popoverElement}</>}
          popoverStyle={{
            maxWidth: "400px",
          }}
        >
          <>
            Showing {visibleUserCount} of {hiddenUserCount + visibleUserCount}{" "}
            members with completed results
            <FontAwesomeIcon
              icon={["far", "question-circle"]}
              className="ms-1"
            />
          </>
        </SimplePopover>
      </span>
    );
  };

  const getTraitCardsHeader = () => {
    if (teamPersonalityScores === null) {
      return <h2>Team Personality Traits</h2>;
    }

    const isViewInsightsButtonDisabled = !!(
      teamPersonalityScores &&
      (!teamPersonalityScores.teamMembers?.length ||
        teamPersonalityScores.teamMembers?.length < 4)
    );

    const overlay = (
      <Popover className="team-leader-popover">
        <div className="column-gap-16px">
          Insights are available once this team has at least 4 completed
          assessments with results shared with you.
        </div>
      </Popover>
    );

    return (
      <div className="column-gap-20px">
        <div className="d-flex justify-content-between">
          <h2>Team Personality Traits</h2>
          <OverlayTrigger
            overlay={overlay}
            trigger="hover"
            placement="left"
            show={showViewInsightsPopover && isViewInsightsButtonDisabled}
            onToggle={setShowViewInsightsPopover}
          >
            <div
              style={{
                cursor: isViewInsightsButtonDisabled
                  ? "not-allowed"
                  : undefined,
              }}
            >
              <Button
                disabled={isViewInsightsButtonDisabled}
                onClick={() => {
                  if (isViewInsightsButtonDisabled) return;
                  navigate(`/TeamGuide/${teamId}/TeamPersonality`);
                }}
                variant="secondary-blue"
                className="border-0"
              >
                View Insights
                <FontAwesomeIcon icon="arrow-right" className="ms-2" />
              </Button>
            </div>
          </OverlayTrigger>
        </div>
        <p className="team-personality-paragraph">
          This section shows how your team members work according to 10
          different behavioral traits. Notable traits are marked with a
          <img src={notableTraitIcon} alt="notable trait" />
          and most strongly characterize your team.
        </p>
        <div
          className="row-gap-8px"
          style={{
            color: "#53565a",
            marginTop: "-8px",
          }}
        >
          {isSampleTeam ? null : (
            <>
              <FontAwesomeIcon icon={["far", "info-circle"]} className="mt-1" />
              <p>
                Not seeing a team member in this section? Only team members who
                have joined the team, completed their personality test, and
                chosen to be visible on Team Reports will appear on continuums.
                A team member can change their user permissions in their user
                settings. {getShowingCount()}
              </p>
            </>
          )}
        </div>

        {userBelongsToTeam &&
        companySettings?.allowVisibilityPermissionOverride ? (
          <VisibilityBanner
            style={{
              marginTop: "-8px",
              marginBottom: "0",
            }}
            button={{
              text: "Configure visibility",
              onClick: () => {
                navigate("/UserSettings");
              },
            }}
          >
            {getYourVisibilityInTeamPersonalityBanner({
              userVisibilitySettings,
            })}
          </VisibilityBanner>
        ) : null}
        {/* vvv This is a hack to add extra spacing at the bottom of the header vvv */}
        <div />
      </div>
    );
  };

  const { notableTraits, nonNotableTraits } = useMemo(() => {
    const notableTraits: React.JSX.Element[] = [];
    const nonNotableTraits: React.JSX.Element[] = [];
    if (!teamPersonalityScores?.teamScores) {
      return { notableTraits, nonNotableTraits };
    }

    Object.entries(teamPersonalityScores.teamScores).forEach(([key, value]) => {
      const traitCard = (
        <TraitCard
          traitAbbrev={key}
          teamMemberScores={teamPersonalityScores.teamMembers}
          isNotableTrait={!!value.isNotableTrait}
        />
      );
      if (!traitCard) return;
      if (value.isNotableTrait) {
        notableTraits.push(traitCard);
      } else {
        nonNotableTraits.push(traitCard);
      }
    });
    return { notableTraits, nonNotableTraits };
  }, [teamPersonalityScores]);

  const getTraitCardsContent = () => {
    if (getTeamPersonalityScoresStatus === "loading") {
      return <Loading />;
    }

    return (
      <>
        <div className="trait-cards">{notableTraits}</div>
        <Collapse in={showingAllTraits}>
          <div className="trait-cards">{nonNotableTraits}</div>
        </Collapse>
        <div className="trait-cards-expand-button-container">
          <Button
            variant="secondary-blue"
            onClick={() => {
              setShowingAllTraits(!showingAllTraits);
            }}
            className="border-0"
          >
            {showingAllTraits ? "Show notable traits" : "Show all traits"}
            <FontAwesomeIcon
              icon={showingAllTraits ? "chevron-up" : "chevron-down"}
              className="ms-2"
            />
          </Button>
        </div>
      </>
    );
  };

  const getTeamPersonalitySummaryText = () => {
    if (!teamPersonalityScores || !team?.teamMemberIds) {
      return null;
    }
    return (
      <p>
        See how personalities shape your team's behaviors. Only members who have
        taken the assessment and enabled visibility to their results will be
        shown in this report. Encourage your team members to take the assessment
        and share results with your team. This report will be more accurate and
        valuable if it reflects all team members on this team.
      </p>
    );
  };

  const getCoachBotPreselectedPrompts = () => {
    if (userBelongsToTeam && isLeaderOfTeam) {
      return [
        "Help me understand our team's personality",
        "Help me understand the relative strengths a specific individual brings to this team",
        "Help me understand learning opportunities on this team",
      ];
    }
    if (userBelongsToTeam) {
      return [
        "Help me understand our team's personality",
        "Help me understand the relative strengths I bring to this team",
        "Help me reflect on what I could learn from my teammates",
      ];
    }
    if (isLeaderOfTeam) {
      return [
        "Help me understand this team's personality",
        "Help me understand the relative strengths of any individual on this team",
        "Help me understand learning opportunities on this team",
      ];
    }
    return [];
  };

  const getTeamPersonalityReportHeader = () => {
    if (!hasInviteAccess) {
      return <h2>Team Personality Report</h2>;
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h2>Team Personality Report</h2>
        <div style={{ display: "flex", alignItems: "center" }}>
          {getEppCompletionRateInfo()}
          {hideInviteMembersToTakeEpp ? null : (
            <>
              <div className="team-personality-separator"></div>
              <div>
                <Button
                  onClick={() => setShowInviteEppModal(true)}
                  variant="secondary-blue"
                >
                  <FontAwesomeIcon icon="user-plus" className="me-2" />
                  Invite members to complete
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const onCoachbotClick = () => {
    dispatch(resetChatbotState());
    const template = WORKPLACEINSIGHTS_COACHBOT_PROMPT["teamPersonalityGuide"];
    dispatch(
      setCoachBotPrompt({
        userPrompts: getCoachBotPreselectedPrompts(),
        coachBotIntro: template.coachBotIntro,
        chatType: template.chatType ?? "",
        type: "personalityReport",
        report: teamPersonalityScores,
      })
    );
    dispatch(setIsCoachbotOpen(true));
  };

  const getContent = () => {
    if (getTeamPersonalityScoresStatus === "failed" && !teamPersonalityScores) {
      return (
        <div
          className="empty-card"
          style={{
            padding: "32px",
          }}
        >
          <div className="column-gap-12px">
            <span>
              <b>Unable To View Report</b>
            </span>
            <p>
              Unfortunately, you don’t have access to this report. Please reach
              out to the team leader or admin to change this team’s visibility
              settings.
            </p>
          </div>
        </div>
      );
    }

    if (!teamPersonalityScores) {
      return (
        <div className="column-gap-20px align-items-center">
          <img
            src={EmptyTeamPersonalityIllustration}
            alt="empty team personality illustration"
            style={{
              width: "200px",
              height: "200px",
            }}
          />
          <p>
            Once {userBelongsToTeam ? "your team" : "this team's"} members have
            taken their personality assessment, their results will appear here.
          </p>
          {hasInviteAccess && !hideInviteMembersToTakeEpp ? (
            <div>
              <Button onClick={() => setShowInviteEppModal(true)}>
                <FontAwesomeIcon icon="user-plus" className="me-2" />
                Invite members to complete
              </Button>
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <>
        {teamPersonalityScores.teamMembers?.length ? (
          <Card>
            {getTraitCardsHeader()}
            {getTraitCardsContent()}
          </Card>
        ) : null}
        <TeamSuperPower
          superPower={teamPersonalityScores?.superPower || null}
        />
        <RadarChartCard
          teamPersonalityScores={teamPersonalityScores}
          teamId={teamId}
        />
        <TeamBlindSpotReport
          blindSpots={teamPersonalityScores?.blindSpots || []}
        />
        <WhatTeamMembersBringToThisTeamReport
          teamMembers={teamPersonalityScores?.teamMembers || []}
          whatTeamMembersBringToThisTeam={
            teamPersonalityScores?.whatTeamMembersBringToThisTeam || {}
          }
        />
        <TeamPotentialConflictAreas
          conflictAreas={teamPersonalityScores?.conflictAreas || []}
        />
      </>
    );
  };

  if (getTeamPersonalityScoresStatus === "loading") {
    return (
      <div className="team-personality-content">
        <Card className="team-personality-content">
          <h2>Team Personality Report</h2>
          <Loading />
        </Card>
      </div>
    );
  }

  return (
    <>
      <InviteEppModal
        show={showInviteEppModal}
        onHide={() => setShowInviteEppModal(false)}
        defaultId={teamId}
      />
      <div className="team-personality-content">
        <Card className="team-personality-content">
          {getTeamPersonalityReportHeader()}
          {getTeamPersonalitySummaryText()}
          {getContent()}
        </Card>
        {companySettings?.aiFeaturesEnabled &&
        teamPersonalityScores &&
        (userBelongsToTeam || isLeaderOfTeam) ? (
          <CustomChatbotButton
            onCoachbotClick={onCoachbotClick}
            text="Need help understanding this Team Personality Report?"
          />
        ) : null}
      </div>
    </>
  );
}
