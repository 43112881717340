import { Modal } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import { selectWordCloud, selectTextSynthesis } from "../slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector } from "utils/redux/hooks";
import CollapsibleCard from "app/components/AnonymousFeedback/CollapsibleCard";
import WordCloudChart from "app/storybookComponents/Charts/WordCloudChart";
import { useMemo } from "react";
import { TextInsightResponse } from "../types";

interface Props {
  show: boolean;
  textInsights: TextInsightResponse | null;
  entityType: "department" | "organization";
  onHide: () => void;
}

export default function FreeTextInsightsModal({
  show,
  textInsights,
  entityType,
  onHide,
}: Props) {
  const textSynthesis = useAppSelector(selectTextSynthesis);
  const wordCloudData = useAppSelector(selectWordCloud);

  const getWordCloudVisual = useMemo(() => {
    if (!wordCloudData) {
      return null;
    }

    return (
      <WordCloudChart canvasId="free-text-word-chart" data={wordCloudData} />
    );
  }, [wordCloudData]);

  const getSnapShot = () => {
    if (!textSynthesis) {
      return null;
    }
    return (
      <div className="snapshot-box">
        <FontAwesomeIcon icon={["far", "lightbulb-on"]} />
        <div>
          <p>Synthesis: {textSynthesis.themeText}</p>
          <p>{textSynthesis.skillsText}</p>
        </div>
      </div>
    );
  };

  const getDoingWell = () => {
    if (!textInsights) {
      return null;
    }
    return (
      <div>
        <h4>
          Based on {textInsights.responsesCount} survey responses, here are the
          top themes that emerged about what this {entityType} is doing well:
        </h4>
        <div className="collapsible-cards-container">
          {textInsights?.doingWell?.map((item, index) => {
            return (
              <CollapsibleCard
                key={index}
                title={item.theme}
                feedbackArr={[
                  {
                    feedbackText: item.response,
                    feedbackDate: item.responseInfo?.dateResponseGiven,
                    from: item.responseInfo?.department
                      ? item.responseInfo.department
                      : "No Department",
                    type: "Department",
                  },
                ]}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const getCouldBeBetter = () => {
    if (!textInsights) {
      return null;
    }
    return (
      <div>
        <h4>
          Based on {textInsights.responsesCount} survey responses, here are the
          top themes that emerged about what this {entityType} could be doing
          better:
        </h4>
        <div className="collapsible-cards-container">
          {textInsights?.couldImproveOn?.map((item, index) => {
            return (
              <CollapsibleCard
                key={index}
                title={item.theme}
                feedbackArr={[
                  {
                    feedbackText: item.response,
                    feedbackDate: item.responseInfo?.dateResponseGiven,
                    from: item.responseInfo?.department
                      ? item.responseInfo.department
                      : "No Department",
                    type: "Department",
                  },
                ]}
              />
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      className="simple-modal free-text-insights-modal"
    >
      <div className="modal-title-row mb-0">
        <h2>Free Text Insights</h2>
        <Button
          onClick={() => onHide()}
          variant={"secondary-blue"}
          style={{ border: "none", width: "auto" }}
          xIcon
        />
      </div>
      {getWordCloudVisual}
      {getSnapShot()}
      <hr />
      {getDoingWell()}
      <hr />
      {getCouldBeBetter()}
    </Modal>
  );
}
