import Checkbox from "app/components/Checklists/Checkbox";
import SimpleModalFooter from "app/components/Modals/SimpleModalFooter";
import { getMustSelectUserRoleErrorBanner } from "../helpers";
import { useEffect, useState } from "react";
import { CHECKLIST_KEY_TO_LABEL_MAP } from "../constant";
import { SendEmailOptions } from "../types";
interface Props {
  onBack: () => void;
  onSave: (selectedOptionValues?: { [type: string]: 0 | 1 | boolean }) => void;
  sendEmailOptions: SendEmailOptions;
  getWarningBanner?: (sendToType: string) => JSX.Element | null;
}

const OrganizationEmailConfig = ({
  onBack,
  onSave,
  sendEmailOptions,
  getWarningBanner,
}: Props) => {
  // -------------------- State --------------------
  const [areTeamLeadersSelected, setAreTeamLeadersSelected] = useState(false);
  const [areTeamMembersSelected, setAreTeamMembersSelected] = useState(false);
  const [areDepartmentLeadersSelected, setAreDepartmentLeadersSelected] =
    useState(false);
  const [areManagersSelected, setAreManagersSelected] = useState(false);
  const [areAllOtherUsersSelected, setAreAllOtherUsersSelected] =
    useState(false);
  const [showMustSelectCheckboxError, setShowMustSelectCheckboxError] =
    useState(false);
  const [areAdminsSelected, setAreAdminsSelected] = useState(false);

  // -------------------- Effects --------------------
  useEffect(() => {
    setAreTeamLeadersSelected(!!sendEmailOptions.teamLeaders);
    setAreTeamMembersSelected(!!sendEmailOptions.teamMembers);
    setAreDepartmentLeadersSelected(!!sendEmailOptions.departmentLeaders);
    setAreManagersSelected(!!sendEmailOptions.managers);
    setAreAllOtherUsersSelected(!!sendEmailOptions.nonTeamMembers);
  }, [sendEmailOptions]);

  // -------------------- Functions --------------------
  const onEveryoneChange = () => {
    const newValue = !(
      areTeamLeadersSelected &&
      areTeamMembersSelected &&
      areDepartmentLeadersSelected &&
      areManagersSelected &&
      areAllOtherUsersSelected
    );
    setAreTeamLeadersSelected(newValue);
    setAreTeamMembersSelected(newValue);
    setAreDepartmentLeadersSelected(newValue);
    setAreManagersSelected(newValue);
    setAreAllOtherUsersSelected(newValue);
    setAreAdminsSelected(newValue);
  };

  const onCheckboxChangeSideEffect = () => {
    setShowMustSelectCheckboxError(false);
  };

  return (
    <>
      <div className="column-gap-8px">
        <p className="navy-text">Send this email to: </p>
        <Checkbox
          id="everyone"
          label="Everyone"
          onChange={onEveryoneChange}
          value={
            areTeamLeadersSelected &&
            areTeamMembersSelected &&
            areDepartmentLeadersSelected &&
            areManagersSelected &&
            areAllOtherUsersSelected &&
            areAdminsSelected
          }
        />
        <hr className="m-0" />
        <Checkbox
          id="admin"
          label={CHECKLIST_KEY_TO_LABEL_MAP.admin}
          onChange={() => {
            onCheckboxChangeSideEffect();
            setAreAdminsSelected(!areAdminsSelected);
          }}
          value={areAdminsSelected}
        />
        <Checkbox
          id="departmentLeaders"
          label={CHECKLIST_KEY_TO_LABEL_MAP.departmentLeaders}
          onChange={() => {
            onCheckboxChangeSideEffect();
            setAreDepartmentLeadersSelected(!areDepartmentLeadersSelected);
          }}
          value={areDepartmentLeadersSelected}
        />
        <Checkbox
          id="teamLeaders"
          label={CHECKLIST_KEY_TO_LABEL_MAP.teamLeaders}
          onChange={() => {
            onCheckboxChangeSideEffect();
            setAreTeamLeadersSelected(!areTeamLeadersSelected);
          }}
          value={areTeamLeadersSelected}
        />
        <Checkbox
          id="managers"
          label={CHECKLIST_KEY_TO_LABEL_MAP.managers}
          onChange={() => {
            onCheckboxChangeSideEffect();
            setAreManagersSelected(!areManagersSelected);
          }}
          value={areManagersSelected}
        />
        <Checkbox
          id="teamMembers"
          label={CHECKLIST_KEY_TO_LABEL_MAP.teamMembers}
          onChange={() => {
            onCheckboxChangeSideEffect();
            setAreTeamMembersSelected(!areTeamMembersSelected);
          }}
          value={areTeamMembersSelected}
        />
        <Checkbox
          id="nonTeamMembers"
          label={CHECKLIST_KEY_TO_LABEL_MAP.nonTeamMembers}
          onChange={() => {
            onCheckboxChangeSideEffect();
            setAreAllOtherUsersSelected(!areAllOtherUsersSelected);
          }}
          value={areAllOtherUsersSelected}
        />
      </div>
      {getWarningBanner?.("organization") ?? null}
      {showMustSelectCheckboxError && getMustSelectUserRoleErrorBanner()}
      <SimpleModalFooter
        onBackButtonClick={onBack}
        onPrimaryButtonClick={() => {
          if (
            !areTeamLeadersSelected &&
            !areTeamMembersSelected &&
            !areDepartmentLeadersSelected &&
            !areManagersSelected &&
            !areAllOtherUsersSelected &&
            !areAdminsSelected
          ) {
            return setShowMustSelectCheckboxError(true);
          }

          onSave({
            teamLeaders: areTeamLeadersSelected,
            teamMembers: areTeamMembersSelected,
            departmentLeaders: areDepartmentLeadersSelected,
            managers: areManagersSelected,
            nonTeamMembers: areAllOtherUsersSelected,
            admin: areAdminsSelected,
          });
        }}
        isPrimaryButtonDisabled={showMustSelectCheckboxError}
        primaryButtonText="Next"
      />
    </>
  );
};

export default OrganizationEmailConfig;
