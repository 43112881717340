import SimpleModal from "app/components/Modals/SimpleModal";
import Button from "app/storybookComponents/Button";
import { useAppSelector } from "utils/redux/hooks";
import {
  selectVisibilityPermissionDifferCountStatus,
  selectUserVisibilityUpdatedEntities,
} from "../slice";
import Loading from "app/storybookComponents/Loading";
import { selectEmailManagerSettings } from "app/components/EmailManager/slice";
import { getHasOrHave, getS } from "utils/helperFunctions";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (isToggleOn: boolean) => void;
  visibilitySettingSelected: number | null;
  isLoading?: boolean;
}

export default function ConfirmSaveVisibilityDefaultsModal({
  isOpen,
  onClose,
  visibilitySettingSelected,
  onSave,
  isLoading,
}: Readonly<Props>) {
  const getUpdateEntityCountStatus = useAppSelector(
    selectVisibilityPermissionDifferCountStatus
  );
  const visibilityUpdatedEntities = useAppSelector(
    selectUserVisibilityUpdatedEntities
  );
  const emailManagementSettings = useAppSelector(selectEmailManagerSettings);

  const getWarningBanner = () => {
    if (getUpdateEntityCountStatus === "loading") {
      return <Loading />;
    }

    const userVisibilityUpdatedEntities = visibilitySettingSelected
      ? visibilityUpdatedEntities[visibilitySettingSelected]
      : null;
    const teamCount =
      userVisibilityUpdatedEntities?.differingTeamIds.length ?? 0;
    const userCount =
      userVisibilityUpdatedEntities?.differingUserAccountIds.length ?? 0;

    if (!teamCount && !userCount) {
      return null;
    }

    let countString = "";
    if (userCount) {
      countString += `${userCount} user${getS(userCount)}`;
    }
    if (teamCount) {
      countString += userCount ? " and " : "";
      countString += `${teamCount} team${getS(teamCount)}`;
    }

    return (
      <div className="warning-banner gold">
        <p>
          {countString} {getHasOrHave(userCount + teamCount)} customized their
          settings. Selecting "reconfigure everyone's settings" will change
          their settings to the new company defaults
          {emailManagementSettings?.visibilityUpdateIsEnabled
            ? " and send an email notifying them"
            : null}
          .
        </p>
      </div>
    );
  };

  const isActionButtonDisabled =
    visibilitySettingSelected === null || isLoading;

  return (
    <SimpleModal
      show={isOpen}
      onHide={onClose}
      title="Automatically Reconfigure Settings"
      className="confirm-save-visibility-defaults-modal"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          gap: "20px",
        }}
      >
        <h3 className="navy-text">
          Automatically reconfigure everyone's visibility settings to match
          these defaults?
        </h3>
        <div
          style={{
            marginTop: "-10px",
          }}
          className="navy-text"
        >
          <p>
            Opt to automatically reconfigure <b>all</b> user and team settings
            to match these organization visibility defaults.
          </p>
          <div
            className="row-gap-8px"
            style={{
              padding: "0 8px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <hr style={{ width: "120px" }} />
            <p>
              <b>OR</b>
            </p>
            <hr style={{ width: "120px" }} />
          </div>
          <p>
            <b>Only</b> reconfigure <b>unmodified</b> user and team settings
            (settings that still match the organization's defaults) to match
            these visibility defaults. If user or team settings have been
            modified, their settings will remain the same.
          </p>
        </div>
        {getWarningBanner()}
        <div className="row-gap-16px">
          <Button
            onClick={() => onSave(true)}
            disabled={isActionButtonDisabled}
          >
            Reconfigure everyone's settings
          </Button>
          <Button
            variant="secondary-blue"
            onClick={() => onSave(false)}
            disabled={isActionButtonDisabled}
          >
            Only reconfigure unmodified settings
          </Button>
        </div>
      </div>
    </SimpleModal>
  );
}
