import { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import { RowContent } from "app/components/SortableTable/types";
import SortableTable from "app/components/SortableTable";
import Toggle from "../Toggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SendEmailModal from "./SendEmailModal/SendEmailModal";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  getEmailManagerSettings,
  selectEmailManagerSettings,
  sendPendingEmails,
  updateEmailManagerSettings,
  selectSendPreviewEmailStatus,
  selectSendPendingEmailsStatus,
  selectValidUserListForEmails,
  getValidUserList,
} from "./slice";
import {
  EmailManagementSettings,
  SendToType,
  EmailManagementValidEmailType,
} from "./types";
import {
  checkIfUserHasBeenInvited,
  getInviteToOrgOptionsWarningBanner,
  getEppInviteOptionsWarningBanner,
  getTeamScanInviteOptionsWarningBanner,
  getTeamInviteOptionsWarningBanner,
  checkIfUserShouldNotReceiveEmail,
  titleAndDescriptionForPreviewEmailModal,
} from "./helpers";
import { selectAllCompanyUsersById } from "app/containers/Global/slice";
import PreviewEmailModal from "./PreviewEmailModal/PreviewEmailModal";
import { trackUserViewedEmailManager } from "utils/trackingFunctions";

const EmailManager = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectAllCompanyUsersById);
  const [showAutomatedEmailSettings, setShowAutomatedEmailSettings] =
    useState(false);
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] = useState(false);
  const [sendEppInvitationEmailModal, setSendEppInvitationEmailModal] =
    useState(false);
  const [previewEmailModalState, setPreviewEmailModalState] = useState<{
    emailType?: EmailManagementValidEmailType;
    isOpen: boolean;
  }>({ isOpen: false });

  const [isSendTeamScanEmailModalOpen, setIsSendTeamScanEmailModalOpen] =
    useState(false);
  const [isSendTeamInviteEmailModalOpen, setIsSendTeamInviteEmailModalOpen] =
    useState(false);
  const emailManagementSettings = useAppSelector(selectEmailManagerSettings);
  const sendPreviewEmailStatus = useAppSelector(selectSendPreviewEmailStatus);
  const sendPendingEmailsStatus = useAppSelector(selectSendPendingEmailsStatus);
  const validUserListForEmails = useAppSelector(selectValidUserListForEmails);

  useEffect(() => {
    dispatch(getEmailManagerSettings());
  }, [dispatch]);

  useEffect(() => {
    trackUserViewedEmailManager();
  }, []);

  const getActionCellDisplay = ({
    emailButtonHandlers,
    isToggleOn,
    emailManagementSettingsKey,
  }: {
    emailButtonHandlers?: {
      onPreview?: () => void;
      onSend?: () => void;
    };
    isToggleOn?: boolean;
    emailManagementSettingsKey?: keyof EmailManagementSettings;
  }) => (
    <div className="toggle-container">
      <div>
        {emailManagementSettingsKey ? (
          <Toggle
            isOn={!!isToggleOn}
            handleToggle={() => {
              dispatch(
                updateEmailManagerSettings({
                  updatedEmailSettings: {
                    [emailManagementSettingsKey]: isToggleOn ? 0 : 1,
                  },
                })
              );
            }}
          />
        ) : null}
      </div>
      {emailButtonHandlers ? (
        <div className="row-gap-8px">
          {emailButtonHandlers.onPreview ? (
            <Button
              variant="secondary-blue"
              onClick={emailButtonHandlers.onPreview}
            >
              Preview
            </Button>
          ) : null}
          {emailButtonHandlers.onSend ? (
            <Button onClick={emailButtonHandlers.onSend}>Send Email</Button>
          ) : null}
        </div>
      ) : null}
    </div>
  );

  const getDescription = (description: string) => ({
    displayValue: (
      <div>
        <p>{description}</p>
      </div>
    ),
    sortValue: description,
  });

  const getUsersWhoDoNotNeedEmail = (
    emailType: EmailManagementValidEmailType
  ) => {
    dispatch(getValidUserList(emailType));
  };

  const mainTableRows: RowContent[] = [
    {
      emailName: "Welcome to Develop Email",
      description: getDescription(
        "An email to invite users to join this organization in Develop. This can only be sent to users who have been added to Develop but have not logged in yet."
      ),
      byDefaultColumn: {
        displayValue: getActionCellDisplay({
          emailButtonHandlers: {
            onPreview: () =>
              setPreviewEmailModalState({
                emailType: "inviteToOrganization",
                isOpen: true,
              }),
            onSend: () => {
              setIsSendEmailModalOpen(true);
              getUsersWhoDoNotNeedEmail("inviteToOrganization");
            },
          },
          isToggleOn: !!emailManagementSettings?.inviteToOrganizationIsEnabled,
          emailManagementSettingsKey: "inviteToOrganizationIsEnabled",
        }),
        sortValue: true,
      },
    },
    {
      emailName: "TEAMscan Invite Email",
      description: getDescription(
        "An email to invite users to complete the TEAMscan survey. This can only be sent to users with an active TEAMscan survey that still needs to be completed."
      ),
      byDefaultColumn: {
        displayValue: getActionCellDisplay({
          emailButtonHandlers: {
            onPreview: () =>
              setPreviewEmailModalState({
                emailType: "TEAMScanInvite",
                isOpen: true,
              }),
            onSend: () => {
              setIsSendTeamScanEmailModalOpen(true);
              getUsersWhoDoNotNeedEmail("TEAMScanInvite");
            },
          },
          isToggleOn: !!emailManagementSettings?.TEAMScanInviteIsEnabled,
          emailManagementSettingsKey: "TEAMScanInviteIsEnabled",
        }),
        sortValue: true,
      },
    },
    {
      emailName: "Team Invite Email",
      description: getDescription(
        "An email requesting users to accept team invitations in Develop. This can only be sent to users who have a pending invitation to join a team."
      ),
      byDefaultColumn: {
        displayValue: getActionCellDisplay({
          emailButtonHandlers: {
            onPreview: () =>
              setPreviewEmailModalState({
                emailType: "teamInvite",
                isOpen: true,
              }),
            onSend: () => {
              setIsSendTeamInviteEmailModalOpen(true);
              getUsersWhoDoNotNeedEmail("teamInvite");
            },
          },
          isToggleOn: !!emailManagementSettings?.teamInviteIsEnabled,
          emailManagementSettingsKey: "teamInviteIsEnabled",
        }),
        sortValue: true,
      },
    },
    {
      emailName: "EPP Invite Email",
      description: getDescription(
        "An email to invite users to complete the Employee Personality Profile (EPP). This can only be sent to users who have not completed the EPP or connected past results."
      ),
      byDefaultColumn: {
        displayValue: getActionCellDisplay({
          emailButtonHandlers: {
            onPreview: () =>
              setPreviewEmailModalState({
                emailType: "eppInvite",
                isOpen: true,
              }),
            onSend: () => {
              setSendEppInvitationEmailModal(true);
              getUsersWhoDoNotNeedEmail("eppInvite");
            },
          },
        }),
        sortValue: true,
      },
    },
  ];

  const automatedEmailsRows = [
    {
      emailName: "Visibility Update Email",
      description: getDescription(
        "An email is sent automatically to users when changes to the organization-wide visibility defaults modify their settings for personality and teamwork reports."
      ),
      enabledOrDisabledColumn: {
        displayValue: getActionCellDisplay({
          isToggleOn: !!emailManagementSettings?.visibilityUpdateIsEnabled,
          emailManagementSettingsKey: "visibilityUpdateIsEnabled",
        }),
        sortValue: true,
      },
    },
    {
      emailName: "New Direct Report Email",
      description: getDescription(
        "An email is automatically sent to managers notifying them of their new direct report(s) when a manager is assigned to a user."
      ),
      enabledOrDisabledColumn: {
        displayValue: getActionCellDisplay({
          isToggleOn: !!emailManagementSettings?.newDirectReportIsEnabled,
          emailManagementSettingsKey: "newDirectReportIsEnabled",
        }),
        sortValue: true,
      },
    },
  ];

  const { title, titleDescriptionText } =
    titleAndDescriptionForPreviewEmailModal(previewEmailModalState?.emailType);

  return (
    <>
      {/* Invite user to organization email modal */}
      <SendEmailModal
        title="Send Organization Invite Email"
        isOpen={isSendEmailModalOpen}
        closeModal={() => setIsSendEmailModalOpen(false)}
        titleDescription="Send the organization invite email to select users, teams, departments, or the entire organization. This can only be sent to users who have been added to Develop but have not logged in yet."
        userConfig={{
          checkIfUserHasError: (UID) => checkIfUserHasBeenInvited(UID, users),
          userErrorMessage:
            "This user has already joined your organization in Develop.",
        }}
        onSave={async (sendToType: SendToType, ids: number[], options) => {
          await dispatch(
            sendPendingEmails({
              sendToType,
              ids,
              options,
              emailType: "inviteToOrganization",
            })
          );
        }}
        isLoading={sendPendingEmailsStatus === "loading"}
        getWarningBanner={getInviteToOrgOptionsWarningBanner}
        confirmationDescriptionText="You’re sending an organization invite email to"
        sendToTypeDefault="organization"
      />
      {/* Send Epp Invite Email */}
      <SendEmailModal
        title="Send Epp Invite Email"
        titleDescription="Send the EPP (Employee Personality Profile) invite email to select users, teams, departments, or the entire organization. This can only be sent to users who have not completed the EPP or connected past results."
        userConfig={{
          checkIfUserHasError: (UID) =>
            checkIfUserShouldNotReceiveEmail(
              UID,
              validUserListForEmails["eppInvite"]
            ),
          userErrorMessage:
            "This user has already completed the EPP or connected past results.",
        }}
        isOpen={sendEppInvitationEmailModal}
        closeModal={() => setSendEppInvitationEmailModal(false)}
        getWarningBanner={getEppInviteOptionsWarningBanner}
        onSave={async (sendToType: SendToType, ids: number[], options) => {
          await dispatch(
            sendPendingEmails({
              sendToType,
              ids,
              options,
              emailType: "eppInvite",
            })
          );
        }}
        confirmationDescriptionText="You’re sending an EPP invite email to"
        sendToTypeDefault="users"
      />
      {/* Team Invite Email */}
      <SendEmailModal
        title="Send Team Invite Email"
        titleDescription="Send the team invite email to select users, teams, departments, or the entire organization. This can only be sent to users who have a pending invitation to join a team."
        userConfig={{
          checkIfUserHasError: (UID) =>
            checkIfUserShouldNotReceiveEmail(
              UID,
              validUserListForEmails["teamInvite"]
            ),
          userErrorMessage: "This user doesn't have a pending team invitation.",
        }}
        getWarningBanner={getTeamInviteOptionsWarningBanner}
        isOpen={isSendTeamInviteEmailModalOpen}
        closeModal={() => setIsSendTeamInviteEmailModalOpen(false)}
        onSave={async (sendToType: SendToType, ids: number[], options) => {
          await dispatch(
            sendPendingEmails({
              sendToType,
              ids,
              options,
              emailType: "teamInvite",
            })
          );
        }}
        confirmationDescriptionText="You’re sending a team invite email to"
      />
      {/* TeamScan Invite Email */}
      <SendEmailModal
        title="Send TEAMscan Invite Email"
        titleDescription="Send the TEAMscan invite email to select users, teams, departments, or the entire organization. This can only be sent to users with a pending TEAMscan survey."
        userConfig={{
          checkIfUserHasError: (UID) =>
            checkIfUserShouldNotReceiveEmail(
              UID,
              validUserListForEmails["TEAMScanInvite"]
            ),
          userErrorMessage: "This user doesn't have an active TEAMscan survey.",
        }}
        getWarningBanner={getTeamScanInviteOptionsWarningBanner}
        isOpen={isSendTeamScanEmailModalOpen}
        closeModal={() => setIsSendTeamScanEmailModalOpen(false)}
        onSave={async (sendToType: SendToType, ids: number[], options) => {
          await dispatch(
            sendPendingEmails({
              sendToType,
              ids,
              options,
              emailType: "TEAMScanInvite",
            })
          );
        }}
        confirmationDescriptionText="You’re sending a TEAMscan invite email to"
      />
      <PreviewEmailModal
        onClose={() => setPreviewEmailModalState({ isOpen: false })}
        emailType={previewEmailModalState?.emailType}
        isOpen={previewEmailModalState.isOpen}
        title={title}
        titleDescriptionText={titleDescriptionText}
        isLoading={sendPreviewEmailStatus === "loading"}
      />
      <h2>Emails & Notifications</h2>
      <p style={{ margin: "-16px 0px -4px 0px" }}>
        Send system emails and configure which emails are enabled by default.
      </p>

      <SortableTable
        rows={mainTableRows}
        tableClassName="email-manager-table"
        columnHeaders={[
          {
            label: "Email Name",
            key: "emailName",
            className: "email-name-column-header",
            disableSort: true,
          },
          {
            label: "Description",
            key: "description",
            className: "description-column-header",
            disableSort: true,
          },
          {
            label: "By Default",
            key: "byDefaultColumn",
            disableSort: true,
            className: "toggle-column-header",
            infoTooltip:
              "When toggled on, this email will be enabled by default. When toggled off, this email will disabled by default. You can still enable or disable emails within each individual flow when you’re inviting users to your organization, adding users to a team, launching a TEAMscan, etc.",
          },
        ]}
        hidePagination
        itemsPerPage={Infinity}
      />
      <div>
        <Button
          variant="secondary-blue"
          className="border-0"
          onClick={() =>
            setShowAutomatedEmailSettings(!showAutomatedEmailSettings)
          }
        >
          Automated Email Settings{" "}
          <FontAwesomeIcon
            icon={`caret-${showAutomatedEmailSettings ? "up" : "down"}`}
            className="ms-1"
          />
        </Button>
      </div>
      <Collapse in={showAutomatedEmailSettings}>
        <div>
          <SortableTable
            rows={automatedEmailsRows}
            tableClassName="email-manager-table"
            columnHeaders={[
              {
                label: "Email Name",
                key: "emailName",
                className: "email-name-column-header",
                disableSort: true,
              },
              {
                label: "Description",
                key: "description",
                className: "description-column-header",
                disableSort: true,
              },
              {
                label: "Enable/Disable",
                key: "enabledOrDisabledColumn",
                disableSort: true,
                className: "toggle-column-header",
                infoTooltip:
                  "When toggled on, this email will be automatically sent when triggered. When toggled off, this email will never be sent.",
              },
            ]}
            hidePagination
            itemsPerPage={Infinity}
          />
        </div>
      </Collapse>
    </>
  );
};

export default EmailManager;
