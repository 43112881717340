import Checkbox from "app/components/Checklists/Checkbox";
import SimpleModalFooter from "app/components/Modals/SimpleModalFooter";
import { selectTeamsByTeamId } from "app/containers/Global/slice";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useAppSelector } from "utils/redux/hooks";
import { SendEmailOptions } from "../types";
import { getMustSelectUserRoleErrorBanner } from "../helpers";

interface Props {
  onBack: () => void;
  onSave: (
    selectedTeamIds: number[],
    selectedOptionValues?: { [type: string]: 0 | 1 | boolean }
  ) => void;
  preselectedUserIds?: number[];
  sendEmailOptions: SendEmailOptions;
  getWarningBanner?: (sendToType: string) => JSX.Element | null;
}

const TeamEmailConfig = ({
  onBack,
  onSave,
  preselectedUserIds,
  sendEmailOptions,
  getWarningBanner,
}: Props) => {
  // -------------------- Selectors ----------------
  const teamsById = useAppSelector(selectTeamsByTeamId);
  // -------------------- State --------------------
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [areTeamLeadersSelected, setAreTeamLeadersSelected] = useState(false);
  const [areTeamMembersSelected, setAreTeamMembersSelected] = useState(false);
  const [showMustSelectCheckboxError, setShowMustSelectCheckboxError] =
    useState(false);

  // -------------------- Effects --------------------
  useEffect(() => {
    setAreTeamLeadersSelected(!!sendEmailOptions.teamLeaders);
    setAreTeamMembersSelected(!!sendEmailOptions.teamMembers);
  }, [sendEmailOptions]);

  useEffect(() => {
    if (preselectedUserIds) {
      setSelectedIds(preselectedUserIds);
    }
  }, [preselectedUserIds]);

  const onCheckboxChangeSideEffect = () => {
    setShowMustSelectCheckboxError(false);
  };

  return (
    <>
      <Select
        noOptionsMessage={() => null}
        placeholder="Search by team name"
        options={Object.values(teamsById)
          .filter((team) => !team?.isSampleTeam)
          .map((team) => ({
            label: team.teamName ?? "",
            value: `${team.teamId}`,
          }))}
        isMulti
        value={selectedIds.map((id) => ({
          label: teamsById[id]?.teamName,
          value: String(teamsById[id]?.teamId),
        }))}
        onChange={(values) => {
          if (values) {
            setSelectedIds(values.map(({ value }) => Number(value)));
          }
        }}
        classNames={{
          multiValue: () => "select__multi-value",
        }}
      />

      <div className="column-gap-8px">
        <p className="navy-text">
          On teams selected above, send this email to:
        </p>
        <Checkbox
          id="teamLeaders"
          label="Team Leaders"
          onChange={() => {
            onCheckboxChangeSideEffect();
            setAreTeamLeadersSelected(!areTeamLeadersSelected);
          }}
          value={areTeamLeadersSelected}
        />
        <Checkbox
          id="teamMembers"
          label="Team Members"
          onChange={() => {
            onCheckboxChangeSideEffect();
            setAreTeamMembersSelected(!areTeamMembersSelected);
          }}
          value={areTeamMembersSelected}
        />
      </div>
      {selectedIds?.length && getWarningBanner
        ? getWarningBanner("teams")
        : null}
      {showMustSelectCheckboxError && getMustSelectUserRoleErrorBanner()}
      <SimpleModalFooter
        onBackButtonClick={onBack}
        onPrimaryButtonClick={() => {
          if (!areTeamLeadersSelected && !areTeamMembersSelected) {
            return setShowMustSelectCheckboxError(true);
          }

          onSave(selectedIds, {
            teamLeaders: areTeamLeadersSelected,
            teamMembers: areTeamMembersSelected,
          });
        }}
        isPrimaryButtonDisabled={
          !selectedIds.length || showMustSelectCheckboxError
        }
        primaryButtonText="Next"
      />
    </>
  );
};

export default TeamEmailConfig;
