import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import {
  EmailManagementSettings,
  SendEmailPayloadOptions,
  EmailManagementValidEmailType,
} from "./types";
import { successNotificationOptions } from "utils/constants";

// ------------------ State Type/Structure ------------------
export interface EmailManagerState {
  getEmailMangerSettings: responseStatus;
  updatingEmailManagerSettings: responseStatus;
  emailManagementSettings: EmailManagementSettings | null;
  sendPreviewEmailStatus: responseStatus;
  sendPendingEmailsStatus: responseStatus;
  validUserListForEmails: Record<string, Record<number, true | undefined>>;
  getValidUserListStatus: responseStatus;
}

// ------------------ InitialState ------------------
const initialState: EmailManagerState = {
  getEmailMangerSettings: "idle",
  updatingEmailManagerSettings: "idle",
  emailManagementSettings: null,
  sendPreviewEmailStatus: "idle",
  sendPendingEmailsStatus: "idle",
  validUserListForEmails: {},
  getValidUserListStatus: "idle",
};

// ------------------ Asynchronous API calls ------------------
//------------------------------------- GET -------------------------------------
export const getEmailManagerSettings = createAsyncThunk(
  "emailManager/getEmailManagerSettings",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/emailManagementSettings`;
    return (await request(requestUrl)) as EmailManagementSettings;
  },
  {
    condition: (
      { refresh }: { refresh: boolean } = {
        refresh: false,
      },
      { getState }
    ) => {
      const {
        emailManager: { getEmailMangerSettings },
        global: { currentUserAccountId },
      } = getState() as RootState;
      if (!currentUserAccountId) return false;

      return (
        refresh ||
        getEmailMangerSettings === "idle" ||
        getEmailMangerSettings === "failed"
      );
    },
  }
);

export const getValidUserList = createAsyncThunk(
  "emailManager/getValidUserList",
  async (emailType: EmailManagementValidEmailType) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/emails/${emailType}/validUserList`;
    const response = (await request(requestUrl)) as number[];
    const mappedResponse: Record<number, true | undefined> = {};
    response.forEach((id) => {
      mappedResponse[id] = true;
    });
    return {
      emailType,
      validUserMap: mappedResponse,
    };
  }
);

// ------------------------------------- POST -------------------------------------
export const sendPendingEmails = createAsyncThunk(
  "emailManager/sendPendingEmails",
  async ({
    emailType,
    options,
    ids,
    sendToType,
  }: {
    emailType: EmailManagementValidEmailType;
    options: SendEmailPayloadOptions;
    ids: number[];
    sendToType: "users" | "teams" | "departments" | "organization";
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/sendPendingEmails`;
    const response = (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({
        emailType,
        options,
        ids,
        sendToType,
      }),
    })) as {
      scheduledCount: number;
    };
    return response.scheduledCount ?? 0;
  }
);

export const sendPreviewEmail = createAsyncThunk(
  "emailManager/sendPreviewEmail",
  async ({
    emailType,
    options,
  }: {
    emailType: string;
    options: SendEmailPayloadOptions & {
      alreadyJoined?: boolean;
    };
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/sendPreviewEmail`;
    return await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({
        emailType,
        options,
      }),
    });
  }
);

// ------------------------------------- PUT -------------------------------------
export const updateEmailManagerSettings = createAsyncThunk(
  "emailManager/updateEmailManagerSettings",
  async ({
    updatedEmailSettings,
  }: {
    updatedEmailSettings: Partial<EmailManagementSettings>;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/emailManagementSettings`;
    return (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({
        ...updatedEmailSettings,
      }),
    })) as EmailManagementSettings;
  }
);

// ------------------------------------- DELETE -------------------------------------

// ------------------ Beginning of Slice Definition ------------------
export const emailManagerSlice = createSlice({
  name: "emailManager",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmailManagerSettings.pending, (state) => {
        state.getEmailMangerSettings = "loading";
      })
      .addCase(
        getEmailManagerSettings.fulfilled,
        (state, action: PayloadAction<EmailManagementSettings>) => {
          state.getEmailMangerSettings = "succeeded";
          state.emailManagementSettings = action.payload;
        }
      )
      .addCase(getEmailManagerSettings.rejected, (state) => {
        state.getEmailMangerSettings = "failed";
      })
      .addCase(updateEmailManagerSettings.pending, (state) => {
        state.updatingEmailManagerSettings = "loading";
      })
      .addCase(
        updateEmailManagerSettings.fulfilled,
        (state, action: PayloadAction<EmailManagementSettings>) => {
          state.updatingEmailManagerSettings = "succeeded";
          toast.success(
            "Settings updated successfully",
            successNotificationOptions
          );
          state.emailManagementSettings = action.payload;
        }
      )
      .addCase(updateEmailManagerSettings.rejected, (state) => {
        state.updatingEmailManagerSettings = "failed";
      })
      .addCase(sendPreviewEmail.pending, (state) => {
        state.sendPreviewEmailStatus = "loading";
      })
      .addCase(sendPreviewEmail.fulfilled, (state) => {
        state.sendPreviewEmailStatus = "succeeded";
        toast.success(
          "Preview email sent successfully",
          successNotificationOptions
        );
      })
      .addCase(sendPreviewEmail.rejected, (state) => {
        state.sendPreviewEmailStatus = "failed";
      })
      .addCase(sendPendingEmails.pending, (state) => {
        state.sendPendingEmailsStatus = "loading";
      })
      .addCase(
        sendPendingEmails.fulfilled,
        (state, { payload }: PayloadAction<number>) => {
          state.sendPendingEmailsStatus = "succeeded";
          if (payload) {
            toast.success(
              "Emails sent successfully",
              successNotificationOptions
            );
          }
        }
      )
      .addCase(sendPendingEmails.rejected, (state) => {
        state.sendPendingEmailsStatus = "failed";
      })
      .addCase(getValidUserList.pending, (state) => {
        state.getValidUserListStatus = "loading";
      })
      .addCase(
        getValidUserList.fulfilled,
        (
          state,
          action: PayloadAction<{
            emailType: string;
            validUserMap: Record<number, true | undefined>;
          }>
        ) => {
          state.getValidUserListStatus = "succeeded";
          state.validUserListForEmails[action.payload.emailType] =
            action.payload.validUserMap;
        }
      )
      .addCase(getValidUserList.rejected, (state) => {
        state.getValidUserListStatus = "failed";
      });
  },
});

// ------------------ Selectors ------------------
export const selectEmailManagerSettings = (state: RootState) =>
  state.emailManager.emailManagementSettings;
export const selectGetEmailManagerSettingsStatus = (state: RootState) =>
  state.emailManager.getEmailMangerSettings;
export const selectUpdatingEmailManagerSettingsStatus = (state: RootState) =>
  state.emailManager.updatingEmailManagerSettings;
export const selectSendPreviewEmailStatus = (state: RootState) =>
  state.emailManager.sendPreviewEmailStatus;
export const selectSendPendingEmailsStatus = (state: RootState) =>
  state.emailManager.sendPendingEmailsStatus;
export const selectValidUserListForEmails = (state: RootState) =>
  state.emailManager.validUserListForEmails;
export const selectGetValidUserListStatus = (state: RootState) =>
  state.emailManager.getValidUserListStatus;

export default emailManagerSlice.reducer;
