import { useState, ReactNode, useEffect } from "react";
import { Card, Nav } from "react-bootstrap";
import UploadCSVForm from "app/storybookComponents/Forms/UploadTeamCSVForm";
import Button from "app/storybookComponents/Button";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { UserInfo } from "app/containers/Global/types";
import AvatarCircle from "app/components/AvatarCircle";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isValidEmail } from "utils/helperFunctions";
import { PLATFORM_NAME } from "utils/constants";
import Toggle from "app/components/Toggle";

interface Props {
  onInviteViaEmail: (emails: string[]) => void;
  allowedDomains?: string[] | "ALL";
  hiddenUserAccounts?: number[];
  inviteLink?: string;
  isLoading?: boolean;
  onHide?: () => void;
  teamId?: number;
  teamMembers?: UserInfo[];
  totalUsersInvited?: number;
  inviteMemberInfoMessage?: string;
  addMemberInfoMessage?: string;
  openLinkInfoMessage?: string;
  modalDescription?: JSX.Element;
  allUsedEmails?: string[];
  invalidInvitedStrings: string[];
  onCSVUploadSuccess?: () => void;
  isUserAllowedToInviteNewMembers?: boolean;
  hideCsvTab?: boolean;
  hideInviteViaLinkTab?: boolean;
  resetInvalidInvitedStrings?: () => void;
  setSendEmailSettingProps?: {
    value: boolean;
    setter: (value: boolean) => void;
  };
  defaultTab?: "email" | "csv" | "link" | null;
  getAdditionalInviteViaEmailErrors?: (
    selectedValues: { email: string; userAccountId?: number }[]
  ) => JSX.Element | void | null;
  checkEmailForDanger?: (
    email: string,
    userAccountId?: number | string
  ) => boolean;
  entity?: "members" | "team leaders";
}
interface OptionType {
  label: string;
  value: string;
  name: string;
  userAccountId?: number;
}

export default function InviteUserForm({
  inviteLink = "",
  onHide = () => {},
  teamId,
  teamMembers,
  isLoading,
  hiddenUserAccounts,
  inviteMemberInfoMessage,
  addMemberInfoMessage,
  openLinkInfoMessage,
  modalDescription,
  onInviteViaEmail,
  invalidInvitedStrings = [],
  onCSVUploadSuccess,
  isUserAllowedToInviteNewMembers = true,
  hideInviteViaLinkTab,
  hideCsvTab,
  resetInvalidInvitedStrings,
  setSendEmailSettingProps,
  defaultTab,
  getAdditionalInviteViaEmailErrors,
  checkEmailForDanger,
  entity = "members",
}: Readonly<Props>) {
  // This is only temporary until the backend updates the value.
  const [tabShowing, setTabShowing] = useState<"email" | "link" | "csv">(
    "email"
  );
  // --------------------- email tab states ---------------------
  const [inputText, setInputText] = useState<string>("");
  const [selectValues, setSelectValues] = useState<
    readonly {
      label: string;
      value: string;
      name: string;
      className?: string;
      userAccountId?: number;
    }[]
  >([]);

  // --------------------- link tab states ---------------------
  const [copied, setCopied] = useState<boolean>(false);
  const [generalInvalidEmailStrings, setGeneralInvalidEmailStrings] = useState<
    string[]
  >([]);

  // --------------------- useEffect helpers ---------------------
  const getEmailsFromInvalidStrings = (invalidInvitedStrings: string[]) => {
    const emails: string[] = [];
    invalidInvitedStrings.forEach((invalidString) => {
      const match = invalidString.match(/'([^']+)'/);
      if (match) {
        emails.push(match[1]);
      }
    });
    return emails;
  };

  // --------------------- useEffects ---------------------
  // This makes sure that the success banner disappears after 3 seconds.
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (copied) {
      timer = setTimeout(() => {
        setCopied(false);
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [copied]);

  // create a useEffect that will update the selectValues when the invalidInvitedStrings change.
  useEffect(() => {
    const emails = getEmailsFromInvalidStrings(invalidInvitedStrings);
    setSelectValues((previousSelectedValues) =>
      previousSelectedValues.map((selectedVal) => {
        if (
          emails.includes(selectedVal.value) ||
          checkEmailForDanger?.(selectedVal.value, selectedVal.userAccountId)
        ) {
          return { ...selectedVal, className: "danger" };
        }
        return selectedVal;
      })
    );
  }, [invalidInvitedStrings, checkEmailForDanger]);

  useEffect(() => {
    setTabShowing(defaultTab ?? "email");
  }, [defaultTab]);

  // --------------------- email tab functions ---------------------
  const onEmailInvite = () => {
    if (generalInvalidEmailStrings.length) {
      return;
    }

    if (selectValues.length) {
      onInviteViaEmail(selectValues.map(({ value }) => value));
    }
  };

  // This function will update the selectValues and check the emails for validity.
  const onUpdateAndCheckEmails = (
    arr: {
      label: string;
      value: string;
      name: string;
      className?: string;
      userAccountId?: number;
    }[]
  ) => {
    const generalInvalidEmails: Set<string> = new Set<string>();

    // We check the values locally, before sending to the backend.
    const updatedValues = arr.map((selectedVal) => {
      // if valid no need to add the danger class
      if (isValidEmail(selectedVal.value)) {
        return selectedVal;
      }

      generalInvalidEmails.add(
        `Email address "${selectedVal.value}" is invalid.`
      );
      return { ...selectedVal, className: "danger" };
    });
    resetInvalidInvitedStrings?.();
    setGeneralInvalidEmailStrings(Array.from(generalInvalidEmails));
    setSelectValues(updatedValues);
  };

  // --------------------- link tab functions ---------------------
  const onCopyLinkHandler = async () => {
    await navigator.clipboard.writeText(inviteLink || "");
    setCopied(true);
  };

  // --------------------- csv tab functions ---------------------
  const customFilterOption = (
    option: FilterOptionOption<OptionType>,
    inputValue: string
  ) => {
    return (
      option.data.label.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.data.name.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const getWarningBanners = () =>
    [...generalInvalidEmailStrings, ...invalidInvitedStrings]?.map((message) =>
      getWarningBanner(message, message)
    );

  const getWarningBanner = (stringValue: string, key: string) => (
    <div
      key={key}
      className="warning-banner light-red row-gap-12px align-items-center"
    >
      <FontAwesomeIcon icon="triangle-exclamation" />
      <p>{stringValue}</p>
    </div>
  );

  const getUserSelect = (
    memberMap: Map<
      string,
      { avatarCircle: ReactNode; fullName: string; emailAddress: string }
    >,
    options: OptionType[] = []
  ) => {
    const selectProps = {
      noOptionsMessage: () => null,
      components: {
        DropdownIndicator: null,
      },
      inputValue: inputText,
      isClearable: true,
      isMulti: true,
      isSearchable: true,
      options,
      onChange: (newValue: any) => {
        onUpdateAndCheckEmails(newValue);
      },
      onInputChange: (newValue: string) => setInputText(newValue),
      placeholder: "Enter email addresses...",
      value: selectValues,
      styles: {
        menu: (base: any) => ({
          ...base,
          marginTop: 0,
        }),
      },
      classNames: {
        multiValue: (base: any) =>
          `${base?.data?.className || ""} multi-value select-item`,
        input: () => `simple-select-input`,
      },
      formatOptionLabel: (member: any) =>
        getFormatOptionLabel(memberMap, member),
      filterOption: customFilterOption,
    };

    if (isUserAllowedToInviteNewMembers) {
      return (
        <CreatableSelect
          {...selectProps}
          formatCreateLabel={(e) => `Invite "${e}"`}
          onCreateOption={(newValue) => {
            onUpdateAndCheckEmails([
              ...selectValues,
              {
                label: newValue,
                value: newValue,
                className: "create-email",
                name: "",
              },
            ]);
            setInputText("");
          }}
        />
      );
    }
    return <Select {...selectProps} />;
  };

  const getSendEmailSettingBanner = () => {
    if (!setSendEmailSettingProps) {
      return null;
    }
    const inviteToType = entity === "members" ? "team members" : entity;
    const toggleLabel = teamId
      ? `Send invite email to ${inviteToType}?`
      : "Invite user(s) to Develop via email?";
    let message = "";

    if (!setSendEmailSettingProps.value) {
      message =
        "No invitation emails will be sent. You can send invitation emails later from the People tab in the Admin Console.";
    } else if (teamId) {
      message = `An invitation email will be sent to these ${inviteToType} to join this team. If any team member has not joined your organization yet, they will receive an invite to join both this organization and team.`;
    } else {
      message = `An invitation will be sent to these emails to join ${PLATFORM_NAME}. This can be done later as well.`;
    }

    return (
      <div className="warning-banner lighter-blue column-gap-12px border-0">
        <div className="row-gap-12px align-items-center">
          <Toggle
            handleToggle={() => {
              setSendEmailSettingProps.setter(!setSendEmailSettingProps.value);
            }}
            isOn={setSendEmailSettingProps.value}
          />
          <p
            style={{
              color: "black",
            }}
          >
            {toggleLabel}
          </p>
        </div>
        <p>{message}</p>
      </div>
    );
  };

  const getTabContent = () => {
    switch (tabShowing) {
      case "email": {
        const memberMap = new Map<
          string,
          { avatarCircle: ReactNode; fullName: string; emailAddress: string }
        >();

        const options: OptionType[] = [];
        teamMembers?.forEach(
          ({ firstName, lastName, emailAddress, userAccountId }) => {
            if (hiddenUserAccounts?.includes(userAccountId)) {
              return;
            }
            memberMap.set(emailAddress, {
              fullName: `${firstName} ${lastName}`,
              avatarCircle: (
                <AvatarCircle
                  name={`${firstName} ${lastName}`}
                  userAccountId={userAccountId}
                  size="small"
                />
              ),
              emailAddress,
            });
            options.push({
              label: emailAddress,
              value: emailAddress,
              name: `${firstName} ${lastName}`,
              userAccountId: userAccountId,
            });
          }
        );

        const additionalErrors =
          getAdditionalInviteViaEmailErrors?.(
            selectValues.map(({ value, userAccountId }) => ({
              email: value,
              userAccountId: userAccountId,
            }))
          ) ?? null;

        return (
          <>
            {getUserSelect(memberMap, options)}
            {getWarningBanners()}
            {additionalErrors}
            {getSendEmailSettingBanner()}
            <div className="action-buttons">
              <Button
                onClick={onEmailInvite}
                disabled={
                  isLoading ||
                  selectValues.length === 0 ||
                  generalInvalidEmailStrings.length > 0 ||
                  !!additionalErrors
                }
              >
                {setSendEmailSettingProps?.value
                  ? `Add ${entity} and send invite`
                  : `Add ${entity}`}
              </Button>
            </div>
          </>
        );
      }
      case "link":
        return (
          <>
            <Card
              onClick={onCopyLinkHandler}
              role="button"
              aria-disabled={!!inviteLink}
              className={inviteLink ? "invite-link" : "disabled-invite-link"}
            >
              <p className="invite-link">{inviteLink || "Loading..."}</p>
            </Card>
            <div className="action-buttons">
              <div>
                <Button
                  onClick={onCopyLinkHandler}
                  className={copied ? "copied-success" : ""}
                >
                  Copy Invite Link
                </Button>
                {copied ? (
                  <span className="ms-2" style={{ color: "#009952" }}>
                    <FontAwesomeIcon icon="check" className="me-2" />
                    Link Copied To Clipboard
                  </span>
                ) : null}
              </div>
            </div>
          </>
        );
      case "csv":
        return (
          <UploadCSVForm
            onHide={onHide}
            teamId={teamId}
            isLoading={isLoading}
            onCSVUploadSuccess={onCSVUploadSuccess}
          />
        );
    }
  };

  const getFormatOptionLabel = (
    memberMap: Map<
      string,
      { avatarCircle: ReactNode; fullName: string; emailAddress: string }
    >,
    member: {
      label: string;
      value: string;
    }
  ) => {
    const memberInfo = memberMap.get(member.value);
    if (memberInfo) {
      return (
        <div className="member-option">
          {memberInfo.avatarCircle}
          <div className="member-info">
            <span className="member-name">{memberInfo.fullName}</span>
            <span className="member-email">{memberInfo.emailAddress}</span>
          </div>
        </div>
      );
    }

    return (
      <div key={member.value}>
        <span className="simple-text">{member.label}</span>
      </div>
    );
  };

  const getSecondDescriptionText = () => {
    const teamOrOrganization = teamId ? "team" : "organization";
    switch (tabShowing) {
      case "email": {
        if (inviteMemberInfoMessage) {
          if (!setSendEmailSettingProps?.value && addMemberInfoMessage) {
            return <p>{addMemberInfoMessage}</p>;
          }
          return <p>{inviteMemberInfoMessage}</p>;
        }
        return (
          <p>
            Simply type in the email addresses of the people you want to invite.
            They'll receive an invitation to join the {teamOrOrganization}.
          </p>
        );
      }
      case "link": {
        if (openLinkInfoMessage) return <p>{openLinkInfoMessage}</p>;
        return (
          <p>
            Generate a unique link that can be shared with people you want to
            add. They can use this link to join this {teamOrOrganization}.
          </p>
        );
      }
      case "csv":
        return null;
    }
  };
  const getInviteBody = () => {
    const isLinkTabDisabled = !inviteLink || hideInviteViaLinkTab;
    const isCsvTabDisabled = hideCsvTab;
    const navTabs =
      !isLinkTabDisabled || !isCsvTabDisabled ? (
        <Nav
          className="simple-nav"
          activeKey={tabShowing || ""}
          onSelect={(e) => {
            setTabShowing(e as "email" | "link" | "csv");
          }}
        >
          <Nav.Item>
            <Nav.Link eventKey="email">Via Email</Nav.Link>
          </Nav.Item>
          {!isLinkTabDisabled ? (
            <Nav.Item>
              <Nav.Link eventKey="link">Via Link</Nav.Link>
            </Nav.Item>
          ) : null}
          {!isCsvTabDisabled ? (
            <Nav.Item>
              <Nav.Link eventKey="csv">Via CSV</Nav.Link>
            </Nav.Item>
          ) : null}
        </Nav>
      ) : null;

    return (
      <>
        {modalDescription ?? null}
        {navTabs}
        {getSecondDescriptionText()}
        {getTabContent()}
      </>
    );
  };

  return <div className="column-gap-20px">{getInviteBody()}</div>;
}
