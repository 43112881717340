import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WarningBanner from "app/storybookComponents/WarningBanner";
import { UserInfo } from "app/containers/Global/types";
import { CHECKLIST_KEY_TO_LABEL_MAP } from "./constant";

export const getInviteToOrgOptionsWarningBanner = (sendToType: string) => {
  switch (sendToType) {
    case "team":
    case "teams":
      return (
        <div className="warning-banner gold">
          <p>
            This email will only be sent to users on the teams selected above
            who have <b>not logged in yet.</b>
          </p>
        </div>
      );
    case "department":
    case "departments":
      return (
        <div className="warning-banner gold">
          <p>
            This email will only be sent to users in the departments selected
            above who have <b>not logged in yet.</b>
          </p>
        </div>
      );
    case "organization":
      return (
        <div className="warning-banner gold">
          <p>
            This email will only be sent to added users who have{" "}
            <b>not logged in yet.</b>
          </p>
        </div>
      );
    case "users":
    case "user":
    default:
      return null;
  }
};

export const getEppInviteOptionsWarningBanner = (sendToType: string) => {
  switch (sendToType) {
    case "team":
    case "teams":
      return (
        <div className="warning-banner gold">
          <p>
            This email will only be sent to users on teams selected above who
            haven’t completed the EPP or connected past results.
          </p>
        </div>
      );
    case "department":
    case "departments":
      return (
        <div className="warning-banner gold">
          <p>
            This email will only be sent to users in the departments selected
            above who haven’t completed the EPP or connected past results.
          </p>
        </div>
      );
    case "organization":
      return (
        <div className="warning-banner gold">
          <p>
            This email will only be sent to users who haven’t completed the EPP
            or connected past results.
          </p>
        </div>
      );
    case "users":
    case "user":
    default:
      return null;
  }
};

export const getTeamScanInviteOptionsWarningBanner = (sendToType: string) => {
  switch (sendToType) {
    case "team":
    case "teams":
      return (
        <div className="warning-banner gold">
          <p>
            This email will only be sent to users on team(s) selected above with
            an active TEAMscan survey that still needs to be completed.
          </p>
        </div>
      );
    case "department":
    case "departments":
      return (
        <div className="warning-banner gold">
          <p>
            This email will only be sent to users in the department(s) selected
            above with an active TEAMscan survey that still needs to be
            completed.
          </p>
        </div>
      );
    case "organization":
      return (
        <div className="warning-banner gold">
          <p>
            This email will only be sent to users with an active TEAMscan survey
            that still needs to be completed.
          </p>
        </div>
      );
    case "users":
    case "user":
    default:
      return null;
  }
};

export const getTeamInviteOptionsWarningBanner = (sendToType: string) => {
  switch (sendToType) {
    case "team":
    case "teams":
      return (
        <div className="warning-banner gold">
          <p>
            This email will only be sent to users on team(s) selected above with
            one or more pending invitations to join a team.
          </p>
        </div>
      );
    case "department":
    case "departments":
      return (
        <div className="warning-banner gold">
          <p>
            This email will only be sent to users in the department(s) selected
            above with one or more pending invitations to join a team.
          </p>
        </div>
      );
    case "organization":
      return (
        <div className="warning-banner gold">
          <p>
            This email will only be sent to users with one or more pending
            invitations to join a team.
          </p>
        </div>
      );
    case "users":
    case "user":
    default:
      return null;
  }
};

export const getMustSelectUserRoleErrorBanner = () => (
  <WarningBanner type="light-red">
    <div className="row-gap-12px align-items-center">
      <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />
      <p>You must select at least one user role to send this email to.</p>
    </div>
  </WarningBanner>
);

export const checkIfUserHasBeenInvited = (
  userAccountId: number | string,
  usersById: Record<number | string, UserInfo>
) => {
  const userAccountIdNumber = Number(userAccountId);
  const userAccount = usersById[userAccountIdNumber];
  return !!userAccount?.firstTeamsLogin;
};

export const checkIfUserShouldNotReceiveEmail = (
  userAccountId: number | string,
  validUsersMap?: Record<number, true | undefined>
) => {
  const userAccountIdNumber = Number(userAccountId);
  return !validUsersMap?.[userAccountIdNumber];
};

export const getChecklistText = (
  checklistObj: Record<string, boolean | 0 | 1>
) => {
  const allCheckListItemsChecked = Object.values(checklistObj).every(
    (value) => value
  );
  if (allCheckListItemsChecked) {
    return "Everyone";
  }
  let text = "";
  const arr: string[] = [];
  const keyArr = [
    "admin",
    "teamLeaders",
    "teamMembers",
    "departmentLeaders",
    "managers",
    "nonTeamMembers",
  ];
  keyArr.forEach((key) => {
    if (checklistObj[key]) {
      arr.push(CHECKLIST_KEY_TO_LABEL_MAP[key]);
    }
  });
  arr.forEach((item, index) => {
    if (index === 0) {
      text += `Only ${item}`;
    } else if (index === arr.length - 1) {
      text += ` and ${item}`;
    } else {
      text += `, ${item}`;
    }
  });

  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const titleAndDescriptionForPreviewEmailModal = (
  emailType?: string
): {
  title: string;
  titleDescriptionText?: string;
} => {
  switch (emailType) {
    case "inviteToOrganization":
      return {
        title: "Preview Organization Invite Email",
        titleDescriptionText:
          "Send a test email to yourself to preview the organization invite email.",
      };
    case "TEAMScanInvite":
      return {
        title: "Preview TEAMscan Invite Email",
        titleDescriptionText:
          "Send a test email to yourself to preview the TEAMscan invite email.",
      };
    case "teamInvite":
      return {
        title: "Preview Team Invite Email",
        titleDescriptionText:
          "Send a test email to yourself to preview the team invite email.",
      };
    case "eppInvite":
      return {
        title: "Preview EPP Invite Email",
        titleDescriptionText:
          "Send a test email to yourself to preview the EPP invite email.",
      };
    default:
      return {
        title: "Preview Email",
        titleDescriptionText:
          "Send a test email to yourself to preview the email.",
      };
  }
};
