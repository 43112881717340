import { useEffect, useState } from "react";
import SimpleModal from "app/components/Modals/SimpleModal";
import { Dropdown } from "react-bootstrap";
import SendEmailConfirmation from "./SendEmailConfirmation";
import OrganizationEmailConfig from "./OrganizationEmailConfig";
import TeamEmailConfig from "./TeamEmailConfig";
import UserEmailConfig from "./UserEmailConfig";
import DepartmentEmailConfig from "./DepartmentEmailConfig";
import {
  SendEmailOptions,
  SendEmailPayloadOptions,
  SendToType,
} from "../types";

interface Props {
  isOpen: boolean;
  title: string;
  titleDescription: string;
  closeModal: () => void;
  userConfig?: {
    checkIfUserHasError: (userAccountId: number | string) => boolean;
    userErrorMessage: string;
  };
  onSave?: (
    type: SendToType,
    ids: number[],
    options: SendEmailPayloadOptions
  ) => Promise<void> | void;
  isLoading?: boolean;
  getWarningBanner?: (sendToType: string) => JSX.Element | null;
  confirmationDescriptionText?: string;
  sendToTypeDefault?: SendToType;
}

const SendEmailModal = ({
  title,
  isOpen,
  closeModal,
  titleDescription,
  onSave,
  userConfig,
  isLoading,
  getWarningBanner,
  confirmationDescriptionText,
  sendToTypeDefault,
}: Props) => {
  const [sendToType, setSendToType] = useState("teams");
  const [showConfirmationScreen, setShowConfirmationScreen] = useState(false);
  const [sendEmailOptions, setSendEmailOptions] = useState<SendEmailOptions>(
    {}
  );
  const [ids, setIds] = useState<number[]>([]);

  useEffect(() => {
    setSendToType(sendToTypeDefault ?? "teams");
  }, [sendToTypeDefault]);

  const onDropdownSelect = (e: string | null) => {
    setSendToType(e ?? "teams");
    setIds([]);
  };

  const closeModalWrapper = () => {
    setSendToType(sendToTypeDefault ?? "teams");
    setIds([]);
    closeModal();
    setShowConfirmationScreen(false);
    setSendEmailOptions({});
  };

  const getTypeDropdownSelected = () => {
    if (sendToType === "organization") {
      return "Entire Organization";
    }

    const typeUpper = sendToType.charAt(0).toUpperCase() + sendToType.slice(1);
    return `Select ${typeUpper}`;
  };

  const getTypeDropdown = () => {
    return (
      <Dropdown className="full-width-dropdown" onSelect={onDropdownSelect}>
        <Dropdown.Toggle variant="light" id="dropdown-basic">
          {getTypeDropdownSelected()}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item eventKey="users">Users</Dropdown.Item>
          <Dropdown.Item eventKey="teams">Teams</Dropdown.Item>
          <Dropdown.Item eventKey="departments">Departments</Dropdown.Item>
          <Dropdown.Item eventKey="organization">Organization</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const onSaveEmailConfig = (
    selectedIds: number[],
    selectedOptionValues?: { [type: string]: 0 | 1 | boolean }
  ) => {
    setIds(selectedIds);
    setShowConfirmationScreen(true);
    setSendEmailOptions(selectedOptionValues ?? {});
  };

  const onBackClick = () => {
    if (showConfirmationScreen) {
      return setShowConfirmationScreen(false);
    }
    closeModalWrapper();
  };

  const getAdditionalSettings = () => {
    switch (sendToType) {
      case "organization":
        return (
          <OrganizationEmailConfig
            onBack={onBackClick}
            onSave={(selectedOptions) => onSaveEmailConfig([], selectedOptions)}
            sendEmailOptions={sendEmailOptions}
            getWarningBanner={getWarningBanner}
          />
        );
      case "teams":
        return (
          <TeamEmailConfig
            onBack={onBackClick}
            onSave={onSaveEmailConfig}
            preselectedUserIds={ids}
            sendEmailOptions={sendEmailOptions}
            getWarningBanner={getWarningBanner}
          />
        );
      case "users":
        return (
          <UserEmailConfig
            onBack={onBackClick}
            onSave={onSaveEmailConfig}
            preselectedUserIds={ids}
            checkIfUserHasError={userConfig?.checkIfUserHasError}
            errorMessage={userConfig?.userErrorMessage}
          />
        );
      case "departments":
        return (
          <DepartmentEmailConfig
            onBack={onBackClick}
            onSave={onSaveEmailConfig}
            preselectedUserIds={ids}
            sendEmailOptions={sendEmailOptions}
            getWarningBanner={getWarningBanner}
          />
        );
      default:
        return null;
    }
  };

  const getModalContent = () => {
    if (showConfirmationScreen) {
      return (
        <SendEmailConfirmation
          sendToType={sendToType as SendToType}
          ids={ids}
          onSave={async () => {
            await onSave?.(sendToType as SendToType, ids, sendEmailOptions);
            closeModalWrapper();
          }}
          onBack={onBackClick}
          sendEmailOptions={sendEmailOptions}
          isLoading={isLoading}
          getWarningBanner={getWarningBanner}
          confirmationDescriptionText={confirmationDescriptionText}
        />
      );
    }

    return (
      <>
        {getTypeDropdown()}
        {getAdditionalSettings()}
      </>
    );
  };

  return (
    <SimpleModal show={isOpen} onHide={closeModalWrapper} title={title}>
      {showConfirmationScreen ? null : (
        <div>
          <p>{titleDescription}</p>
        </div>
      )}
      {getModalContent()}
    </SimpleModal>
  );
};

export default SendEmailModal;
