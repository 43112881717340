import { Form } from "react-bootstrap";

interface Props {
  controlId: string;
  inputText: string;
  onTextChange: (text: string) => void;
  maxLength?: number;
  inputLabel?: string;
  placeholder?: string;
  hideLength?: boolean;
  disabled?: boolean;
  className?: string;
  onUnFocus?: () => void;
  type?: HTMLInputElement["type"];
  onFocus?: () => void;
}

// This component will be used to render a text input and we will have the character limit next to it
export default function TextInput({
  maxLength,
  inputText,
  inputLabel,
  onTextChange,
  placeholder,
  controlId,
  hideLength,
  disabled,
  className = "",
  onUnFocus,
  type,
  onFocus,
}: Readonly<Props>) {
  return (
    <Form.Group
      className={`form-group text-input ${className}`}
      controlId={controlId}
    >
      {inputLabel ? (
        <Form.Label className="textarea-label">{inputLabel}</Form.Label>
      ) : null}
      <Form.Control
        placeholder={placeholder}
        onChange={(e) => {
          if (maxLength) {
            onTextChange(e.target.value.slice(0, maxLength));
          } else {
            onTextChange(e.target.value);
          }
        }}
        value={inputText}
        disabled={disabled}
        required
        className={className}
        onBlur={onUnFocus}
        type={type}
        onFocus={onFocus}
      />
      {!hideLength && maxLength ? (
        <Form.Text id={`${controlId}Limit`} className="max-length" muted>
          {inputText.length}/{maxLength} Characters
        </Form.Text>
      ) : null}
    </Form.Group>
  );
}
