import Checkbox from "app/components/Checklists/Checkbox";

interface Props {
  setter: (key: string, value: boolean) => void;
  valueMap: Record<string, boolean>;
}

const PreviewInviteToOrgChecklistOptions = ({ setter, valueMap }: Props) => (
  <>
    <p className="navy-text">
      Users can have one or more roles. Send a test email to yourself based on a
      user who has been invited to be a:
    </p>
    <div className="column-gap-8px">
      <Checkbox
        id="admin"
        label="Admin"
        onChange={() => {
          setter("admin", !valueMap.admin);
        }}
        value={!!valueMap.admin}
      />
      <Checkbox
        id="departmentLeaders"
        label="Department Leader"
        onChange={() => {
          // setAreDepartmentLeadersSelected(!areDepartmentLeadersSelected);
          setter("departmentLeaders", !valueMap.departmentLeaders);
        }}
        value={!!valueMap.departmentLeaders}
      />
      <Checkbox
        id="teamLeaders"
        label="Team Leader"
        onChange={() => {
          setter("teamLeaders", !valueMap.teamLeaders);
        }}
        value={!!valueMap.teamLeaders}
      />
      <Checkbox
        id="managers"
        label="Manager"
        onChange={() => {
          setter("managers", !valueMap.managers);
        }}
        value={!!valueMap.managers}
      />
      <Checkbox
        id="teamMembers"
        label="Team Member"
        onChange={() => {
          setter("teamMembers", !valueMap.teamMembers);
        }}
        value={!!valueMap.teamMembers}
      />
    </div>
  </>
);

export default PreviewInviteToOrgChecklistOptions;
