import Button from "app/storybookComponents/Button";

interface SimpleModalFooterProps {
  onBackButtonClick?: () => void;
  onPrimaryButtonClick: () => void;
  backButtonText?: string;
  isBackButtonDisabled?: boolean;
  primaryButtonText?: string;
  isPrimaryButtonDisabled?: boolean;
}

const SimpleModalFooter = ({
  onBackButtonClick,
  backButtonText = "Back",
  onPrimaryButtonClick,
  isBackButtonDisabled = false,
  primaryButtonText = "Save",
  isPrimaryButtonDisabled = false,
}: SimpleModalFooterProps) => (
  <div className="simple-modal-footer-buttons">
    {onBackButtonClick ? (
      <Button
        variant="secondary-gray"
        className="border-0"
        onClick={onBackButtonClick}
        disabled={isBackButtonDisabled}
      >
        {backButtonText}
      </Button>
    ) : (
      <div />
    )}
    <Button onClick={onPrimaryButtonClick} disabled={isPrimaryButtonDisabled}>
      {primaryButtonText}
    </Button>
  </div>
);

export default SimpleModalFooter;
