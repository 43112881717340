import { IS_AU_INSTANCE } from "./environmentVariables";

export const getUserIdendifier = (userAccountId: number | string) =>
  `${userAccountId}${IS_AU_INSTANCE ? "au" : ""}`;

export const segmentIdentifyUser = (
  userAccountId: number,
  loginType: string = "password"
) => {
  // we need the internal property of the token to determine if the user logged in from the intranet
  window.analytics?.identify(getUserIdendifier(userAccountId), {
    timeStamp: new Date(),
    type: loginType,
  });
};

export const trackEvent = (
  eventName: string,
  data:
    | {
        [key: string]: string | number | boolean | null | undefined;
      }
    | undefined = {}
) => {
  window.analytics?.track(`Develop By Criteria - ${eventName}`, {
    isPostHire: true,
    eventName,
    ...data,
  });
};

export const trackPageVisit = (pathName: string) => {
  window.analytics?.page("Develop By Criteria - Page View", {
    isPostHire: true,
    url: window.location.href,
    path: pathName,
  });
};

export const trackDirectReportModalOpened = () => {
  trackEvent("New Direct Report Modal Opened");
};

export const trackUserGuideTabChange = (tabName: string, isSelf: boolean) => {
  trackEvent("User Guide Tab Change", { tabName, isSelf });
};

export const trackTeamGuideTabChange = (
  tabName: string,
  isTeamLeader: boolean
) => {
  trackEvent("Team Guide Tab Change", { tabName, isTeamLeader });
};

export const trackDepartmentGuideTabChange = (
  tabName: string,
  isDepartmentLeader: boolean
) => {
  trackEvent("Department Guide Tab Change", { tabName, isDepartmentLeader });
};

export const trackUserDownloadedAttachment = (
  attachmentType: "Cover Letter" | "Resume"
) => {
  trackEvent("User Job Profile Attachment Downloaded", { attachmentType });
};

export const trackUserViewedEmailManager = () => {
  trackEvent("User Viewed Email Manager");
};
