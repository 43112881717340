import { Modal } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import { useState, ReactElement } from "react";
import {
  getTestTakerMatches,
  linkTestTaker,
  selectGetTestTakerMatchesStatus,
  selectLinkTestTakerStatus,
  selectTestTakerMatches,
  resetTestTakerMatches,
} from "./slice";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import { selectCurrentUserAccountId } from "app/containers/Global/slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LinkResultsIcon from "resources/images/link-results-connect.png";
import {
  dismissNotification,
  selectConnectedYourPersonalityReportNotification,
} from "app/components/Notifications/slice";

interface Props {
  show?: boolean;
  onHide: () => void;
}

export const LinkResultsModal = ({ show, onHide }: Props) => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const userAccountId = useAppSelector(selectCurrentUserAccountId);
  const getTestTakerMatchesStatus = useAppSelector(
    selectGetTestTakerMatchesStatus
  );
  const testTakerMatches = useAppSelector(selectTestTakerMatches);
  const linkTestTakerStatus = useAppSelector(selectLinkTestTakerStatus);
  const connectYourPersonalityReportNotification = useAppSelector(
    selectConnectedYourPersonalityReportNotification
  );

  const getTitle = () => {
    if (linkTestTakerStatus === "succeeded") {
      return "Personality Results Linked";
    }

    return "Link Personality Results";
  };

  const getContent = () => {
    if (linkTestTakerStatus === "succeeded") {
      return getPersonalityResultsLinkedContent();
    }

    if (testTakerMatches && testTakerMatches.length > 0) {
      return getTestTakersOptionsContent();
    }

    return getEnterEmailContent();
  };

  const getWarningBanner = (message: string) => {
    return (
      <div
        className="warning-banner red d-flex row-gap-12px"
        style={{ border: "none" }}
      >
        <FontAwesomeIcon icon="triangle-exclamation" />
        <p>{message}</p>
      </div>
    );
  };

  const getEnterEmailContent = () => {
    return (
      <>
        <div>
          <label htmlFor="email-input" className="enter-email">
            Enter the email you used to complete the Employee Personality
            Profile (EPP):
          </label>
          <input
            id="email-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="enter-email-input"
          />
        </div>
        {testTakerMatches &&
          testTakerMatches.length < 1 &&
          getWarningBanner(
            "No candidate profiles found associated with this email. Please try a different email."
          )}
        {getTestTakerMatchesStatus === "failed" &&
          getWarningBanner(
            "An error occurred while searching for candidate profiles. Please try again."
          )}
        <div className="link-personality-button-row">
          <Button
            onClick={handleNext}
            disabled={!email || getTestTakerMatchesStatus === "loading"}
          >
            Next
          </Button>
        </div>
      </>
    );
  };

  const getTestTakersOptionsContent = () => {
    const testTakers: ReactElement[] = [];
    testTakerMatches?.forEach((testTaker) => {
      const appliedDate = new Date(testTaker.dateEventTaken);
      const appliedDateReadable = appliedDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      testTakers.push(
        <div className="candidate-option" key={testTaker.testTakerId}>
          <div className="candidate-name-and-badge">
            <strong>
              {testTaker.testTakerFirstName} {testTaker.testTakerLastName}
            </strong>
            <div className="personality-badge">
              <p>Personality </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.03712 0.0596591C8.07642 0.0198864 8.12882 0 8.19432 0C8.25982 0 8.31878 0.0198864 8.37118 0.0596591L8.9214 0.636364C8.9738 0.676136 9 0.729167 9 0.795455C9 0.861742 8.9738 0.921401 8.9214 0.974432L3.0262 6.94034C2.9869 6.98011 2.9345 7 2.869 7C2.80349 7 2.74454 6.98011 2.69214 6.94034L0.0786026 4.27557C0.0262008 4.2358 0 4.18277 0 4.11648C0 4.05019 0.0262008 3.99053 0.0786026 3.9375L0.628821 3.38068C0.681223 3.32765 0.740175 3.30114 0.805677 3.30114C0.871179 3.30114 0.923581 3.32765 0.962882 3.38068L2.869 5.30966L8.03712 0.0596591Z"
                  fill="#166669"
                />
              </svg>
            </div>
          </div>
          <div className="candidate-email-row">
            <div className="candidate-email">{testTaker.testTakerEmail} ·</div>{" "}
            {testTaker.position}
          </div>
          <div className="date-row">
            <div>Applied {appliedDateReadable}</div>
            <Button
              onClick={async () => {
                if (!userAccountId) return;
                await dispatch(
                  linkTestTaker({
                    userAccountId,
                    testTakerId: testTaker.testTakerId,
                  })
                );

                if (connectYourPersonalityReportNotification) {
                  dispatch(
                    dismissNotification(
                      connectYourPersonalityReportNotification.notificationId
                    )
                  );
                }
              }}
              variant="secondary-blue"
              disabled={linkTestTakerStatus === "loading"}
            >
              Link
            </Button>
          </div>
        </div>
      );
    });

    const length = testTakerMatches?.length || 0;
    const text = length === 1 ? "candidate profile" : "candidate profiles";

    const handleBack = () => {
      dispatch(resetTestTakerMatches());
      setEmail("");
    };

    return (
      <div>
        <p>
          {length} {text} found:
        </p>
        {testTakers}
        <Button
          variant="secondary-blue"
          onClick={handleBack}
          style={{ marginTop: "16px" }}
        >
          Back
        </Button>
        {linkTestTakerStatus === "failed" &&
          getWarningBanner(
            "An error occurred while linking the candidate profile. Please try again."
          )}
      </div>
    );
  };

  const getPersonalityResultsLinkedContent = () => {
    return (
      <div className="results-linked-div">
        <img
          src={LinkResultsIcon}
          alt="link results"
          height="120px"
          width="120px"
        />
        <h3>Your Personality Profile Has Been Successfully Linked</h3>
        <p>
          Check out your Workplace Insights to learn about your workplace
          superpower, hidden strength, and more.
        </p>
        <Button variant="secondary-blue" onClick={onHide}>
          Close
        </Button>
      </div>
    );
  };

  const handleNext = () => {
    dispatch(
      getTestTakerMatches({
        searchString: email,
        exactMatch: "true",
      })
    );
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" className="simple-modal">
      <div className="modal-title-row">
        <div className="column-gap-12px">
          <h2>{getTitle()}</h2>
        </div>
        <div>
          <Button
            onClick={() => onHide()}
            variant={"secondary-blue"}
            style={{ border: "none", width: "auto" }}
            xIcon
          />
        </div>
      </div>
      {getContent()}
    </Modal>
  );
};
