import { Card } from "react-bootstrap";
import NoResultsIcon from "resources/images/no-results.jpg";

interface Props {
  name: string;
  type?: "user" | "team" | "company";
}
export default function NoGuideYet({ type = "user", name }: Props) {
  const getNoGuideText = () => {
    switch (type) {
      case "team":
        return "Content added by this team’s leader(s) will be shown here";
      case "company":
        return "Content added by your organization admins will be shown here";
      case "user":
      default:
        return "Content added by this user will be shown here";
    }
  };
  return (
    <Card className="not-available-card">
      <img src={NoResultsIcon} alt="no results" height="80px" width="80px" />
      <span>
        {name} has not completed their {type} guide yet
      </span>
      <p>{getNoGuideText()}</p>
    </Card>
  );
}
