import { Modal } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import { Team, UserInfo } from "app/containers/Global/types";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectInviteLinkByTeamId,
  inviteUserByEmail,
  selectInviteUserByEmailStatus,
  selectInvalidInvitedStrings,
  resetInvalidInvitedStrings,
} from "app/containers/Global/slice";
import InviteUserForm from "app/storybookComponents/Forms/InviteUserForm";
import { selectCompanySettings } from "app/containers/AdminConsole/slice";
import { getAllowedDomains } from "app/containers/AdminConsole/helpers";
import { INVITE_PEOPLE_TEXT } from "app/components/Modals/constants";
import { selectUserIsAbleToInviteUsers } from "app/containers/UserGuide/slice";
import { useEffect, useState } from "react";
import { selectHideInviteViaLinkOption } from "app/containers/Login/slice";
import {
  getEmailManagerSettings,
  selectEmailManagerSettings,
} from "app/components/EmailManager/slice";

interface Props {
  show?: boolean;
  onHide: () => void;
  inviteCount?: number;
  teamId: number;
  currentTeamInfo?: Team;
  users: UserInfo[];
}

export default function InviteMemberModal({
  show,
  onHide,
  inviteCount,
  teamId,
  currentTeamInfo,
  users,
}: Props) {
  const dispatch = useAppDispatch();
  const teamLinksById = useAppSelector(selectInviteLinkByTeamId);
  const companySettings = useAppSelector(selectCompanySettings);
  const inviteUsersByEmailStatus = useAppSelector(
    selectInviteUserByEmailStatus
  );
  const invalidInvitedStrings = useAppSelector(selectInvalidInvitedStrings);
  const canUserInviteNewMembers = useAppSelector(selectUserIsAbleToInviteUsers);
  const hideInviteViaLinkOption = useAppSelector(selectHideInviteViaLinkOption);
  const emailManagementSettings = useAppSelector(selectEmailManagerSettings);

  const [sendEmails, setSendEmails] = useState(
    !!emailManagementSettings?.inviteToOrganizationIsEnabled
  );

  useEffect(() => {
    setSendEmails(
      teamId
        ? !!emailManagementSettings?.teamInviteIsEnabled
        : !!emailManagementSettings?.inviteToOrganizationIsEnabled
    );
  }, [emailManagementSettings, teamId]);

  useEffect(() => {
    dispatch(getEmailManagerSettings());
  }, [dispatch]);

  const hideModal = () => {
    onHide();
    dispatch(resetInvalidInvitedStrings());
  };

  const getHiddenUserAccounts = () => {
    const hiddenUserAccounts: number[] = [];
    if (currentTeamInfo?.teamMemberIds) {
      hiddenUserAccounts.push(...currentTeamInfo.teamMemberIds);
    }
    if (currentTeamInfo?.pendingTeamMemberIds) {
      hiddenUserAccounts.push(...currentTeamInfo.pendingTeamMemberIds);
    }
    return [...new Set(hiddenUserAccounts)];
  };

  const { modalDescription, inviteMemberInfoMessage, addMemberInfoMessage } =
    INVITE_PEOPLE_TEXT.Team;

  return (
    <Modal
      show={show}
      onHide={hideModal}
      className="invite-modal simple-modal"
      size="lg"
    >
      <div className="modal-title-row">
        <h2>
          {inviteCount
            ? "Invitations Sent"
            : `Invite People to ${currentTeamInfo?.teamName}`}
        </h2>
        <Button
          onClick={hideModal}
          variant={"secondary-blue"}
          style={{ border: "none", width: "auto" }}
          xIcon
        />
      </div>
      <InviteUserForm
        inviteLink={teamLinksById[teamId]}
        allowedDomains={getAllowedDomains(companySettings)}
        teamId={teamId}
        onInviteViaEmail={async (emailAddresses: string[]) => {
          dispatch(resetInvalidInvitedStrings());
          const resp = (await dispatch(
            inviteUserByEmail({
              teamId: teamId,
              emailAddresses,
              sendInvitations: sendEmails,
            })
          )) as any;
          if (resp.error) return;
          hideModal();
        }}
        modalDescription={
          <p>
            <b>Why Invite People?</b> {modalDescription}
          </p>
        }
        teamMembers={users}
        isLoading={inviteUsersByEmailStatus === "loading"}
        hiddenUserAccounts={getHiddenUserAccounts()}
        onHide={hideModal}
        totalUsersInvited={inviteCount}
        invalidInvitedStrings={invalidInvitedStrings}
        onCSVUploadSuccess={() => {
          hideModal();
        }}
        isUserAllowedToInviteNewMembers={canUserInviteNewMembers}
        inviteMemberInfoMessage={inviteMemberInfoMessage}
        addMemberInfoMessage={addMemberInfoMessage}
        resetInvalidInvitedStrings={() =>
          dispatch(resetInvalidInvitedStrings())
        }
        setSendEmailSettingProps={{
          setter: setSendEmails,
          value: sendEmails,
        }}
        hideCsvTab
        hideInviteViaLinkTab={hideInviteViaLinkOption}
      />
    </Modal>
  );
}
