import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import {
  selectDepartments,
  selectTeamAndDepartmentLeads,
  updateDepartment,
  reassignTeamLead,
} from "app/containers/AdminConsole/slice";
import {
  selectAllCompanyUsersById,
  selectTeamsByTeamId,
  deleteUserByUserAccountId,
  selectUserDeletedStatus,
  resetUserDeletedState,
  selectCurrentUserAccountId,
} from "app/containers/Global/slice";
import SingleUserSelect from "app/storybookComponents/SearchableInput/SingleUserSelect";

interface Props {
  show: boolean;
  onHide: () => void;
  userAccountId?: number | null;
}
export default function DeactivateUserModal({
  show,
  onHide,
  userAccountId,
}: Props) {
  const dispatch = useAppDispatch();
  const departments = useAppSelector(selectDepartments);
  const teamDepartmentLeads = useAppSelector(selectTeamAndDepartmentLeads);
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const teamByTeamId = useAppSelector(selectTeamsByTeamId);
  const deleteUserStatus = useAppSelector(selectUserDeletedStatus);
  const loggedInUserAccountId = useAppSelector(selectCurrentUserAccountId);

  const [updatedTeams, setUpdatedTeams] = useState<{
    [teamId: number]: number; // userAccountId
  }>({});
  const [updatedDepartments, setUpdatedDepartments] = useState<{
    [departmentId: number]: number; // userAccountId
  }>({});
  const [teamIds, setTeamIds] = useState<number[]>([]);
  const [departmentId, setDepartmentId] = useState<number[]>([]);
  const [currentTeamIndex, setCurrentTeamIndex] = useState(0);
  const [currentDepartmentIndex, setCurrentDepartmentIndex] = useState(0);

  // Upon a successful request we close the modal and reset the state for the response status
  useEffect(() => {
    if (deleteUserStatus === "succeeded") {
      onHide();
      dispatch(resetUserDeletedState());
    }
  }, [deleteUserStatus, onHide, dispatch]);

  // This function should return back the teamIds for the user selected
  const getTeamIds = useCallback(
    (teamIds: number[] = []) =>
      // filtering out teams to only those teams that have only one team lead and has more than one team member
      teamIds.filter((teamId) => {
        const teamLeads =
          teamByTeamId[teamId]?.teamLeadUserAccountIds?.length ?? 0;
        const teamMembers = teamByTeamId[teamId]?.teamMemberIds?.length ?? 0;
        return teamLeads === 1 && teamMembers > 1;
      }),
    [teamByTeamId]
  );

  useEffect(() => {
    if (!teamDepartmentLeads || !userAccountId) return;
    const teamIds = teamDepartmentLeads.users[userAccountId]?.teams;
    setTeamIds(getTeamIds(teamIds));

    const departmentIds = teamDepartmentLeads.users[userAccountId]?.departments;
    setDepartmentId(departmentIds ?? []);
  }, [teamDepartmentLeads, userAccountId, getTeamIds]);

  // When we close the modal we reset all of the states
  useEffect(() => {
    if (!show) {
      setUpdatedTeams({});
      setUpdatedDepartments({});
      setCurrentTeamIndex(0);
      setCurrentDepartmentIndex(0);
    }
  }, [show]);

  // ------------------ OnClick Helper Functions ------------------

  const onDepartmentBack = () => {
    if (currentDepartmentIndex === 0) {
      return onHide();
    }
    setCurrentDepartmentIndex(currentDepartmentIndex - 1);
  };

  const onTeamBack = () => {
    // If there is a previous team, go back to that team and exit
    if (currentTeamIndex > 0) {
      return setCurrentTeamIndex(currentTeamIndex - 1);
    }
    // Given that there are not previous teams, if there are previous departments, go back to that department
    if (currentDepartmentIndex > 0) {
      return setCurrentDepartmentIndex(currentDepartmentIndex - 1);
    }
    // If there are no previous teams or departments, exit
    return onHide();
  };

  const onDeactivateUserClick = async () => {
    if (!userAccountId) return;
    const promises: Promise<any>[] = [];
    Object.entries(updatedTeams).forEach(([teamId, newTeamLead]) => {
      promises.push(
        dispatch(
          reassignTeamLead({
            teamId: parseInt(teamId),
            newTeamLead,
            currentTeamLead: userAccountId,
          })
        )
      );
    });

    Object.entries(updatedDepartments).forEach(
      ([departmentId, newDepartmentLead]) => {
        promises.push(
          dispatch(
            updateDepartment({
              departmentId: parseInt(departmentId),
              leader: newDepartmentLead,
            })
          )
        );
      }
    );

    await Promise.all(promises);
    await dispatch(deleteUserByUserAccountId(userAccountId));
  };

  // ------------------ Get Helper Functions ------------------

  const getAreYouSureText = () => {
    const deactivatingSelf = userAccountId === loggedInUserAccountId;
    if (deactivatingSelf) {
      return "Are you sure you want to deactivate your account?";
    }
    return "Are you sure you want to deactivate this user?";
  };

  const getDescription = () => {
    const deactivatingSelf = userAccountId === loggedInUserAccountId;
    if (deactivatingSelf) {
      return "You will no longer have access to the platform and be removed from all teams and departments you are currently a part of.";
    }
    return "This user will no longer have access to the platform and be removed from all teams and departments they are currently a part of.";
  };

  const getFinalScreen = () => {
    return (
      <div className="deactivate-user-modal-body">
        <span>{getAreYouSureText()}</span>
        <p>{getDescription()}</p>
        {getUpdatedTeamsPreview()}
        {getUpdatedDepartmentsPreview()}
        <div className="deactivate-user-modal-footer">
          <Button onClick={onTeamBack} variant="secondary-blue">
            {currentTeamIndex === 0 && currentDepartmentIndex === 0
              ? "Cancel"
              : "Back"}
          </Button>
          <Button
            variant="danger"
            onClick={onDeactivateUserClick}
            disabled={deleteUserStatus === "loading"}
          >
            Confirm Deactivation
          </Button>
        </div>
      </div>
    );
  };

  const getUpdatedTeamsPreview = () => {
    if (Object.keys(updatedTeams).length === 0) return null;
    const stringMessages: string[] = [];
    Object.entries(updatedTeams).forEach(
      ([unParsedTeamId, updatedUserAccountId]) => {
        const teamId = parseInt(unParsedTeamId);
        const teamName = teamByTeamId[teamId]?.teamName;
        const newTeamLeader = usersById[updatedUserAccountId]
          ? `${usersById[updatedUserAccountId].firstName} ${usersById[updatedUserAccountId].lastName}`
          : null;
        stringMessages.push(
          `${newTeamLeader} will be the new team leader of ${teamName}`
        );
      }
    );

    return getUpdatedDivPreview(stringMessages);
  };

  const getUpdatedDepartmentsPreview = () => {
    if (Object.keys(updatedDepartments).length === 0) return null;
    const stringMessages: string[] = [];
    Object.entries(updatedDepartments).forEach(
      ([unParsedDepartmentId, updatedUserAccountId]) => {
        const departmentId = parseInt(unParsedDepartmentId);
        const departmentName = departments[departmentId]?.name;
        const newDepartmentLeader = usersById[updatedUserAccountId]
          ? `${usersById[updatedUserAccountId].firstName} ${usersById[updatedUserAccountId].lastName}`
          : null;
        stringMessages.push(
          `${newDepartmentLeader} will be the new department leader of ${departmentName}`
        );
      }
    );
    return getUpdatedDivPreview(stringMessages);
  };

  const getUpdatedDivPreview = (stringMessages: string[]) => (
    <div className="updated-teams">
      <ul>
        {stringMessages.map((stringMessage, idx) => (
          <li key={idx}>
            <strong>Update: </strong>
            {stringMessage}
          </li>
        ))}
      </ul>
    </div>
  );

  const getModalContent = () => {
    if (currentDepartmentIndex < departmentId.length) {
      const newDepLeadId = departmentId[currentDepartmentIndex]; // This is the value of the new team leader
      const departmentName = departments[newDepLeadId]?.name;
      return (
        <div>
          <div className="deactivate-user-modal-body">
            <span>Reassign a department leader</span>
            <p>
              This user is the department leader of{" "}
              <strong>{departmentName}</strong>. Before deactivating this user,
              please select a new department leader for this department.
            </p>
          </div>
          <SingleUserSelect
            users={usersById}
            setSelectedValue={(payload) => {
              if (
                payload === null ||
                updatedDepartments[newDepLeadId] === payload
              ) {
                const updatedDepartmentsCopy = { ...updatedDepartments };
                delete updatedDepartmentsCopy[newDepLeadId];
                return setUpdatedDepartments(updatedDepartmentsCopy);
              }
              setUpdatedDepartments({
                ...updatedDepartments,
                [newDepLeadId]: payload,
              });
            }}
            currentUserAccountId={userAccountId}
            selectedValue={updatedDepartments[newDepLeadId] ?? null}
          />
          <div className="deactivate-user-modal-footer">
            <Button onClick={onDepartmentBack} variant="secondary-blue">
              {currentDepartmentIndex > 0 ? "Back" : "Cancel"}
            </Button>
            <Button
              disabled={
                Object.keys(updatedDepartments).length <
                currentDepartmentIndex + 1
              }
              onClick={() =>
                setCurrentDepartmentIndex(currentDepartmentIndex + 1)
              }
            >
              Next
            </Button>
          </div>
        </div>
      );
    }

    if (currentTeamIndex < teamIds.length) {
      const newTeamLeadId = teamIds[currentTeamIndex]; // This is the value of the new team leader
      const teamName = teamByTeamId[newTeamLeadId]?.teamName;
      return (
        <div>
          <span>Reassign a team leader</span>
          <p>
            This user is the team leader of <strong>{teamName}</strong>. Before
            deactivating this user, please select a new team leader for this
            team.
          </p>
          <SingleUserSelect
            users={usersById}
            setSelectedValue={(payload) => {
              if (payload === null || updatedTeams[newTeamLeadId] === payload) {
                const updatedTeamsCopy = { ...updatedTeams };
                delete updatedTeamsCopy[newTeamLeadId];
                return setUpdatedTeams(updatedTeamsCopy);
              }
              setUpdatedTeams({
                ...updatedTeams,
                [newTeamLeadId]: payload,
              });
            }}
            userAccountIds={teamByTeamId[newTeamLeadId]?.teamMemberIds}
            currentUserAccountId={userAccountId}
            selectedValue={updatedTeams[newTeamLeadId] ?? null}
            isCreatable={false}
          />
          <div className="deactivate-user-modal-footer">
            <Button onClick={onTeamBack} variant="secondary-blue">
              {currentTeamIndex > 0 || currentDepartmentIndex !== 0
                ? "Back"
                : "Cancel"}
            </Button>
            <Button
              disabled={Object.keys(updatedTeams).length < currentTeamIndex + 1}
              onClick={() => setCurrentTeamIndex(currentTeamIndex + 1)}
            >
              Next
            </Button>
          </div>
        </div>
      );
    }

    return getFinalScreen();
  };

  return (
    <Modal show={show} onHide={onHide} className="simple-modal" size="lg">
      <div className="modal-title-row">
        <h2>Deactivate User</h2>
        <Button
          onClick={() => onHide()}
          variant={"secondary-blue"}
          style={{ border: "none", width: "auto" }}
          xIcon
        />
      </div>
      {getModalContent()}
    </Modal>
  );
}
