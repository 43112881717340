interface Props {
  setAlreadyJoined: (value: boolean) => void;
  alreadyJoined: boolean;
}
const PreviewTeamInviteChecklistOptions = ({
  setAlreadyJoined,
  alreadyJoined,
}: Props) => (
  <>
    <p className="navy-text">
      Send a test email to yourself based on a user who has:
    </p>
    <div className="column-gap-8px">
      <div className="check-box-container">
        <input
          type="radio"
          id="alreadyJoined"
          value="alreadyJoined"
          checked={alreadyJoined}
          onChange={() => setAlreadyJoined(true)}
        />
        <label htmlFor="alreadyJoined">Already joined this organization</label>
      </div>
      <div className="check-box-container">
        <input
          type="radio"
          id="notJoined"
          value="notJoined"
          checked={!alreadyJoined}
          onChange={() => setAlreadyJoined(false)}
        />
        <label htmlFor="notJoined">Has not joined this organization yet</label>
      </div>
    </div>
  </>
);

export default PreviewTeamInviteChecklistOptions;
