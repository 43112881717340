import { useState, useEffect, useLayoutEffect, useCallback } from "react";
import { Card, Dropdown, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { AdminConsoleTab, isAdminConsoleTab } from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import { PLATFORM_NAME } from "utils/constants";
import {
  selectAllCompanyUsersById,
  selectTeamsByTeamId,
  selectCompanyInfo,
} from "app/containers/Global/slice";
import {
  openCreateTeamModal,
  openEditDepartmentModal,
  openInviteTeamLeaderModal,
} from "app/components/Modals/slice";
import Button from "app/storybookComponents/Button";
import InviteUsersModal from "app/components/Modals/InviteUsersModal";
import PuzzleIllustration from "resources/images/trait-icons-team-dynamic.png";
import TeamAverageIllustration from "resources/images/illustration-team-average.png";
import MailSentIllustration from "resources/images/illustration-invite-sent.png";
import SurveyIllustration from "resources/images/survey-illustration.png";
import AdminConsoleSettings from "./AdminConsoleSettings";
import {
  selectDepartments,
  selectDepartmentTypes,
  getCompanySettings,
  createCompanyInviteLink,
  selectCreateCompanyInviteLinkStatus,
  selectGetCompanySettingsStatus,
  selectUserIsAbleToInviteTeamLeaders,
  selectIsInviteModalOpen,
  setInviteModalOpen,
  selectTeamMembersWithPendingTeamScanSurvey,
  selectHideSurveyTableBanner,
  inviteTeamMembersWithPendingTeamScanSurvey,
  selectInvitingTeamMembersWithPendingTeamScanSurveyStatus,
  selectCompanySettings,
} from "./slice";
import { getSelectProps } from "utils/helperFunctions";
import Select from "react-select";
import AddSingleUserModal from "app/containers/AdvancedCsvUpload/People/AddSingleUserModal";
import { getNotifications } from "app/components/Notifications/slice";
import Analytics from "./Analytics";
import {
  getDepartmentSearchInput,
  getPeopleSearchInput,
  getTeamSearchInput,
  getOrganizationDepartments,
  getSurveyTableBanner,
} from "./helpers";
import NavigateBackButton from "app/components/NavigateBackButton";
import DeactivateUserModal from "./Modals/DeactivateUserModal";
import {
  getDimensionGuide,
  getSkillGuide,
  selectGetDimensionGuideStatus,
  selectGetSkillsGuideStatus,
} from "app/containers/SkillsGuide/slice";
import AdminTablesHeader from "./AdminTablesHeader";
import TeamsTable from "./Tables/TeamsTable";
import DepartmentTable from "./Tables/DepartmentTable";
import PeopleTable from "./Tables/PeopleTable";
import SurveysTable from "./Tables/SurveysTable";
import SurveyBreakdownTable from "./Tables/SurveyBreakdownTable";
import { showScheduleAssessmentModal } from "app/components/LaunchAssessmentModal/slice";
import AdminOnboarding from "../Dashboard/DashboardOnboarding/AdminOnboarding";
import SimpleSideNavigation from "app/components/SimpleSideNavigation";
import MobileSideNav from "app/components/SimpleSideNavigation/MobileSideNav";
import useTrackPage from "utils/hooks/useTrackPage";
import CSVInvitationSuccessModal from "../AdvancedCsvUpload/CSVInvitationSuccessModal";
import {
  addSingleUser,
  selectAddingSingleUserStatus,
} from "../AdvancedCsvUpload/slice";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import AutomationSettings from "../AutomationSettings";
import { selectIdentityProviderResponse } from "../Login/slice";
import { IS_AU_INSTANCE } from "utils/environmentVariables";
import EmailManager from "app/components/EmailManager/EmailManager";

export default function AdminConsole() {
  const dispatch = useAppDispatch();
  const urlParams = useParams();
  const navigate = useNavigate();
  const tabSelected = urlParams.tabSelected;
  const location = useLocation();

  // ------------------------- App selectors -------------------------
  const companySettings = useAppSelector(selectCompanySettings);
  const companyInfo = useAppSelector(selectCompanyInfo);
  const teamInfoById = useAppSelector(selectTeamsByTeamId);
  const departments = useAppSelector(selectDepartments);
  const usersInfoById = useAppSelector(selectAllCompanyUsersById);
  const departmentTypes = useAppSelector(selectDepartmentTypes); // Department Types is different in that we use department types for analytics while departments are what the user sees.
  const createCompanyInviteLinkStatus = useAppSelector(
    selectCreateCompanyInviteLinkStatus
  );
  const getCompanySettingsStatus = useAppSelector(
    selectGetCompanySettingsStatus
  );
  const getDimensionGuideStatus = useAppSelector(selectGetDimensionGuideStatus);
  const getSkillsGuideStatus = useAppSelector(selectGetSkillsGuideStatus);
  const addSingleUserStatus = useAppSelector(selectAddingSingleUserStatus);
  const isUserAbleToInviteTeamLeader = useAppSelector(
    selectUserIsAbleToInviteTeamLeaders
  );
  const isInviteModalOpen = useAppSelector(selectIsInviteModalOpen);
  const membersWithTeamScanSurveys = useAppSelector(
    selectTeamMembersWithPendingTeamScanSurvey
  );
  const hideSurveyTableBanner = useAppSelector(selectHideSurveyTableBanner);
  const invitingMembersWithPendingTeamScanStatus = useAppSelector(
    selectInvitingTeamMembersWithPendingTeamScanSurveyStatus
  );
  const identityProviderResponse = useAppSelector(
    selectIdentityProviderResponse
  );

  // ------------------------- States -------------------------
  const [searchInput, setSearchInput] = useState("");
  const [displayedInput, setDisplayedInput] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<{
    ids: number[];
    type: "Departments" | "Teams" | "People";
  } | null>(null);
  const [tabShowing, setTabShowing] = useState<AdminConsoleTab | null>(
    "Analytics"
  );
  const [showDeactivateUserModalUserId, setShowDeactivateUserModalUserId] =
    useState<null | number>(null);
  const [showAddSingleUserModal, setShowAddSingleUserModal] = useState(false);
  const [
    showInvitePendingTeamScanWarning,
    setShowInvitePendingTeamScanWarning,
  ] = useState<
    | false
    | {
        memberCount: number;
      }
  >(false);

  // ------------------------- Hooks -------------------------
  useTrackPage();
  useLayoutEffect(() => {
    // If the query params is set for the tab and the tab is a valid tab then we set the active tab to the query param.
    if (tabSelected && isAdminConsoleTab(tabSelected)) {
      setTabShowing(tabSelected);
    } else {
      // if no tab is set or the tab is not a valid tab then we set the tab to the about tab.
      setTabShowing("Dashboard");
    }
  }, [tabSelected]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchInput(displayedInput);
    }, 500); // 300ms debounce delay

    return () => clearTimeout(delayDebounceFn);
  }, [displayedInput]);

  useEffect(() => {
    if (location?.state?.openInviteModal) {
      dispatch(setInviteModalOpen({ isOpen: true }));
    }
  }, [location, dispatch]);

  const handleSearchInputChange = useCallback(
    (changedInput: string) => {
      const filteredDepartments = getOrganizationDepartments(departments);
      const regexString = changedInput
        .replace(/\\/g, "\\\\")
        .replace("+", "\\+");

      const pattern = new RegExp(regexString.toLowerCase());
      switch (tabShowing) {
        case "Departments": {
          return setFilteredOptions({
            ids: getDepartmentSearchInput(
              pattern,
              filteredDepartments,
              departmentTypes,
              usersInfoById
            ),
            type: tabShowing,
          });
        }
        case "Teams": {
          return setFilteredOptions({
            ids: getTeamSearchInput(
              pattern,
              teamInfoById,
              filteredDepartments,
              usersInfoById
            ),
            type: tabShowing,
          });
        }
        case "People": {
          return setFilteredOptions({
            ids: getPeopleSearchInput(pattern, usersInfoById),
            type: tabShowing,
          });
        }
      }
    },
    [departments, departmentTypes, tabShowing, teamInfoById, usersInfoById]
  );

  useEffect(() => {
    if (searchInput) {
      handleSearchInputChange(searchInput);
    } else {
      setFilteredOptions(null);
    }
  }, [searchInput, handleSearchInputChange]);

  useEffect(() => {
    setDisplayedInput("");
    setSearchInput("");
    setFilteredOptions(null);
  }, [tabShowing]);

  useEffect(() => {
    if (
      createCompanyInviteLinkStatus === "idle" &&
      companyInfo?.companyAccountId
    ) {
      dispatch(
        createCompanyInviteLink({
          payload: { expiration: 20 },
          companyAccountId: companyInfo?.companyAccountId,
        })
      );
    }
  }, [createCompanyInviteLinkStatus, companyInfo?.companyAccountId, dispatch]);

  // Might need move this to the analytics page since not all tabs need this.
  useEffect(() => {
    if (getSkillsGuideStatus === "idle") {
      dispatch(getSkillGuide());
    }
  }, [dispatch, getSkillsGuideStatus]);

  // Might need move this to the analytics page since not all tabs need this.
  useEffect(() => {
    if (getDimensionGuideStatus === "idle") {
      dispatch(getDimensionGuide());
    }
  }, [dispatch, getDimensionGuideStatus]);

  useEffect(() => {
    if (getCompanySettingsStatus === "idle" && companyInfo?.companyAccountId) {
      dispatch(getCompanySettings(companyInfo.companyAccountId));
    }
  }, [getCompanySettingsStatus, companyInfo, dispatch]);

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  // ------------------------- Helper functions -------------------------
  const getTable = () => {
    const isLoading = searchInput !== displayedInput;
    switch (tabShowing) {
      case "Teams": {
        return (
          <TeamsTable
            filteredTeamIds={
              filteredOptions !== null && filteredOptions.type === "Teams"
                ? filteredOptions.ids
                : undefined
            }
            onInviteToTeamClick={(teamId) => {
              dispatch(setInviteModalOpen({ isOpen: true, teamId }));
            }}
            searchTerm={searchInput}
            isLoading={isLoading}
          />
        );
      }
      case "People": {
        return (
          <PeopleTable
            filteredUserAccountIds={
              filteredOptions !== null && filteredOptions.type === "People"
                ? filteredOptions.ids
                : undefined
            }
            searchTerm={searchInput}
            isLoading={isLoading}
          />
        );
      }
      case "Departments":
        return (
          <DepartmentTable
            filteredDepartmentIds={
              filteredOptions !== null && filteredOptions.type === "Departments"
                ? filteredOptions.ids
                : undefined
            }
            searchTerm={searchInput}
            isLoading={isLoading}
          />
        );
      case "Surveys":
        return (
          <>
            {getSurveyTableBanner({
              forceHide: hideSurveyTableBanner,
              dispatch,
              memberCount: membersWithTeamScanSurveys.length,
              onShowWarningModal: () => {
                setShowInvitePendingTeamScanWarning({
                  memberCount: membersWithTeamScanSurveys.length,
                });
              },
            })}
            <SurveysTable />
          </>
        );
      case "SurveyBreakdown":
        return <SurveyBreakdownTable />;
      case "Emails & Notifications":
        return <EmailManager />;
      default:
        return null;
    }
  };

  const getSearchPlaceholderText = () => {
    switch (tabShowing) {
      case "Teams":
        return "Search by name, team lead or department...";
      case "People":
        return "Search by name, job title or email...";
      case "Departments":
        return "Search by name, type or leader...";
      default:
        return "";
    }
  };

  const getTabContent = () => {
    switch (tabShowing) {
      case "Analytics":
        return (
          <Card>
            <Analytics />
          </Card>
        );
      case "Settings":
        return <AdminConsoleSettings users={usersInfoById} />;
      case "Dashboard":
        return (
          <div>
            <AdminOnboarding
              onShowInviteTeamLeaderModal={() => {
                dispatch(openInviteTeamLeaderModal());
              }}
              onShowInviteMemberModal={() => {
                dispatch(setInviteModalOpen({ isOpen: true }));
              }}
            />
          </div>
        );
      case "Automations":
        return <AutomationSettings />;
      default:
        return (
          <Card>
            {getTablesHeader()}
            {getTableAndSearch()}
          </Card>
        );
    }
  };

  const getPeopleActionButton = () => {
    const onSelect = (eventKey: string | null) => {
      if (!eventKey) {
        return;
      }
      switch (eventKey) {
        case "email":
        case "link":
        case "csv":
          dispatch(setInviteModalOpen({ isOpen: true, defaultTab: eventKey }));
          break;
        case "single-user":
          setShowAddSingleUserModal(true);
          break;
      }
    };
    return (
      <Dropdown onSelect={onSelect} title="Invite">
        <Dropdown.Toggle id="dropdown-basic">
          <FontAwesomeIcon icon="user-plus" className="me-2" />
          Add people
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item eventKey="email">
            Invite people via email
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item eventKey="link">Invite people via link</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item eventKey="single-user">
            Create users manually
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item eventKey="csv">Create users via CSV</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const getPeopleAdditionalHeaderContent = () => {
    const hasSSOHardEnabled =
      identityProviderResponse?.identityProviderMode === 2;
    const softEnabled = identityProviderResponse?.identityProviderMode === 1;
    if (!hasSSOHardEnabled && !softEnabled) {
      return null;
    }
    const helpDocsLink = IS_AU_INSTANCE
      ? "https://criteriacorp.helpdocs.io/category/5we4p3fn0k-sso-integrations"
      : "https://criteria.helpdocs.io/category/8jk4zxi8ou-single-sign-on-sso";
    return (
      <div>
        <p>
          Your organization has Single Sign On (SSO){" "}
          <b>{hasSSOHardEnabled ? "hard" : "soft"} enabled</b>. This means your
          users can{hasSSOHardEnabled ? " only" : ""} log into Develop via SSO.{" "}
          <a href={helpDocsLink} target="_blank" rel="noreferrer">
            Read about adding your members to your SSO provider.
          </a>
        </p>
      </div>
    );
  };

  const getTablesHeader = () => {
    switch (tabShowing) {
      case "Teams":
        return (
          <AdminTablesHeader
            headerTitle={tabShowing}
            headerDescription="View and manage all active teams. Create a new team or delete an existing team. View a team’s name, team leader, number of members, who can access the profile of the team (either team members only or anyone in the organization) and the department the team belongs to."
            bannerTitle="Create a new team"
            bannerDescription="Teams are the foundation of your organization. Create a team for each department, location, or any other group that makes sense for your organization."
            iconImageSrc={TeamAverageIllustration}
            iconImageAlt="Teams"
            actionButton={
              <div className="row-gap-8px">
                <Button
                  onClick={() =>
                    dispatch(openCreateTeamModal({ isFromAdminConsole: true }))
                  }
                >
                  <FontAwesomeIcon icon="user-plus" className="me-2" /> Create
                  Team
                </Button>
                {isUserAbleToInviteTeamLeader ? (
                  <Button
                    variant="secondary-blue"
                    className="white-button"
                    onClick={() => dispatch(openInviteTeamLeaderModal())}
                  >
                    Add a team leader
                  </Button>
                ) : null}
              </div>
            }
          />
        );
      case "People":
        return (
          <AdminTablesHeader
            headerTitle={tabShowing}
            headerDescription="View and manage all users here. View a user’s job title, the number of teams they are on, and their access role. Users can be assigned as admin with full privileges or a non-admin user (e.g. a team member, team leader, or a department leader)."
            bannerTitle={`Invite people to ${PLATFORM_NAME}`}
            bannerDescription={`Learn, collaborate, and communicate with your team members all in one space`}
            iconImageSrc={MailSentIllustration}
            iconImageAlt="People"
            actionButton={getPeopleActionButton()}
            additionalHeaderContent={getPeopleAdditionalHeaderContent()}
          />
        );
      case "Departments": {
        return (
          <AdminTablesHeader
            headerTitle={tabShowing}
            headerDescription="Add, edit, or manage departments here. View all your departments, the number of teams and members per department, the department leader and the leader’s email."
            bannerTitle="Create a new department"
            bannerDescription="Departments group teams (e.g. marketing, product, sales, etc) and allow you to create and manage department leaders who get access to all the team profiles and reports for the teams in their department. They can be turned on/off in settings."
            iconImageSrc={PuzzleIllustration}
            iconImageAlt="Departments"
            actionButton={
              <Button onClick={() => dispatch(openEditDepartmentModal())}>
                <FontAwesomeIcon icon="plus" className="me-2" /> Create
                Department
              </Button>
            }
          />
        );
      }
      case "Surveys": {
        return (
          <AdminTablesHeader
            headerTitle={"Manage Surveys"}
            headerDescription="Monitor completion and edit survey settings for TEAMscans here. To view results or edit settings, click the menu icon to find more actions for each instance."
            bannerDescription="Launch the TEAMScan for any team you've created to get a quick readout on teamwork across 17 validated dimensions. It only takes a few minutes and is a great developmental tool."
            bannerTitle="Measure Teamwork"
            iconImageSrc={SurveyIllustration}
            iconImageAlt="Launch TEAMscan"
            actionButton={
              <div className="row-gap-12px">
                <Button
                  onClick={() => {
                    dispatch(showScheduleAssessmentModal());
                  }}
                >
                  Launch TEAMscan
                </Button>
                <Button
                  onClick={() => {
                    navigate("/SkillsGuide");
                  }}
                  variant="secondary-blue"
                  className="white-button"
                >
                  See dimensions
                </Button>
              </div>
            }
          />
        );
      }
      default:
        return null;
    }
  };

  const hasNoRows = () => {
    switch (tabShowing) {
      case "Teams":
        return Object.keys(teamInfoById).length === 0;
      case "People":
        return Object.keys(usersInfoById).length === 0;
      case "Departments": {
        const filteredDepartments = getOrganizationDepartments(departments);
        return Object.keys(filteredDepartments).length === 0;
      }
      default:
        return false;
    }
  };

  const getTableAndSearch = () => {
    const placeholder = getSearchPlaceholderText();
    const { selectStyles, components } = getSelectProps();

    if (
      tabShowing === "Surveys" ||
      tabShowing === "SurveyBreakdown" ||
      tabShowing === "Emails & Notifications"
    ) {
      return getTable();
    }

    return (
      <>
        {/* Hiding the search input for survey table and instead holding the search inside of the survey table component */}
        {hasNoRows() ? null : (
          <Form.Group>
            <Select
              placeholder={placeholder}
              isClearable={true}
              isSearchable={true}
              components={components}
              inputValue={displayedInput}
              styles={selectStyles}
              menuIsOpen={false}
              onInputChange={(e, actionMeta) => {
                if (actionMeta.action === "input-change") {
                  setDisplayedInput(e);
                }
              }}
            />
          </Form.Group>
        )}
        <div>{getTable()}</div>
      </>
    );
  };

  const onTabSelect = (tab: string | null) => {
    navigate(`/AdminConsole/${tab}`);
    setTabShowing(tab as AdminConsoleTab);
  };

  const navs = [
    { title: "Dashboard" },
    { title: "People" },
    { title: "Teams" },
    { title: "Departments" },
    { title: "Surveys" },
    { title: "Emails & Notifications" },
    { title: "Settings" },
  ];

  if (companySettings?.showAutomationSettings) {
    navs.splice(navs.length - 1, 0, { title: "Automations" });
  }

  const navProps = {
    navs,
    setNavSelected: (path: string) => {
      onTabSelect(path);
    },
    navSelected: tabShowing ? [tabShowing] : [],
    footer: (
      <>
        <hr className="m-0" />
        <Button
          variant="secondary-blue"
          className="border-0"
          onClick={() => dispatch(setInviteModalOpen({ isOpen: true }))}
        >
          <FontAwesomeIcon icon="plus" className="me-2" /> Add new team member
        </Button>
      </>
    ),
  };

  return (
    <>
      <CSVInvitationSuccessModal />
      <DeactivateUserModal
        show={showDeactivateUserModalUserId !== null}
        onHide={() => setShowDeactivateUserModalUserId(null)}
        userAccountId={showDeactivateUserModalUserId}
      />
      <InviteUsersModal
        showing={!!isInviteModalOpen}
        hideModal={() => {
          dispatch(setInviteModalOpen(false));
        }}
        teamId={isInviteModalOpen?.teamId}
        onInviteSuccess={() => dispatch(setInviteModalOpen(false))}
        defaultTab={isInviteModalOpen?.defaultTab}
      />
      <AddSingleUserModal
        isOpen={showAddSingleUserModal}
        onClose={() => setShowAddSingleUserModal(false)}
        onSaveUserDataRow={async (payload) => {
          await dispatch(addSingleUser(payload));
        }}
        showConnectTestTaker
        isLoading={addSingleUserStatus === "loading"}
      />
      <WarningModal
        isOpen={showInvitePendingTeamScanWarning !== false}
        hideModal={() => setShowInvitePendingTeamScanWarning(false)}
        modalTitle="Send Invitations"
        warningTitle="Send email invitations to all team members to complete the TEAMscan?"
        warningMessage="Send out an email invitation to all team members who have a pending TEAMscan survey."
        customButtonText="Send invitations"
        onConfirmClick={async () => {
          await dispatch(inviteTeamMembersWithPendingTeamScanSurvey());
          setShowInvitePendingTeamScanWarning(false);
        }}
        isInProgress={invitingMembersWithPendingTeamScanStatus === "loading"}
        isDanger={false}
      />
      <div className="side-nav-page">
        <SimpleSideNavigation {...navProps} isResponsive />
        <div className="side-nav-content">
          <div className="column-gap-8px my-3">
            {tabShowing === "Analytics" || tabShowing === "SurveyBreakdown" ? (
              <div>
                <NavigateBackButton />
              </div>
            ) : null}
            <MobileSideNav {...navProps} />
          </div>
          {getTabContent()}
        </div>
      </div>
    </>
  );
}
