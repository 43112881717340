import SimpleModalFooter from "app/components/Modals/SimpleModalFooter";
import { selectAllCompanyUsersById } from "app/containers/Global/slice";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useAppSelector } from "utils/redux/hooks";
import WarningBanner from "app/storybookComponents/WarningBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  onBack: () => void;
  onSave: (selectedUserIds: number[]) => void;
  preselectedUserIds?: number[];
  checkIfUserHasError?: (userAccountId: number | string) => boolean;
  errorMessage?: string;
}

const UserEmailConfig = ({
  onBack,
  onSave,
  preselectedUserIds,
  checkIfUserHasError,
  errorMessage = "This user has already joined your organization in Develop.",
}: Props) => {
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (preselectedUserIds) {
      setSelectedIds(preselectedUserIds);
    }
  }, [preselectedUserIds]);

  useEffect(() => {
    setShowError(
      !!checkIfUserHasError && selectedIds.some((id) => checkIfUserHasError(id))
    );
  }, [selectedIds, usersById, checkIfUserHasError]);

  const getMultiValueClass = (userAccountId: string) => {
    const className = "select__multi-value";

    if (checkIfUserHasError?.(userAccountId)) {
      return `${className} danger`;
    }

    return className;
  };

  const getUserOption = (userAccountId: number | string) => {
    const user = usersById[userAccountId];
    const name =
      `${user?.firstName ?? ""} ${user?.lastName ?? ""}`.trim() ||
      user.emailAddress;

    return {
      label: name,
      value: `${userAccountId}`,
    };
  };

  const getUserTeams = () => {
    return Object.keys(usersById).map(getUserOption);
  };

  const getUsersSelector = () => {
    return (
      <Select
        noOptionsMessage={() => null}
        placeholder="Search by user name or email..."
        options={getUserTeams()}
        isMulti
        value={selectedIds.map(getUserOption)}
        onChange={(values) => {
          if (values) {
            setSelectedIds(values.map(({ value }) => Number(value)));
          }
        }}
        classNames={{
          multiValue: ({ data: { value } }) => getMultiValueClass(value),
        }}
        filterOption={(option, inputValue) => {
          const user = usersById[option.data.value];
          const name = `${user?.firstName ?? ""} ${
            user?.lastName ?? ""
          }`.trim();
          const email = user.emailAddress;
          return (
            name.toLowerCase().includes(inputValue.toLowerCase()) ||
            email.toLowerCase().includes(inputValue.toLowerCase())
          );
        }}
      />
    );
  };

  return (
    <>
      {getUsersSelector()}
      {showError && (
        <WarningBanner type="light-red">
          <div className="row-gap-12px align-items-center">
            <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />
            <p>{errorMessage}</p>
          </div>
        </WarningBanner>
      )}
      <SimpleModalFooter
        onBackButtonClick={onBack}
        onPrimaryButtonClick={() => {
          onSave(selectedIds);
        }}
        isPrimaryButtonDisabled={!selectedIds.length || showError}
        primaryButtonText="Next"
      />
    </>
  );
};

export default UserEmailConfig;
