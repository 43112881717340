import SimpleModal from "app/components/Modals/SimpleModal";
import NewDirectReportModalContent from "./NewDirectReportModalContent";
import { useState } from "react";
import Paginator from "app/components/SortableTable/Paginator";

interface NewDirectReportModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  userAccountIds: number[];
  onDismissDirectReportNotifications: () => void;
}

const NewDirectReportModal = ({
  isOpen,
  onCloseModal,
  userAccountIds,
  onDismissDirectReportNotifications,
}: Readonly<NewDirectReportModalProps>) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [seenDirectReportSet, setSeenDirectReportSet] = useState<Set<number>>(
    new Set([currentPage])
  );

  const getPagination = () => {
    // if only one user account id then we should not show pagination
    if (userAccountIds.length <= 1) {
      return null;
    }
    return (
      <div className="d-flex">
        <Paginator
          pageCount={userAccountIds.length}
          onPageChange={({ selected: newPage }) => {
            setSeenDirectReportSet((prevSet) => new Set([...prevSet, newPage]));
            setCurrentPage(newPage);
          }}
          forcePage={currentPage}
        />
      </div>
    );
  };

  const closeWrapper = () => {
    // Before resetting the state we will see if the user has seen all the direct reports
    if (seenDirectReportSet.size === userAccountIds.length) {
      onDismissDirectReportNotifications();
    }

    setCurrentPage(0);
    setSeenDirectReportSet(new Set([0]));
    onCloseModal();
  };

  return (
    <SimpleModal
      title="You Have A New Direct Report"
      show={isOpen}
      onHide={closeWrapper}
    >
      <NewDirectReportModalContent
        onCloseModal={closeWrapper}
        userAccountId={
          userAccountIds[currentPage] ? userAccountIds[currentPage] : 0
        }
      />
      {getPagination()}
    </SimpleModal>
  );
};

export default NewDirectReportModal;
