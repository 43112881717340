import { ReactElement, useState } from "react";
import { ListModuleTemplate } from "app/components/Modules/types";
import { Form, Card } from "react-bootstrap";
import Toggle from "app/components/Toggle";
import MultiInputWithTrashCan from "app/storybookComponents/Forms/MultiInputWithTrashCan";

interface Props extends ListModuleTemplate {
  onSave: (
    section: string,
    title: string,
    moduleType: string,
    moduleContent: string,
    moduleTemplateId: number,
    link?: string
  ) => void;
  getSubmitButton: (
    talentInsightsModuleId?: number,
    isDisabled?: boolean
  ) => ReactElement;
  moduleContent?: string;
  talentInsightsModuleId?: number;
  link?: string;
}

export default function ListModuleForm({
  section,
  prefilled,
  onSave,
  perItemCharacterLimit,
  shortDescription,
  description,
  totalItemLimit,
  title,
  moduleTemplateId,
  moduleContent,
  getSubmitButton,
  talentInsightsModuleId,
  link,
}: Props) {
  const getInitialInputs = () => {
    try {
      if (moduleContent) {
        return JSON.parse(moduleContent);
      }
      return prefilled.map(() => "").splice(0, totalItemLimit);
    } catch {
      return prefilled.map(() => "").splice(0, totalItemLimit);
    }
  };

  const [inputArray, setInputArray] = useState<string[]>(getInitialInputs());
  const [hyperLink, setHyperLink] = useState(link || "");
  const [linkToggled, setLinkToggled] = useState(!!link);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSave(
          section,
          title,
          "List",
          JSON.stringify(inputArray),
          moduleTemplateId,
          linkToggled ? hyperLink : ""
        );
      }}
    >
      <Card className="module-form-card column-gap-16px">
        <MultiInputWithTrashCan
          inputs={inputArray}
          setInputs={setInputArray}
          prefilled={prefilled}
          totalItemLimit={totalItemLimit}
          perItemCharacterLimit={perItemCharacterLimit}
          description={description || shortDescription}
        />

        <Form.Group className="column-gap-16px">
          <div className="d-flex">
            <span
              className="me-2"
              style={{ color: "#53565A", fontSize: "14px", fontWeight: "bold" }}
            >
              Add Link
            </span>
            <Toggle
              isOn={linkToggled}
              handleToggle={() => setLinkToggled(!linkToggled)}
            />
          </div>
          {linkToggled ? (
            <Form.Control
              placeholder="https://www."
              value={hyperLink}
              onChange={(e) => setHyperLink(e.target.value)}
              className="mt-1"
            />
          ) : null}
        </Form.Group>
      </Card>
      {getSubmitButton(
        talentInsightsModuleId,
        inputArray.every((input) => input.length === 0)
      )}
    </Form>
  );
}
