import { Card, Form, Dropdown } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ReactElement,
  useEffect,
  useLayoutEffect,
  useState,
  useCallback,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  selectAllCompanyUsersById,
  selectGetAllUserStatus,
  selectTeamsByTeamId,
  selectCurrentUserAccountId,
  selectGetAllTeamsStatus,
  deleteTeam,
  selectDeleteTeamStatus,
  selectCompanyInfo,
} from "app/containers/Global/slice";
import { openCreateTeamModal } from "app/components/Modals/slice";
import {
  selectDepartments,
  selectIsDepartmentsHidden,
} from "app/containers/AdminConsole/slice";
import { WarningModalState } from "app/containers/AdminConsole/types";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import { Team, UserInfo } from "app/containers/Global/types";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import AvatarCircle from "app/components/AvatarCircle";
import { getSelectProps, toSorted, getS } from "utils/helperFunctions";
import Select from "react-select";
import { teamFilters, teamFilterOptions } from "./constants";
import SearchFilter from "app/components/SearchFilter";
import InviteUsersModal from "app/components/Modals/InviteUsersModal";
import { SeeAll } from "./types";
import NavigateBackButton from "app/components/NavigateBackButton";
import { getOrganizationDepartments } from "../AdminConsole/helpers";
import CircularTabs from "app/storybookComponents/Tabs/CircularTabs";
import {
  selectUserIsAbleToCreateTeams,
  selectUserIsAbleToInviteUsers,
} from "app/containers/UserGuide/slice";
import DirectReports from "./DirectReports";

export default function Search() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  // ------------------------------ Selectors ------------------------------
  const usersInfoById = useAppSelector(selectAllCompanyUsersById);
  const teamsByTeamId = useAppSelector(selectTeamsByTeamId);
  const currentUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const deleteTeamStatus = useAppSelector(selectDeleteTeamStatus);
  const departments = useAppSelector(selectDepartments);
  const getAllTeamsStatus = useAppSelector(selectGetAllTeamsStatus);
  const isDepartmentsHidden = useAppSelector(selectIsDepartmentsHidden);
  const companyInfo = useAppSelector(selectCompanyInfo);
  const canUserInviteNewMembers = useAppSelector(selectUserIsAbleToInviteUsers);
  const canUserCreateTeams = useAppSelector(selectUserIsAbleToCreateTeams);

  // ------------------------------ State ------------------------------
  const [searchInput, setSearchInput] = useState("");
  const [sameTeamMembers, setSameTeamMembers] = useState<{
    [userAccountId: number]: number[];
  }>({});
  const getAllUsersStatus = useAppSelector(selectGetAllUserStatus);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [toggledFilters, setToggledFilters] = useState<{
    Department?: number[];
    Team?: number[];
    "Team Size"?: string[];
  }>({});
  const [seeAll, setSeeAll] = useState<SeeAll | null>(null);
  const [showWarningModal, setShowWarningModal] =
    useState<WarningModalState>(false);
  const [searchType, setSearchType] = useState<
    "People" | "Teams" | "Departments" | "DirectReports" | null
  >(null);

  // Takes in a UserInfo object and returns a card with the user's information
  const getUserCard = useCallback(
    (
      {
        userAccountId,
        jobTitle,
        firstName,
        lastName,
        emailAddress,
        firstTeamsLogin,
      }: UserInfo,
      description?: string
    ) => {
      const hasFirstOrLastName = firstName || lastName;
      const isPending = !(firstTeamsLogin || hasFirstOrLastName || jobTitle);
      const name = hasFirstOrLastName
        ? `${firstName || ""} ${lastName || ""}`.trim()
        : emailAddress;

      return (
        <Card className="single-search-card" key={userAccountId}>
          <AvatarCircle
            userAccountId={userAccountId}
            size="large"
            avatarColor={isPending ? "53565A" : undefined}
          />
          <h3 className="pt-2 member-name">{name}</h3>
          <span className="job-title">{jobTitle ?? "No Job Title"}</span>
          {description ? <span className="tag">{description}</span> : null}
          {isPending ? (
            <span className="tag pending">Account Pending</span>
          ) : null}
          <Button
            variant="secondary-blue"
            className="view-guide"
            disabled={isPending}
            onClick={() => {
              if (isPending) return;
              navigate(`/UserGuide/${userAccountId}`);
            }}
          >
            View Guide
          </Button>
        </Card>
      );
    },
    [navigate]
  );

  useEffect(() => {
    if (deleteTeamStatus === "succeeded") {
      setShowWarningModal(false);
    }
  }, [deleteTeamStatus]);

  useEffect(() => {
    const departmentId = searchParams.get("departmentId");
    if (departmentId) {
      setToggledFilters({
        Department: [Number(departmentId)],
      });
      // Once we set the state we need to remove the departmentId from the searchParams
      searchParams.delete("departmentId");
    }

    const teamId = searchParams.get("teamId");
    if (teamId) {
      setToggledFilters({
        Team: [Number(teamId)],
      });
      // Once we set the state we need to remove the teamId from the searchParams
      searchParams.delete("teamId");
    }

    const searchTerm = searchParams.get("searchInput");
    setSearchInput(searchTerm || "");
  }, [toggledFilters, searchParams]);

  useLayoutEffect(() => {
    const [, , type] = pathname.split("/");
    if (
      type === "People" ||
      type === "Teams" ||
      type === "Departments" ||
      type === "DirectReports"
    ) {
      setSearchType(type);
    }
  }, [pathname]);

  useEffect(() => {
    // If either request is not yet fulfilled then we don't get all the users with similar teams
    // Or if for some strange reason the user does not exist inside of the usersInfoById object then return.
    if (
      getAllUsersStatus !== "succeeded" ||
      getAllTeamsStatus !== "succeeded" ||
      !currentUserAccountId ||
      !usersInfoById[currentUserAccountId]
    ) {
      return;
    }

    const userAccountIds: {
      [userAccountId: number]: number[];
    } = {};

    // Goes through the logged in user's teams
    usersInfoById[currentUserAccountId].teamIds.forEach((teamId) => {
      if (!teamsByTeamId[teamId]) return;

      // and then goes through each team's members and adds them to the userAccountIds object.
      teamsByTeamId[teamId].teamMemberIds?.forEach((memberId: number) => {
        // If array is initialized then push the teamId to the array, otherwise create a new array with the teamId.
        if (userAccountIds[memberId]) {
          userAccountIds[memberId].push(teamId);
        } else {
          userAccountIds[memberId] = [teamId];
        }
      });
    });

    setSameTeamMembers(userAccountIds);
  }, [
    currentUserAccountId,
    usersInfoById,
    getAllUsersStatus,
    getAllTeamsStatus,
    teamsByTeamId,
  ]);

  const organizationDepartments = getOrganizationDepartments(departments);

  const getEditDiv = (teamId: number, teamLeadUserAccountIds: number[]) => {
    const isTeamLead =
      currentUserAccountId &&
      teamLeadUserAccountIds?.includes(currentUserAccountId);

    const isAdmin =
      currentUserAccountId &&
      usersInfoById[currentUserAccountId]?.tmgRoleId === 1;
    if (isAdmin || isTeamLead) {
      return (
        <div
          style={{
            top: "20px",
            right: "20px",
            position: "absolute",
          }}
        >
          <Dropdown>
            <Dropdown.Toggle
              variant="outline-primary"
              id="dropdown-basic"
              className="no-caret"
            >
              <FontAwesomeIcon icon="ellipsis" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  navigate(`/TeamGuide/${teamId}/TeamSettings`);
                }}
              >
                Manage Team
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                className="danger"
                onClick={() => {
                  setShowWarningModal({
                    id: teamId,
                    warningTitle: `Are you sure you want to delete this team?`,
                    warningMessage:
                      "This will permanently delete all team information/assessments, team guide details, and all team members from the team. This action cannot be undone.",
                    modalTitle: "Delete Team",
                    isDanger: true,
                    customButtonText: "Confirm Delete",
                  });
                }}
              >
                Delete Team
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    }

    // return a div with the same height
    return <div style={{ height: "40px" }} />;
  };

  const getTeamCard = ({
    teamName,
    profilePicture,
    teamMemberIds,
    departmentId,
    teamId,
    teamLeadUserAccountIds,
    avatarColor,
    pendingTeamMemberIds,
  }: Team) => {
    const acceptedMembers = teamMemberIds?.length || 0;
    const pendingMembers = pendingTeamMemberIds?.length || 0;
    const totalMembers = acceptedMembers + pendingMembers;
    const departmentName = departmentId
      ? departments[departmentId]?.name
      : null;

    return (
      <Card className="single-search-card">
        {getEditDiv(teamId, teamLeadUserAccountIds || [])}
        <AvatarCircle
          name={teamName}
          size="large"
          profilePicture={profilePicture}
          avatarColor={avatarColor}
        />
        <h3 className="pt-2">{teamName || ""}</h3>
        <span className="job-title">
          {totalMembers} Member{getS(totalMembers)}
        </span>
        <span className="tag">{departmentName ?? "No Department"}</span>
        <Button
          variant="secondary-blue"
          className="view-guide"
          onClick={() => {
            if (totalMembers === 0) return;
            navigate(`/TeamGuide/${teamId}`);
          }}
          disabled={totalMembers === 0}
        >
          View Guide
        </Button>
      </Card>
    );
  };

  const getTeamMembersWithSameTeams = () => {
    const members: ReactElement[] = [];
    let over8 = false;
    Object.entries(sameTeamMembers).forEach(([userAccountId, sameTeamIds]) => {
      const { firstTeamsLogin, firstName, lastName, jobTitle } =
        usersInfoById[userAccountId] || {};

      if (
        (firstTeamsLogin || firstName || lastName || jobTitle) &&
        Number(userAccountId) !== currentUserAccountId
      ) {
        if (members.length === 8) {
          over8 = true;
          if (seeAll !== "People on the same team") {
            return;
          }
        }

        // If the user has more than one similar team then we show Multiple teams instead of listing all of them out.
        const description =
          sameTeamIds.length > 1
            ? `${sameTeamIds.length} Mutual Teams`
            : teamsByTeamId[sameTeamIds[0]].teamName;
        members.push(getUserCard(usersInfoById[userAccountId], description));
      }
    });

    if (members.length === 0) {
      return null;
    }

    return (
      <>
        <h3>People on your teams</h3>
        <div className="search-cards-container">{members}</div>
        {over8 ? (
          <Button
            onClick={() => {
              setSeeAll(
                seeAll === "People on the same team"
                  ? null
                  : "People on the same team"
              );
            }}
            variant="tertiary-blue"
          >
            {seeAll === "People on the same team"
              ? "See Less"
              : "See All People on Your Teams"}
          </Button>
        ) : null}
      </>
    );
  };

  const sortMembers = (members: UserInfo[]) =>
    toSorted(members, (a, b) =>
      (a.firstName || "zzz").localeCompare(b.firstName || "zzz")
    );

  const getAllMembers = (seeAllMembers?: boolean) => {
    const filteredMembers = getFilteredMembers();
    const members = sortMembers(filteredMembers)
      .slice(0, seeAllMembers ? filteredMembers.length : 8)
      .map((user) => getUserCard(user));

    return (
      <>
        <h3>All people</h3>
        <div className="search-cards-container">{members}</div>
        {!seeAllMembers && filteredMembers.length > 8 ? (
          <Button
            onClick={() => {
              setSeeAll("People");
            }}
            variant="tertiary-blue"
          >
            See All
          </Button>
        ) : null}
      </>
    );
  };

  const getAllTeams = (seeAllTeams?: boolean) => {
    const filteredTeams = getFilteredTeams();
    const teams = filteredTeams
      .sort((a, b) => (a.teamName || "zzz").localeCompare(b.teamName || "zzz"))
      .slice(0, seeAllTeams ? filteredTeams.length : 8)
      .map((team) => getTeamCard(team));

    return (
      <>
        <h3>All teams</h3>
        <div className="search-cards-container">{teams}</div>
        {!seeAllTeams && filteredTeams.length > 8 ? (
          <Button
            onClick={() => {
              setSeeAll("Teams");
            }}
            variant="tertiary-blue"
          >
            See All
          </Button>
        ) : null}
      </>
    );
  };

  const getYourTeams = () => {
    const teams: ReactElement[] = [];
    let over8 = false;
    if (currentUserAccountId) {
      usersInfoById[currentUserAccountId]?.teamIds?.forEach((teamId) => {
        if (teams.length === 8) {
          over8 = true;
          if (seeAll !== "See all of your teams") {
            return;
          }
        }
        if (!teamsByTeamId[teamId]) return;
        teams.push(getTeamCard(teamsByTeamId[teamId]));
      });
    }

    if (teams.length === 0) {
      return null;
    }

    return (
      <>
        <h3>Your teams</h3>
        <div className="search-cards-container">{teams}</div>
        {over8 ? (
          <Button
            variant="tertiary-blue"
            onClick={() => {
              setSeeAll(
                seeAll === "See all of your teams"
                  ? null
                  : "See all of your teams"
              );
            }}
          >
            {seeAll === "See all of your teams"
              ? "See less"
              : "See all of your teams"}
          </Button>
        ) : null}
      </>
    );
  };

  const getAllDepartments = () => {
    const filteredDepartments = getFilteredDepartments();
    const departmentElms: ReactElement[] = [];
    filteredDepartments.forEach((department) => {
      departmentElms.push(
        <Card className="single-search-card" key={department.departmentId}>
          <h3>{department.name}</h3>
          <span className="job-title">
            {department.teams?.length ?? 0} teams
            <FontAwesomeIcon
              icon="circle"
              className="mx-2 my-1"
              style={{ fontSize: "4px" }}
            />
            {department.teamMembers?.length ?? 0} members
          </span>
          <Button
            variant="secondary-blue"
            className="view-guide"
            onClick={() =>
              navigate(`/Search/Teams?departmentId=${department.departmentId}`)
            }
          >
            View Teams
          </Button>
        </Card>
      );
    });

    return (
      <>
        <h3>All departments</h3>
        <div className="search-cards-container">{departmentElms}</div>
      </>
    );
  };

  const getDepartmentIdsFromTeamIds = (teamIds: number[] = []) => {
    const departmentIds: number[] = [];
    teamIds.forEach((teamId) => {
      if (teamsByTeamId[teamId]) {
        const departmentId = teamsByTeamId[teamId].departmentId;
        if (departmentId) {
          departmentIds.push(departmentId);
        }
      }
    });
    return departmentIds;
  };

  const getUserSearchString = (user: UserInfo) => {
    let aggregatedValue = "";
    if (user.firstName) {
      aggregatedValue += " " + user.firstName;
    }
    if (user.lastName) {
      aggregatedValue += " " + user.lastName;
    }
    if (user.jobTitle) {
      aggregatedValue += " " + user.jobTitle;
    }
    if (user.emailAddress) {
      aggregatedValue += " " + user.emailAddress;
    }
    return aggregatedValue;
  };

  const getFilteredMembers = () =>
    Object.values(usersInfoById).filter((user) => {
      // If the user has Team filters toggled then we need to check that the user belongs to every team inside of the filter, if not then we return false. If it does then proceed to the next filter.
      if (
        toggledFilters.Team !== undefined &&
        toggledFilters.Team.length > 0 &&
        !toggledFilters.Team?.every((teamId) => user.teamIds.includes(teamId))
      ) {
        return false;
      }
      const departmentIds = getDepartmentIdsFromTeamIds(user.teamIds);
      if (
        toggledFilters.Department !== undefined &&
        toggledFilters.Department.length > 0 &&
        !toggledFilters.Department?.every((departmentId) =>
          departmentIds.includes(departmentId)
        )
      ) {
        return false;
      }

      const userSearchString = getUserSearchString(user);

      // If the user has a search input then we need to check if the user's name or job title matches the search input, if not then we return false. If it does then proceed to the next filter.
      if (
        searchInput &&
        !userSearchString
          .toLocaleLowerCase()
          .includes(searchInput.toLocaleLowerCase())
      ) {
        return false;
      }

      return true;
    });

  const getFilteredTeams = () =>
    Object.values(teamsByTeamId).filter((team) => {
      // If the user has Department filters toggled then we need to check if the user has the same department, if not then we return false. If it does then proceed to the next filter.
      if (
        toggledFilters.Department !== undefined &&
        toggledFilters.Department.length > 0 &&
        !(
          team.departmentId &&
          toggledFilters.Department?.includes(team.departmentId)
        )
      ) {
        return false;
      }

      if (
        searchInput &&
        !team.teamName?.toLowerCase().includes(searchInput.toLowerCase())
      ) {
        return false;
      }

      return true;
    });

  const getFilteredDepartments = () =>
    Object.values(organizationDepartments).filter(
      (department) =>
        !(
          searchInput &&
          !department?.name?.toLowerCase().includes(searchInput.toLowerCase())
        )
    );

  const onFilterOptionSelect = (
    filterName: "Department" | "Team" | "Team Size",
    optionValue: string | number
  ) => {
    let castedValue = optionValue;
    if (filterName === "Department" || filterName === "Team") {
      castedValue = Number(castedValue);
    }

    if (filterName === "Team Size") {
      castedValue = String(castedValue);
    }

    // First we check if the department key exist inside of our filters if not then add initialize with the incoming value and return
    if (toggledFilters[filterName] === undefined) {
      return setToggledFilters({
        ...toggledFilters,
        [filterName]: [castedValue],
      });
    }

    // If the option value does not exist as part of our filters then we add it,
    // Temporarily setting cast as never because I don't know how to fix the type error
    if (!toggledFilters[filterName]?.includes(castedValue as never)) {
      return setToggledFilters({
        ...toggledFilters,
        [filterName]: [...toggledFilters[filterName]!, Number(optionValue)],
      });
    }

    // Given that we know that option value is inside of our filters, if it is only one filter left we should pop it off.
    const copy = { ...toggledFilters };
    if (toggledFilters[filterName]?.length === 1) {
      delete copy[filterName];
      return setToggledFilters(copy);
    }

    // Lastly, if it is not the only one left then we just update the filter array without that specific element.
    // Weird behavior that requires me to have these be separate if statements, even though the code is exactly the same.
    if (filterName === "Department" || filterName === "Team") {
      return setToggledFilters({
        ...toggledFilters,
        [filterName]: toggledFilters[filterName]?.filter(
          (a) => a !== castedValue
        ),
      });
    }
    return setToggledFilters({
      ...toggledFilters,
      [filterName]: toggledFilters[filterName]?.filter(
        (a) => a !== castedValue
      ),
    });
  };

  const getFilters = () => {
    if (searchType === "People") {
      const peopleFilters: ("Team" | "Department")[] = ["Team"];
      if (!isDepartmentsHidden) {
        peopleFilters.push("Department");
      }
      return (
        <div className="filters">
          {peopleFilters.map((filterName) => (
            <SearchFilter
              filterName={filterName}
              key={filterName}
              options={getOptions(filterName)}
              onOptionSelect={(optionValue) =>
                onFilterOptionSelect(filterName, optionValue)
              }
              toggledFilters={toggledFilters}
            />
          ))}
        </div>
      );
    }
    if (searchType === "Teams" && !isDepartmentsHidden) {
      return (
        <div className="filters">
          {teamFilters.map((filterName) => (
            <SearchFilter
              filterName={filterName}
              key={filterName}
              options={getOptions(filterName)}
              onOptionSelect={(optionValue) =>
                onFilterOptionSelect(filterName, optionValue)
              }
              toggledFilters={toggledFilters}
            />
          ))}
        </div>
      );
    }
    return null;
  };

  const getOptions = (filterName: string) => {
    switch (filterName) {
      case "Department":
        return Object.values(organizationDepartments).map((department) => ({
          label: department.name || "",
          value: department.departmentId,
          selected: !!toggledFilters.Department?.includes(
            department.departmentId
          ),
        }));
      case "Team":
        return Object.values(teamsByTeamId).map((team) => ({
          label: team.teamName || "",
          value: team.teamId,
          selected: !!toggledFilters.Team?.includes(team.teamId),
        }));
      case "Team Size":
        return teamFilterOptions[filterName].map((option) => ({
          label: option,
          value: option,
        }));
      default:
        return [];
    }
  };
  const getPageView = () => {
    switch (searchType) {
      case "People": {
        // If the user clicks on see all inside of people on the same team then we should only be showing the people on the same team.
        if (seeAll === "People on the same team") {
          return getTeamMembersWithSameTeams();
        }

        if (
          seeAll === "People" ||
          Object.keys(toggledFilters).length > 0 ||
          searchInput
        ) {
          return getAllMembers(true);
        }

        return (
          <>
            {getTeamMembersWithSameTeams()} {getAllMembers()}
          </>
        );
      }
      case "Teams": {
        if (
          seeAll === "Teams" ||
          Object.keys(toggledFilters).length > 0 ||
          searchInput
        ) {
          return getAllTeams(true);
        }

        return (
          <>
            {getYourTeams()}
            {getAllTeams()}
          </>
        );
      }
      case "Departments": {
        return <>{getAllDepartments()}</>;
      }
      default:
        return null;
    }
  };

  const getCircularTabs = () => {
    const tabs = [
      {
        label: "People",
      },
      {
        label: "Teams",
      },
    ];

    if (!isDepartmentsHidden) {
      tabs.push({
        label: "Departments",
      });
    }

    return (
      <div>
        <CircularTabs
          tabs={tabs}
          selectedTab={searchType ?? ""}
          onTabSelected={(tabLabel) => {
            if (
              tabLabel === "People" ||
              tabLabel === "Teams" ||
              tabLabel === "Departments"
            ) {
              setSearchType(tabLabel);
              navigate(`/Search/${tabLabel}?searchInput=${searchInput}`);
            }
          }}
        />
      </div>
    );
  };

  const onInputChange = (e: string) => {
    setSearchInput(e);
    setSearchParams({ searchInput: e }, { replace: false });
  };

  const getContent = () => {
    if (searchType === "DirectReports") {
      return <DirectReports />;
    }

    return (
      <Card className="column-gap-20px">
        <div className="search-header">
          <div className="column-gap-20px">
            <div className="column-gap-6px">
              {companyInfo?.companyName ? (
                <span className="company-name">{companyInfo?.companyName}</span>
              ) : null}

              <h1>{searchType}</h1>
            </div>

            {getFilters()}
          </div>

          {searchType === "People" && canUserInviteNewMembers ? (
            <Button onClick={() => setIsInviteModalOpen(true)}>
              <FontAwesomeIcon icon="user-plus" className="me-2" />
              Invite
            </Button>
          ) : null}
          {searchType === "Teams" && canUserCreateTeams ? (
            <Button
              variant="secondary-blue"
              onClick={() =>
                dispatch(
                  openCreateTeamModal({
                    redirectUponTeamCreation: true,
                  })
                )
              }
            >
              Create a team
            </Button>
          ) : null}
        </div>

        <Form.Group>
          <Select
            isClearable={true}
            isSearchable={true}
            components={components}
            inputValue={searchInput}
            styles={selectStyles}
            menuIsOpen={false}
            placeholder={
              searchType === "People"
                ? "Search by name, job title or email..."
                : "Search by team name, department, etc."
            }
            onInputChange={(e, actionMeta) => {
              if (actionMeta.action === "input-change") {
                onInputChange(e);
              }
            }}
          />
        </Form.Group>
        {getCircularTabs()}
        {getPageView()}
      </Card>
    );
  };

  // If the searchType is null then we don't want to render anything
  if (searchType === null) {
    return null;
  }

  const { selectStyles, components } = getSelectProps();

  return (
    <div className="page search">
      {showWarningModal !== false ? (
        <WarningModal
          modalTitle={showWarningModal.modalTitle}
          warningTitle={showWarningModal.warningTitle}
          warningMessage={showWarningModal.warningMessage}
          hideModal={() => setShowWarningModal(false)}
          onConfirmClick={() => dispatch(deleteTeam(showWarningModal.id))}
          isInProgress={deleteTeamStatus === "loading"}
          isDanger={showWarningModal.isDanger}
          customButtonText={showWarningModal.customButtonText}
          isOpen
        />
      ) : null}
      <InviteUsersModal
        showing={isInviteModalOpen}
        hideModal={() => {
          setIsInviteModalOpen(false);
        }}
      />
      <div className="mb-2">
        {seeAll ? (
          <Button onClick={() => setSeeAll(null)} variant="tertiary">
            <FontAwesomeIcon icon="arrow-left" className="me-2" /> Back
          </Button>
        ) : (
          <NavigateBackButton />
        )}
      </div>
      {getContent()}
    </div>
  );
}
